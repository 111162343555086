import { Document, DocTypes, DocsFilter } from './document';
import type { ConditionOperator } from './conditionOperator';
import type { TriggerOperator } from './triggerOperator';
import { Translations } from '../plugins/i18n';
import { I18n } from '@aws-amplify/core';
import { FieldTypes, DetailData } from '../typings/field';
import { persistentDocumentsStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface HomeegramClass extends Document {
  _docType: DocTypes.HomeegramClass;
  name: string;
  description: string | null;
  triggerOperators: TriggerOperator[] | null;
  conditionOperators: ConditionOperator[] | null;
  hasActions: boolean | null;
}

export const defaultListProperties: string = `
  name
  description
`;

export const defaultDetailProperties: string = `
  name
  description
  triggerOperators {
    _id
  }
  conditionOperators {
    _id
  }
  hasActions
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.NAME),
      value: 'name',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.DESCRIPTION),
      value: 'description',
      align: 'start',
      sortable: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: HomeegramClass[] = Object.values(
    persistentDocumentsStore.HomeegramClass
  );
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (
      document: HomeegramClass
    ): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      name: document.name,
      description: document.description
    })
  );
}

export const sortListBy: string | string[] = 'name';

export const defaults: Partial<HomeegramClass> = {
  hasActions: false
};

export async function getCategories(
  document: Partial<HomeegramClass>
): Promise<DetailData> {
  return {
    title: document.name,
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.NAME),
            required: true,
            model: 'name',
            value: document.name
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.DESCRIPTION),
            required: false,
            model: 'description',
            value: document.description
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.TRIGGER_OPERATORS),
            required: false,
            model: 'triggerOperators',
            value: document.triggerOperators,
            docType: DocTypes.TriggerOperator,
            textField: (item: Document): string => {
              const doc: TriggerOperator =
                persistentDocumentsStore.TriggerOperator[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: Object.values(
              persistentDocumentsStore.TriggerOperator
            ).sort(
              (item1: TriggerOperator, item2: TriggerOperator): number =>
                item1.number - item2.number
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.CONDITION_OPERATORS),
            required: false,
            model: 'conditionOperators',
            value: document.conditionOperators,
            docType: DocTypes.ConditionOperator,
            textField: (item: Document): string => {
              const doc: ConditionOperator =
                persistentDocumentsStore.ConditionOperator[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: Object.values(
              persistentDocumentsStore.ConditionOperator
            ).sort(
              (item1: ConditionOperator, item2: ConditionOperator): number =>
                item1.number - item2.number
            ),
            multiple: true
          },
          {
            type: FieldTypes.BOOLEAN,
            label: I18n.get(Translations.HAS_ACTIONS),
            required: false,
            model: 'hasActions',
            value: document.hasActions
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.NAME),
      property: 'name'
    },
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.DESCRIPTION),
      property: 'description'
    },
    {
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      label: I18n.get(Translations.TRIGGER_OPERATORS),
      property: 'triggerOperators',
      textField: (item: Document): string => {
        const doc: TriggerOperator =
          persistentDocumentsStore.TriggerOperator[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.TriggerOperator).sort(
        (item1: TriggerOperator, item2: TriggerOperator): number =>
          item1.number - item2.number
      )
    },
    {
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      label: I18n.get(Translations.CONDITION_OPERATORS),
      property: 'conditionOperators',
      textField: (item: Document): string => {
        const doc: ConditionOperator =
          persistentDocumentsStore.ConditionOperator[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.ConditionOperator).sort(
        (item1: ConditionOperator, item2: ConditionOperator): number =>
          item1.number - item2.number
      )
    },
    {
      label: I18n.get(Translations.HAS_ACTIONS),
      operators: ['eq', 'ne', 'exists', 'notExists'],
      property: 'hasActions',
      isBoolean: true
    }
  ];
}
