import { Vue } from 'vue-property-decorator';
import VueRouter, {
  RouteConfig,
  Route,
  RouteRecord,
  NavigationGuardNext
} from 'vue-router';
import DetailComponent from '../../components/DetailComponent.vue';
import ListComponent from '../../components/ListComponent.vue';
import AuthView from '../../views/AuthView.vue';
import { userStore } from '../store';
import {
  DocTypes,
  supportedDocTypes,
  DocumentFilter
} from '../../api/document';
import type { EsModuleComponent } from 'vue/types/options';
import Auth from '@aws-amplify/auth';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext): void => {
      if (to.hash) {
        return next({
          path: to.hash.substring(1)
        });
      }
      return next({
        path: `/${DocTypes.Product.toLowerCase()}`
      });
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    meta: { requiresAuth: false }
  },
  {
    path: '/account',
    name: 'account',
    component: (): Promise<EsModuleComponent> =>
      import(
        /* webpackChunkName: "AccountView" */ '../../views/AccountView.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: `/${DocTypes.Localization.toLowerCase()}bulk`,
    name: `${DocTypes.Localization}Bulk`,
    component: (): Promise<EsModuleComponent> =>
      import(
        /* webpackChunkName: "BulkImportView" */ '../../views/BulkImportView.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: `/:docType/:id`,
    name: 'detail',
    component: DetailComponent,
    beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext): void =>
      !supportedDocTypes.find(
        (docType: DocTypes): boolean =>
          (to.params.docType ?? '').toLowerCase() === docType.toLowerCase()
      )
        ? next(`/${DocTypes.Product.toLowerCase()}`)
        : next(),
    props: (
      to: Route
    ): {
      docType: DocTypes | undefined;
      id: string;
      isNewDocument: boolean;
    } => ({
      docType: supportedDocTypes.find(
        (docType: DocTypes): boolean =>
          (to.params.docType ?? '').toLowerCase() === docType.toLowerCase()
      ),
      id: to.params.id,
      isNewDocument: to.params.id === 'new'
    }),
    meta: { requiresAuth: true }
  },
  {
    path: `/:docType`,
    name: 'list',
    component: ListComponent,
    beforeEnter: (to: Route, _from: Route, next: NavigationGuardNext): void =>
      !supportedDocTypes.find(
        (docType: DocTypes): boolean =>
          (to.params.docType ?? '').toLowerCase() === docType.toLowerCase()
      )
        ? next(`/${DocTypes.Product.toLowerCase()}`)
        : next(),
    props: (
      to: Route
    ): {
      docType: DocTypes | undefined;
      filter: DocumentFilter;
      search: string;
    } => ({
      docType: supportedDocTypes.find(
        (docType: DocTypes): boolean =>
          (to.params.docType ?? '').toLowerCase() === docType.toLowerCase()
      ),
      filter: ((): DocumentFilter => {
        try {
          return JSON.parse(to.query?.filter as string);
        } catch (e) {
          return {};
        }
      })(),
      search: (to.query?.search as string) || ''
    }),
    meta: { requiresAuth: true }
  },
  { path: '*', redirect: `/${DocTypes.Product.toLowerCase()}` }
];

const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(
  async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
    const isAuthenticated: boolean =
      userStore.isAuthenticated ||
      (await Auth.currentAuthenticatedUser()
        .then((): boolean => true)
        .catch((): boolean => false));
    if (
      to.matched.some(
        (record: RouteRecord): boolean => record.meta.requiresAuth
      ) &&
      !isAuthenticated
    ) {
      return next({
        path: '/auth',
        ...(to.fullPath && to.fullPath !== '/'
          ? {
              query: {
                redirect: to.fullPath
              }
            }
          : {})
      });
    } else if (to.name === 'auth' && isAuthenticated) {
      return next({
        path:
          (to.query.redirect as string) || `/${DocTypes.Product.toLowerCase()}`
      });
    }
    document.title = 'homee Docs';
    return next();
  }
);

export default router;
