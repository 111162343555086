import { Document, DocTypes, DocsFile, DocsFilter } from './document';
import type { Localization } from './localization';
import type { ProductIcon } from './productIcon';
import { Translations } from '../plugins/i18n';
import { FieldTypes, DetailData } from '../typings/field';
import { I18n } from '@aws-amplify/core';
import { persistentDocumentsStore, userStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface ProductType extends Document {
  _docType: DocTypes.ProductType;
  displayName: Localization;
  productIcon: ProductIcon;
}

export const defaultListProperties: string = `
  displayName {
    _id
  }
  productIcon {
    _id
  }
`;

export const defaultDetailProperties: string = `
  displayName {
    _id
  }
  productIcon {
    _id
  }
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.DISPLAY_NAME),
      value: 'displayName',
      align: 'start',
      sortable: true
    },
    {
      text: '',
      value: 'displayNameKey',
      hidden: true
    },
    {
      text: I18n.get(Translations.PRODUCT_ICON),
      value: 'productIcon',
      align: 'center',
      sortable: false
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: ProductType[] = Object.values(
    persistentDocumentsStore.ProductType
  );
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (
      document: ProductType
    ): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      displayName:
        persistentDocumentsStore.Localization[
          document.displayName?._id ?? ''
        ]?.[userStore.locale],
      displayNameKey:
        persistentDocumentsStore.Localization[document.displayName?._id ?? '']
          ?.stringIdentifier,
      productIcon: persistentDocumentsStore.ProductIcon[
        document.productIcon?._id ?? ''
      ]?.icon
        ? `https://${
            process.env.S3_BUCKET
          }/${DocTypes.ProductIcon.toLocaleLowerCase()}/${
            document.productIcon._id
          }/${
            persistentDocumentsStore.ProductIcon[document.productIcon._id].icon
              .name
          }${
            persistentDocumentsStore.ProductIcon[document.productIcon._id].icon
              .extension
              ? `.${
                  persistentDocumentsStore.ProductIcon[document.productIcon._id]
                    .icon.extension
                }`
              : ''
          }?version=${
            persistentDocumentsStore.ProductIcon[document.productIcon._id].icon
              .version
          }`
        : null
    })
  );
}

export const sortListBy: string | string[] = 'displayName';

export async function getCategories(
  document: Partial<ProductType>
): Promise<DetailData> {
  return {
    title:
      persistentDocumentsStore.Localization[
        document?.displayName?._id ||
          ((document?.displayName as unknown) as string)
      ]?.[userStore.locale] || '',
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.DISPLAY_NAME),
            required: true,
            model: 'displayName',
            value: document.displayName,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter((loc: Localization): boolean =>
              loc.stringIdentifier.toUpperCase().startsWith('DEVICES_TYPE_')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PRODUCT_ICON),
            required: true,
            model: 'productIcon',
            value: document.productIcon,
            docType: DocTypes.ProductIcon,
            textField: (item: Document): string =>
              persistentDocumentsStore.ProductIcon[item._id]?.name || 'unknown',
            valueField: '_id',
            lightupIcons: true,
            iconField: (item: Document): string => {
              const icon: DocsFile =
                persistentDocumentsStore.ProductIcon[item._id]?.icon;
              if (!icon) {
                return '';
              }
              return `https://${
                process.env.S3_BUCKET
              }/${DocTypes.ProductIcon.toLocaleLowerCase()}/${item._id}/${
                icon.name
              }${icon.extension ? `.${icon.extension}` : ''}?version=${
                icon.version
              }`;
            },
            options: Object.values(persistentDocumentsStore.ProductIcon),
            multiple: false
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.DISPLAY_NAME),
      property: 'displayName',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(
        persistentDocumentsStore.Localization
      ).filter((loc: Localization): boolean =>
        loc.stringIdentifier.toUpperCase().startsWith('DEVICES_TYPE_')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.PRODUCT_ICON),
      property: 'productIcon',
      textField: (item: Document): string =>
        persistentDocumentsStore.ProductIcon[item._id]?.name || 'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.ProductIcon)
    }
  ];
}
