import Vue from 'vue';
import Vuex, { Store as VuexStore, Plugin, Payload } from 'vuex';
import { VuexPersistence } from 'vuex-persist';
import PersistentDocumentsModule, {
  namespace as persistentDocumentsNamespace,
  PersistentDocumentsState,
  restoreState as restoreDocumentsState
} from './persistentDocuments';
import UserModule, {
  namespace as userNamespace,
  UserState,
  restoreState as restoreUserState
} from './user';
import VolatileDocumentsModule, {
  namespace as volatileDocumentsNamespace,
  VolatileDocumentsState
} from './volatileDocuments';
import { getModule } from 'vuex-module-decorators';
import { noop } from 'lodash';

Vue.use(Vuex);

type Store<T> = VuexStore<T> & { restored: Promise<T> };

interface StoreType {
  [persistentDocumentsNamespace]?: PersistentDocumentsState;
  [userNamespace]?: UserState;
  [volatileDocumentsNamespace]?: VolatileDocumentsState;
}

const persistentPlugin: Plugin<StoreType> = new VuexPersistence<StoreType>({
  restoreState: restoreDocumentsState,
  saveState: noop,
  modules: [persistentDocumentsNamespace],
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  key: require('../../../package.json').name,
  asyncStorage: true
}).plugin;

const userPlugin: Plugin<StoreType> = new VuexPersistence<StoreType>({
  restoreState: restoreUserState,
  saveState: noop,
  modules: [userNamespace],
  filter: (mutation: Payload): boolean =>
    ![`${userNamespace}/login`, `${userNamespace}/logout`].includes(
      mutation.type
    ),
  asyncStorage: true
}).plugin;

const store: Store<StoreType> = new Vuex.Store<StoreType>({
  modules: {
    [persistentDocumentsNamespace]: PersistentDocumentsModule,
    [userNamespace]: UserModule,
    [volatileDocumentsNamespace]: VolatileDocumentsModule
  },
  plugins: [persistentPlugin, userPlugin]
}) as Store<StoreType>;

export default store;

export const persistentDocumentsStore: PersistentDocumentsModule = getModule<PersistentDocumentsModule>(
  PersistentDocumentsModule,
  store
);

export const userStore: UserModule = getModule<UserModule>(UserModule, store);

export const volatileDocumentsStore: VolatileDocumentsModule = getModule<VolatileDocumentsModule>(
  VolatileDocumentsModule,
  store
);
