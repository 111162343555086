import {
  Module,
  VuexModule,
  Mutation,
  Action,
  MutationAction
} from 'vuex-module-decorators';
import Dexie, { DexieError, Transaction } from 'dexie';
import { DocTypes, supportedDocTypes } from '../../api/document';
import _, { noop } from 'lodash';
import type { Document } from '../../api/document';
import type { AttributeType } from '../../api/attributeType';
import type { AttributeValue } from '../../api/attributeValue';
import type { ConditionOperator } from '../../api/conditionOperator';
import type { Cube } from '../../api/cube';
import type { HomeegramClass } from '../../api/homeegramClass';
import type { Localization } from '../../api/localization';
import type { Product } from '../../api/product';
import type { ProductIcon } from '../../api/productIcon';
import type { ProductType } from '../../api/productType';
import type { Profile } from '../../api/profile';
import type { Protocol } from '../../api/protocol';
import type { Service } from '../../api/service';
import type { TriggerOperator } from '../../api/triggerOperator';
import type { UiElement } from '../../api/uiElement';
import type { UseCase } from '../../api/useCase';
import type { Placeholder } from '../../api/placeholder';

export enum SearchType {
  STRICT = 'STRICT',
  FUZZY = 'FUZZY',
  SYNTAX = 'SYNTAX'
}

export interface UserPreferences {
  searchType: SearchType;
  itemsPerPage: number;
}

export interface PersistentDocumentsState {
  loading: boolean;
  userPreferences: UserPreferences;
  lastFetch: Record<DocTypes, number>;
  [DocTypes.AttributeType]: Record<string, AttributeType>;
  [DocTypes.AttributeValue]: Record<string, AttributeValue>;
  [DocTypes.ConditionOperator]: Record<string, ConditionOperator>;
  [DocTypes.Cube]: Record<string, Cube>;
  [DocTypes.HomeegramClass]: Record<string, HomeegramClass>;
  [DocTypes.Localization]: Record<string, Localization>;
  [DocTypes.Placeholder]: Record<string, Placeholder>;
  [DocTypes.Product]: Record<string, Product>;
  [DocTypes.ProductIcon]: Record<string, ProductIcon>;
  [DocTypes.ProductType]: Record<string, ProductType>;
  [DocTypes.Profile]: Record<string, Profile>;
  [DocTypes.Protocol]: Record<string, Protocol>;
  [DocTypes.Service]: Record<string, Service>;
  [DocTypes.TriggerOperator]: Record<string, TriggerOperator>;
  [DocTypes.UiElement]: Record<string, UiElement>;
  [DocTypes.UseCase]: Record<string, UseCase>;
}

export const namespace: 'persistentDocuments' = 'persistentDocuments' as const;
const DATABASE_VERSION: number = 1;

type MyDexie = Dexie &
  Record<DocTypes, Dexie.Table<Document, string>> & {
    lastFetch: Dexie.Table<
      {
        type: DocTypes;
        lastFetch: number;
      },
      DocTypes
    >;
  } & {
    userPreferences: Dexie.Table<
      { key: keyof UserPreferences; value: unknown },
      keyof UserPreferences
    >;
  };

const db: MyDexie = new Dexie(namespace, { autoOpen: false }) as MyDexie;
db.version(DATABASE_VERSION)
  .stores({
    userPreferences: 'key',
    lastFetch: 'type',
    [DocTypes.AttributeType]: '_id',
    [DocTypes.AttributeValue]: '_id',
    [DocTypes.ConditionOperator]: '_id',
    [DocTypes.Cube]: '_id',
    [DocTypes.HomeegramClass]: '_id',
    [DocTypes.Localization]: '_id',
    [DocTypes.Placeholder]: '_id',
    [DocTypes.Product]: '_id',
    [DocTypes.ProductIcon]: '_id',
    [DocTypes.ProductType]: '_id',
    [DocTypes.Profile]: '_id',
    [DocTypes.Protocol]: '_id',
    [DocTypes.Service]: '_id',
    [DocTypes.TriggerOperator]: '_id',
    [DocTypes.UiElement]: '_id',
    [DocTypes.UseCase]: '_id'
  })
  .upgrade(
    (transaction: Transaction): Promise<void> =>
      Promise.allSettled([
        localStorage.removeItem('initialized'),
        ...transaction.db.tables.map(
          (table: Dexie.Table): Promise<void> => table.clear()
        )
      ]).then(noop)
  );
db.open().catch((err: DexieError): void => {
  if (err.name === Dexie.errnames.Version) {
    Promise.allSettled([
      localStorage.removeItem('initialized'),
      db.delete()
    ]).then((): void => window.location.reload());
  }
});

export async function restoreState(): Promise<{
  [namespace]?: PersistentDocumentsState;
}> {
  const restore: <T extends Document>(
    dbName: DocTypes
  ) => Promise<Record<string, T>> = async <T extends Document>(
    dbName: DocTypes
  ): Promise<Record<string, T>> => {
    return _.keyBy(await db[dbName].toArray(), '_id') as Record<string, T>;
  };
  await db.open();
  return {
    [namespace]: {
      loading: false,
      userPreferences: (await db.userPreferences.toArray()).reduce(
        (
          all: UserPreferences,
          current: { key: keyof UserPreferences; value: unknown }
        ): UserPreferences => ({
          ...all,
          [current.key]: current.value
        }),
        { searchType: SearchType.FUZZY, itemsPerPage: 25 } as UserPreferences
      ),
      lastFetch: (await db.lastFetch.toArray()).reduce(
        (
          all: Record<DocTypes, number>,
          current: { type: DocTypes; lastFetch: number }
        ): Record<DocTypes, number> => ({
          ...all,
          [current.type]: current.lastFetch
        }),
        {} as Record<DocTypes, number>
      ),
      [DocTypes.AttributeType]: await restore<AttributeType>(
        DocTypes.AttributeType
      ),
      [DocTypes.AttributeValue]: await restore<AttributeValue>(
        DocTypes.AttributeValue
      ),
      [DocTypes.ConditionOperator]: await restore<ConditionOperator>(
        DocTypes.ConditionOperator
      ),
      [DocTypes.Cube]: await restore<Cube>(DocTypes.Cube),
      [DocTypes.HomeegramClass]: await restore<HomeegramClass>(
        DocTypes.HomeegramClass
      ),
      [DocTypes.Localization]: await restore<Localization>(
        DocTypes.Localization
      ),
      [DocTypes.Placeholder]: await restore<Placeholder>(DocTypes.Placeholder),
      [DocTypes.Product]: await restore<Product>(DocTypes.Product),
      [DocTypes.ProductIcon]: await restore<ProductIcon>(DocTypes.ProductIcon),
      [DocTypes.ProductType]: await restore<ProductType>(DocTypes.ProductType),
      [DocTypes.Profile]: await restore<Profile>(DocTypes.Profile),
      [DocTypes.Protocol]: await restore<Protocol>(DocTypes.Protocol),
      [DocTypes.Service]: await restore<Service>(DocTypes.Service),
      [DocTypes.TriggerOperator]: await restore<TriggerOperator>(
        DocTypes.TriggerOperator
      ),
      [DocTypes.UiElement]: await restore<UiElement>(DocTypes.UiElement),
      [DocTypes.UseCase]: await restore<UseCase>(DocTypes.UseCase)
    }
  };
}

@Module({
  name: namespace,
  namespaced: true
})
export default class PersistentDocumentsModule
  extends VuexModule
  implements PersistentDocumentsState {
  public loading: boolean = false;
  public userPreferences: UserPreferences = {
    searchType: SearchType.FUZZY,
    itemsPerPage: 25
  };
  public lastFetch: Record<DocTypes, number> = {
    [DocTypes.AttributeType]: 0,
    [DocTypes.AttributeValue]: 0,
    [DocTypes.ConditionOperator]: 0,
    [DocTypes.Cube]: 0,
    [DocTypes.HomeegramClass]: 0,
    [DocTypes.Localization]: 0,
    [DocTypes.Placeholder]: 0,
    [DocTypes.Product]: 0,
    [DocTypes.ProductIcon]: 0,
    [DocTypes.ProductType]: 0,
    [DocTypes.Profile]: 0,
    [DocTypes.Protocol]: 0,
    [DocTypes.Service]: 0,
    [DocTypes.TriggerOperator]: 0,
    [DocTypes.UiElement]: 0,
    [DocTypes.UseCase]: 0
  };
  public [DocTypes.AttributeType]: Record<string, AttributeType> = {};
  public [DocTypes.AttributeValue]: Record<string, AttributeValue> = {};
  public [DocTypes.ConditionOperator]: Record<string, ConditionOperator> = {};
  public [DocTypes.Cube]: Record<string, Cube> = {};
  public [DocTypes.HomeegramClass]: Record<string, HomeegramClass> = {};
  public [DocTypes.Localization]: Record<string, Localization> = {};
  public [DocTypes.Placeholder]: Record<string, Placeholder> = {};
  public [DocTypes.Product]: Record<string, Product> = {};
  public [DocTypes.ProductIcon]: Record<string, ProductIcon> = {};
  public [DocTypes.ProductType]: Record<string, ProductType> = {};
  public [DocTypes.Profile]: Record<string, Profile> = {};
  public [DocTypes.Protocol]: Record<string, Protocol> = {};
  public [DocTypes.Service]: Record<string, Service> = {};
  public [DocTypes.TriggerOperator]: Record<string, TriggerOperator> = {};
  public [DocTypes.UiElement]: Record<string, UiElement> = {};
  public [DocTypes.UseCase]: Record<string, UseCase> = {};

  @Mutation
  private updateListMutation(delta: {
    type: DocTypes;
    delete: string[];
    update: Record<string, Document>;
  }): void {
    delta.delete.forEach(
      (id: string): void => void delete this[delta.type][id]
    );
    (this[delta.type] as Record<string, Document>) = {
      ...this[delta.type],
      ...delta.update
    };
  }

  @Action({ commit: 'updateListMutation' })
  public async updateList({
    type,
    items
  }: {
    type: DocTypes;
    items: Document[];
  }): Promise<{
    type: DocTypes;
    delete: string[];
    update: Record<string, Document>;
  }> {
    const deleteIDs: string[] = items
      .filter((item: Document): boolean => item._deleted)
      .map((doc: Document): string => doc._id);
    const updateDocs: Document[] = items.filter(
      (item: Document): boolean => !item._deleted
    );
    await Promise.all([
      db[type as DocTypes].bulkDelete(deleteIDs),
      db[type as DocTypes].bulkPut(updateDocs)
    ]);
    return {
      type,
      delete: deleteIDs,
      update: _.keyBy(updateDocs, '_id')
    };
  }

  @Mutation
  private updateLastFetchMutation(delta: {
    type: DocTypes;
    lastFetch: number;
  }): void {
    this.lastFetch = {
      ...this.lastFetch,
      [delta.type]: delta.lastFetch
    };
  }

  @Action({ commit: 'updateLastFetchMutation' })
  public async updateLastFetch(update: {
    type: DocTypes;
    lastFetch: number;
  }): Promise<{
    type: DocTypes;
    lastFetch: number;
  }> {
    await db.lastFetch.put(update).catch(noop);
    return update;
  }

  @Mutation
  private updateUserPreferencesMutation(
    userPreferences: Partial<UserPreferences>
  ): void {
    this.userPreferences = {
      ...this.userPreferences,
      ...userPreferences
    };
  }

  @Action({ commit: 'updateUserPreferencesMutation' })
  public async updateSearchType(
    searchType: SearchType
  ): Promise<Partial<UserPreferences>> {
    await db.userPreferences
      .put({ key: 'searchType', value: searchType || SearchType.FUZZY })
      .catch(noop);
    return { searchType };
  }

  @Action({ commit: 'updateUserPreferencesMutation' })
  public async updateItemsPerPage(
    itemsPerPage: number
  ): Promise<Partial<UserPreferences>> {
    await db.userPreferences
      .put({ key: 'itemsPerPage', value: itemsPerPage || 25 })
      .catch(noop);
    return { itemsPerPage };
  }

  @Mutation
  public clearStoreMutation(): void {
    this.userPreferences = {} as UserPreferences;
    this.lastFetch = {} as Record<DocTypes, number>;
    supportedDocTypes.forEach((type: DocTypes): void => {
      this[type] = {};
    });
  }

  @Action({ commit: 'clearStoreMutation' })
  public clearStore(): Promise<void> {
    return Promise.allSettled(
      db.tables.map((table: Dexie.Table): Promise<void> => table.clear())
    ).then(noop);
  }

  @MutationAction({ mutate: ['loading'] })
  public async setLoading(loading: boolean): Promise<{ loading: boolean }> {
    return { loading };
  }
}
