// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).ace.define(
  'ace/theme/homeeTheme',
  ['require', 'exports', 'module', 'ace/lib/dom'],
  (
    acequire: (
      path: string
    ) => { importCssString: (...args: unknown[]) => void },
    exports: Record<string, unknown>
  ): void => {
    exports.isDark = true;
    exports.cssClass = 'ace-homee-theme';
    exports.cssText = `
        .ace-homee-theme .ace_gutter {
          background: #303030;
          color: #ffffff;
        }
  
        .ace-homee-theme.ace_editor {
          font-family: 'dejavu sans mono', 'droid sans mono', consolas, monaco,
            'lucida console', 'courier new', courier, monospace, sans-serif;
          line-height: 1.3;
          background-color: #303030;
        }
        .ace-homee-theme .ace_print-margin {
          width: 10px;
          height: 10px;
          background: #303030; /* test */
        }
        .ace-homee-theme .ace_scroller {
          background-color: #303030;
        }
        .ace-homee-theme .ace_text-layer {
          color: #ffffff;
        }
        .ace-homee-theme .ace_variable {
          color: #50d789;
        }
        .ace-homee-theme .ace_cursor {
          border-left: 2px solid #ffffff;
        }
        .ace-homee-theme .ace_overwrite-cursors .ace_cursor {
          border-left: 0px;
          border-bottom: 1px solid #ffffff; /* test */
        }
        .ace-homee-theme .ace_marker-layer .ace_selection {
          background: #404040;
        }
        .ace-homee-theme.ace_multiselect .ace_selection.ace_start {
          box-shadow: 0 0 3px 0px #303030; /* test */
          border-radius: 2px;
        }
        .ace-homee-theme .ace_marker-layer .ace_step {
          background: #303030; /* test */
        }
        .ace-homee-theme .ace_marker-layer .ace_bracket {
          margin: -1px 0 0 -1px;
          border: 1px solid #55bad8;
        }
        .ace-homee-theme .ace_marker-layer .ace_active-line {
          background: #404040;
        }
        .ace-homee-theme .ace_gutter-active-line {
          background-color: #404040;
        }
        .ace-homee-theme .ace_marker-layer .ace_selected-word {
          border: 1px solid #ffffff;
        }
        .ace-homee-theme .ace_invisible {
          color: #424242;
        }
        .ace-homee-theme .ace_keyword,
        .ace-homee-theme .ace_meta,
        .ace-homee-theme .ace_support.ace_constant.ace_property-value {
          color: #55bad8;
        }
        .ace-homee-theme .ace_keyword.ace_operator {
          color: #aeaeae;
        }
        .ace-homee-theme .ace_keyword.ace_other.ace_unit {
          color: #55bad8;
        }
        .ace-homee-theme .ace_constant.ace_language {
          color: darkorange;
        }
        .ace-homee-theme .ace_constant.ace_numeric {
          color: #f973ab;
        }
        .ace-homee-theme .ace_constant.ace_character.ace_entity {
          color: #bf78cc;
        }
        .ace-homee-theme .ace_invalid {
          color: #303030;
          background-color: #f96d5e;
        }
        .ace-homee-theme .ace_fold {
          background-color: #424242;
          border-color: #6f6f6f;
        }
        .ace-homee-theme .ace_storage,
        .ace-homee-theme .ace_support.ace_class,
        .ace-homee-theme .ace_support.ace_function,
        .ace-homee-theme .ace_support.ace_other,
        .ace-homee-theme .ace_support.ace_type {
          color: #f96d5e;
        }
        .ace-homee-theme .ace_string {
          color: #fcc047;
        }
        .ace-homee-theme .ace_comment {
          color: #329fbc;
        }
        .ace-homee-theme .ace_entity.ace_name.ace_tag,
        .ace-homee-theme .ace_entity.ace_other.ace_attribute-name {
          color: #aeaeae;
        }
        .ace-homee-theme .ace_markup.ace_underline {
          text-decoration: underline;
        }
        .ace-homee-theme .ace_indent-guide {
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bLly//BwAmVgd1/w11/gAAAABJRU5ErkJggg==')
            right repeat-y;
        }
  
        .ace_mobile-menu {
          background: #6f6f6f;
          border: 1px solid #303030;
          color: #ffffff;
          box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
        }`;

    const dom: { importCssString: (...args: unknown[]) => void } = acequire(
      '../lib/dom'
    );
    dom.importCssString(exports.cssText, exports.cssClass);
  }
);
