















































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  mdiLock,
  mdiPencil,
  mdiTrashCan,
  mdiDatabaseImport,
  mdiTag,
  mdiShape,
  mdiAntenna,
  mdiWeb,
  mdiContentDuplicate,
  mdiMenuUp,
  mdiMenuDown,
  mdiImageOutline
} from '@mdi/js';
import { caiAttributeTypes, caiProfiles } from '../plugins/vuetify';
import FieldComponent from '../components/FieldComponent.vue';
import FilterButtonComponent from '../components/FilterButtonComponent.vue';
import store, { userStore, volatileDocumentsStore } from '../plugins/store';
import { Timer } from 'vue-plugin-timers';
import {
  defaults as attributeTypeDefaults,
  getCategories as getAttributeTypeCategories
} from '../api/attributeType';
import {
  defaults as attributeValueDefaults,
  getCategories as getAttributeValueCategories
} from '../api/attributeValue';
import { getCategories as getConditionOperatorCategories } from '../api/conditionOperator';
import { getCategories as getCubeCategories } from '../api/cube';
import {
  defaults as homeegramClassDefaults,
  getCategories as getHomeegramClassCategories
} from '../api/homeegramClass';
import { getCategories as getLocalizationCategories } from '../api/localization';
import { getCategories as getPlaceholderCategories } from '../api/placeholder';
import {
  defaults as productDefaults,
  getCategories as getProductCategories,
  Product
} from '../api/product';
import {
  getCategories as getProductIconCategories,
  ProductIcon
} from '../api/productIcon';
import { getCategories as getProductTypeCategories } from '../api/productType';
import { getCategories as getProfileCategories } from '../api/profile';
import { getCategories as getProtocolCategories } from '../api/protocol';
import { getCategories as getServiceCategories } from '../api/service';
import { getCategories as getTriggerOperatorCategories } from '../api/triggerOperator';
import { getCategories as getUiElementCategories } from '../api/uiElement';
import { getCategories as getUseCaseCategories } from '../api/useCase';
import {
  lockDocument,
  unlockDocument,
  LOCK_TIMEOUT,
  updateDetailState,
  updateDocument,
  deleteDocument,
  createDocument,
  isEmpty,
  CREATE_GROUP,
  DELETE_GROUP,
  UPDATE_GROUP,
  serializeValue
} from '../api';
import { DocsFile, DocTypes, Document } from '../api/document';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import AsyncComputed from 'vue-async-computed-decorator';
import type { Category, DetailData, Field } from '../typings/field';
import type { Route, NavigationGuardNext } from 'vue-router';
import _ from 'lodash';
import { Bind, Throttle } from 'lodash-decorators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const detailMap: Record<DocTypes, (document: any) => Promise<DetailData>> = {
  [DocTypes.AttributeType]: getAttributeTypeCategories,
  [DocTypes.AttributeValue]: getAttributeValueCategories,
  [DocTypes.ConditionOperator]: getConditionOperatorCategories,
  [DocTypes.Cube]: getCubeCategories,
  [DocTypes.HomeegramClass]: getHomeegramClassCategories,
  [DocTypes.Localization]: getLocalizationCategories,
  [DocTypes.Placeholder]: getPlaceholderCategories,
  [DocTypes.Product]: getProductCategories,
  [DocTypes.ProductIcon]: getProductIconCategories,
  [DocTypes.ProductType]: getProductTypeCategories,
  [DocTypes.Profile]: getProfileCategories,
  [DocTypes.Protocol]: getProtocolCategories,
  [DocTypes.Service]: getServiceCategories,
  [DocTypes.TriggerOperator]: getTriggerOperatorCategories,
  [DocTypes.UiElement]: getUiElementCategories,
  [DocTypes.UseCase]: getUseCaseCategories
};

const defaultsMap: Partial<Record<DocTypes, Partial<Document>>> = {
  [DocTypes.AttributeType]: attributeTypeDefaults,
  [DocTypes.AttributeValue]: attributeValueDefaults,
  [DocTypes.HomeegramClass]: homeegramClassDefaults,
  [DocTypes.Product]: productDefaults
};

@Component({
  components: {
    FieldComponent,
    FilterButtonComponent
  }
})
export default class DetailComponent extends Vue {
  private readonly lockIconSvg: string = mdiLock;
  private readonly editIconSvg: string = mdiPencil;
  private readonly deleteIconSvg: string = mdiTrashCan;
  private readonly importIconSvg: string = mdiDatabaseImport;
  private readonly productIconSvg: string = mdiTag;
  private readonly profileIconSvg: string = caiProfiles;
  private readonly categoryIconSvg: string = mdiShape;
  private readonly protocolIconSvg: string = mdiAntenna;
  private readonly attributeIconSvg: string = caiAttributeTypes;
  private readonly localizationIconSvg: string = mdiWeb;
  private readonly cloneIconSvg: string = mdiContentDuplicate;
  private readonly upIconSvg: string = mdiMenuUp;
  private readonly downIconSvg: string = mdiMenuDown;
  private readonly DocTypes: typeof DocTypes = DocTypes;
  private readonly defaultImage: string =
    'data:image/svg+xml;base64,' +
    btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" height="200px" width="300px" viewBox="-37.5 -37.5 100 100" style="background-color: #FFF;"><path fill="#6F6F6F" d="${mdiImageOutline}"></path></svg>`
    );

  private showConfirmDelete: boolean = false;
  private showConfirmReset: boolean = false;
  private showConfirmMalformedAttachment: boolean = false;
  private tab: number = 0;
  private changes: Partial<Document> = {};
  private edit: boolean = false;
  private isLocked: boolean = false;
  private manualDropdown: boolean = false;
  private restored: boolean = false;
  private error: false | string = false;

  private get locale(): string {
    return userStore.locale;
  }

  private get tabCount(): number {
    return (
      ((this.detailData as unknown) as DetailData | null)?.categories?.length ||
      0
    );
  }

  private get createPermission(): boolean {
    return userStore.groups.includes(CREATE_GROUP);
  }

  private get updatePermission(): boolean {
    return userStore.groups.includes(UPDATE_GROUP);
  }

  private get deletePermission(): boolean {
    return userStore.groups.includes(DELETE_GROUP);
  }

  private get document(): Partial<Document> | null {
    if (this.isNewDocument) {
      return this.changes;
    }
    if (!volatileDocumentsStore.documents[this.id]) {
      return null;
    }
    this.isLocked =
      (volatileDocumentsStore.documents[this.id]._lockedAt || 0) >
      new Date().getTime() - LOCK_TIMEOUT;
    return {
      ...volatileDocumentsStore.documents[this.id],
      ...this.changes
    };
  }

  @Watch('tab')
  private onTabChanged(): void {
    (this.$refs.scrollContainer as Vue).$el.scrollTop = 0;
    this.closeMenues();
  }

  @Watch('showConfirmDelete')
  private onShowConfirmDeleteChanged(showConfirmDelete: boolean): void {
    if (showConfirmDelete) {
      setTimeout(
        (): void =>
          ((this.$refs.confirmDeleteBtn as Vue).$el as HTMLElement).focus(),
        100
      );
    }
  }

  @Watch('showConfirmReset')
  private onShowConfirmResetChanged(showConfirmReset: boolean): void {
    if (showConfirmReset) {
      setTimeout(
        (): void =>
          ((this.$refs.confirmResetBtn as Vue).$el as HTMLElement).focus(),
        100
      );
    }
  }

  @Watch('showConfirmMalformedAttachment')
  private onShowConfirmMalformedAttachmentChanged(
    showConfirmMalformedAttachment: boolean
  ): void {
    if (showConfirmMalformedAttachment) {
      setTimeout(
        (): void =>
          ((this.$refs.confirmMalformedAttachmentBtn as Vue)
            .$el as HTMLElement).focus(),
        100
      );
    }
  }

  @AsyncComputed({ default: null })
  private async detailData(): Promise<DetailData | null> {
    return this.document && this.docType
      ? detailMap[this.docType](this.document)
      : null;
  }

  private get dirty(): boolean {
    return Object.keys(this.changes || {}).length > 0;
  }

  private valid: boolean = true;

  private updateModel(field: keyof Document, data: unknown): void {
    if (!field) {
      return;
    }
    const document: Partial<Document> =
      volatileDocumentsStore.documents[this.id] || {};
    if (document[field] !== data) {
      Vue.set(this.changes, field as string, data);
    } else {
      Vue.delete(this.changes, field as string);
    }
  }

  @Timer({ interval: 1000, repeat: true })
  private updateIsLocked(): void {
    this.isLocked =
      (this.document?._lockedAt || 0) > new Date().getTime() - LOCK_TIMEOUT;
  }

  @Prop({ type: String })
  private readonly docType: DocTypes | undefined;

  @Prop({ type: String, default: '' })
  private readonly id!: string;

  @Prop({ type: Boolean, default: false })
  private readonly isNewDocument!: boolean;

  private async setLocked(lock: boolean): Promise<void> {
    try {
      if (
        !this.document?._id ||
        this.document?._changedAt == null ||
        this.document?._lockedAt == null
      ) {
        throw new Error('document is undefined');
      } else if (lock) {
        await lockDocument(
          this.document._id,
          this.document._changedAt,
          this.document._lockedAt
        );
      } else {
        await unlockDocument(
          this.document._id,
          this.document._changedAt,
          this.document._lockedAt
        );
      }
      this.setEdit(lock);
    } catch (e) {
      this.error = I18n.get(Translations.LOCK_ERROR);
      this.$logger.error(e);
    }
  }

  private setEdit(edit: boolean): void {
    this.changes = {};
    this.edit = edit;
    this.$emit('edit', edit);
  }

  private onEscape(): void {
    if (this.showConfirmDelete) {
      this.showConfirmDelete = false;
    } else if (this.showConfirmReset) {
      this.showConfirmReset = false;
    } else if (this.showConfirmMalformedAttachment) {
      this.showConfirmMalformedAttachment = false;
    } else if (this.edit) {
      this.confirmReset();
    }
  }

  private confirmReset(): void {
    if (this.dirty) {
      this.showConfirmReset = true;
    } else {
      this.reset();
    }
  }

  private reset(): void {
    this.showConfirmReset = false;
    if (this.isNewDocument) {
      this.setEdit(false);
      this.$router.push({
        path: `/${(this.docType ?? '').toLowerCase()}`
      });
    } else {
      this.setLocked(false);
    }
  }

  private created(): void {
    store.restored.finally((): void => void (this.restored = true));
  }

  private mounted(): void {
    if (!this.docType) {
      this.error = I18n.get(Translations.UNKNOWN_DOCTYPE);
      return;
    }
    if (!this.isNewDocument) {
      updateDetailState(this.docType, this.id).catch((err: Error): void => {
        this.error = I18n.get(Translations.LOAD_ERROR);
        this.$logger.error(err);
      });
    } else {
      this.setEdit(true);
      this.changes = defaultsMap[this.docType] || {};
    }
    if (this.docType === DocTypes.Product) {
      this.$mousetrap.bind('g g', (): void => void (this.tab = 0));
      this.$mousetrap.bind('g c', (): void => void (this.tab = 1));
      this.$mousetrap.bind('g a', (): void => void (this.tab = 2));
      this.$mousetrap.bind('g w', (): void => void (this.tab = 3));
      this.$mousetrap.bind('g m', (): void => void (this.tab = 4));
    }
    this.$mousetrap.bind('esc', (): void => this.onEscape());
    this.$mousetrap.bind('g 1', (): void => void (this.tab = 0));
    this.$mousetrap.bind('g 2', (): void => void (this.tab = 1));
    this.$mousetrap.bind('g 3', (): void => void (this.tab = 2));
    this.$mousetrap.bind('g 4', (): void => void (this.tab = 3));
    this.$mousetrap.bind('g 5', (): void => void (this.tab = 4));
    this.$mousetrap.bind('g 6', (): void => void (this.tab = 5));
    this.$mousetrap.bind('g 7', (): void => void (this.tab = 6));
    this.$mousetrap.bind('g 8', (): void => void (this.tab = 7));
    this.$mousetrap.bind('g 9', (): void => void (this.tab = 8));
    this.$mousetrap.bind('g 0', (): void => void (this.tab = 9));
    this.$mousetrap.bind(
      'right',
      (): void => void (this.tab = (this.tab + 1) % this.tabCount)
    );
    this.$mousetrap.bind(
      'left',
      (): void =>
        void (this.tab =
          (((this.tab - 1) % this.tabCount) + this.tabCount) % this.tabCount)
    );
    this.$mousetrap.bind(
      'e',
      (): void => void (!this.edit && this.setLocked(true))
    );
  }

  private saveDocument(confirmMalformedAttachment?: true): void {
    this.showConfirmMalformedAttachment = false;
    if (!this.docType) {
      this.error = I18n.get(Translations.UNKNOWN_DOCTYPE);
      this.$logger.error(new Error('docType is undefined'));
      return;
    }
    if (
      !confirmMalformedAttachment &&
      ((this.changes as Partial<ProductIcon>)?.icon?.error ||
        (this.changes as Partial<Product>)?.image?.error ||
        ((this.changes as Partial<Product>)?.manuals || []).some(
          (file: DocsFile): boolean => !!file?.error
        ))
    ) {
      this.showConfirmMalformedAttachment = true;
      return;
    }
    if (this.isNewDocument) {
      createDocument(this.docType, this.changes)
        .then((id: string): void => {
          this.setEdit(false);
          this.$router.push({
            path: `/${(this.docType ?? '').toLowerCase()}/${id}`
          });
        })
        .catch((err: Error): void => {
          this.error = I18n.get(Translations.SAVE_ERROR);
          this.$logger.error(err);
        });
    } else {
      if (this.document?._changedAt == null) {
        this.error = I18n.get(Translations.SAVE_ERROR);
        this.$logger.error(new Error('document is undefined'));
        return;
      }
      updateDocument(
        this.id,
        this.docType,
        this.document._changedAt,
        this.changes
      )
        .then((): void => this.setEdit(false))
        .catch((err: Error): void => {
          this.error = I18n.get(Translations.SAVE_ERROR);
          this.$logger.error(err);
        });
    }
  }

  private deleteDocument(): void {
    if (!this.document?._id) {
      this.error = I18n.get(Translations.DELETE_ERROR);
      this.$logger.error(new Error('document is undefined'));
      return;
    }
    deleteDocument(this.document._id)
      .then((): void => {
        this.setEdit(false);
        this.$router.push({
          path: `/${(this.docType ?? '').toLowerCase()}`
        });
      })
      .catch((err: Error): void => {
        this.showConfirmDelete = false;
        this.error = I18n.get(Translations.DELETE_ERROR);
        this.$logger.error(err);
      });
  }

  private async cloneDocument(): Promise<void> {
    let properties: string[];
    switch (this.docType) {
      case DocTypes.Product:
        properties = ((await getProductCategories({}))?.categories || [])
          .filter((category: Category): boolean =>
            [
              I18n.get(Translations.GENERAL),
              I18n.get(Translations.CORE)
            ].includes(category.name)
          )
          .reduce(
            (properties: string[], category: Category): string[] => [
              ...properties,
              ...(category.fields
                .map((field: Field): string | undefined => field.model)
                .filter(Boolean) as string[])
            ],
            []
          );
        break;
      case DocTypes.AttributeType:
        properties = [
          'descriptionDev',
          'isChartsAttribute',
          'chartPeriods',
          'services',
          'decimals',
          'isAlarmAttribute',
          'uiElements',
          'homeegramClasses'
        ];
        break;
      case DocTypes.AttributeValue:
        properties = ['attributeType', 'isTrigger', 'isCondition', 'isAction'];
        break;
      default:
        return;
    }
    this.changes = Object.entries(
      _.pickBy(
        volatileDocumentsStore.documents[this.id],
        (_0: unknown, property: string): boolean =>
          properties.includes(property)
      )
    ).reduce(
      (
        input: Partial<Document>,
        [key, value]: [string, unknown]
      ): Partial<Document> => ({
        ...input,
        ...(isEmpty(value) ? {} : { [key]: serializeValue(value) })
      }),
      {}
    );
    this.$router
      .push({
        path: `/${(this.docType ?? '').toLowerCase()}/new`
      })
      .then((): void => {
        this.edit = true;
        this.$emit('edit', true);
      });
  }

  @Throttle(500)
  @Bind()
  private closeMenues(): void {
    ([] as FieldComponent[])
      .concat((this.$refs?.field as FieldComponent | FieldComponent[]) || [])
      .forEach((field: FieldComponent): void => field.closeMenu());
  }

  private unlockDocument(): Promise<void> {
    if (
      !this.edit ||
      !this.isLocked ||
      !this.document?._id ||
      this.document?._changedAt == null ||
      this.document?._lockedAt == null
    ) {
      return Promise.resolve();
    }
    return unlockDocument(
      this.document._id,
      this.document._changedAt,
      this.document._lockedAt
    );
  }

  private beforeRouteEnter(
    to: Route,
    _from: Route,
    next: NavigationGuardNext
  ): void {
    document.title = Translations[(to.params?.docType ?? '').toUpperCase()]
      ? `${I18n.get(
          Translations[to.params.docType.toUpperCase()]
        )} - homee Docs`
      : 'homee Docs';
    next();
  }

  private beforeRouteUpdate(
    _to: Route,
    _from: Route,
    next: NavigationGuardNext
  ): void {
    this.unlockDocument()
      .then((): void => next())
      .catch((): void => next(false));
  }

  private beforeRouteLeave(
    _to: Route,
    _from: Route,
    next: NavigationGuardNext
  ): void {
    this.unlockDocument()
      .then((): void => next())
      .catch((): void => next(false));
  }

  private beforeMount(): void {
    window.addEventListener('beforeunload', this.unlockDocument);
  }

  private beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.unlockDocument);
  }
}
