import type { Document, DocTypes, DocsFilter } from './document';
import { Translations } from '../plugins/i18n';
import { I18n } from '@aws-amplify/core';
import { FieldTypes, DetailData } from '../typings/field';
import { persistentDocumentsStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface Service extends Document {
  _docType: DocTypes.Service;
  name: string;
  bit: number;
}

export const defaultListProperties: string = `
  name
  bit
`;

export const defaultDetailProperties: string = `
  name
  bit
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.NAME),
      value: 'name',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.BIT),
      value: 'bit',
      align: 'start',
      sortable: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: Service[] = Object.values(persistentDocumentsStore.Service);
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (document: Service): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      name: document.name,
      bit: document.bit ?? ''
    })
  );
}

export const sortListBy: string | string[] = 'bit';

export async function getCategories(
  document: Partial<Service>
): Promise<DetailData> {
  return {
    title: document.name,
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.NAME),
            required: true,
            model: 'name',
            value: document.name
          },
          {
            type: FieldTypes.NUMBER,
            label: I18n.get(Translations.BIT),
            required: true,
            model: 'bit',
            value: document.bit
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.NAME),
      property: 'name'
    },
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'le',
        'lt',
        'ge',
        'gt',
        'between',
        'in'
      ],
      label: I18n.get(Translations.BIT),
      validator: (data: string): boolean => !isNaN(parseInt(data)),
      property: 'bit'
    }
  ];
}
