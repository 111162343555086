import { Document, DocTypes, DocsFile, DocsFilter } from './document';
import type { HomeegramClass } from './homeegramClass';
import type { ProductIcon } from './productIcon';
import type { Service } from './service';
import type { UiElement } from './uiElement';
import type { Localization } from './localization';
import { Translations } from '../plugins/i18n';
import { I18n } from '@aws-amplify/core';
import { FieldTypes, DetailData } from '../typings/field';
import { persistentDocumentsStore, userStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { AttributeValue } from './attributeValue';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export enum TimePeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export interface AttributeType extends Document {
  _docType: DocTypes.AttributeType;
  name: string;
  number: number;
  descriptionDev: string;
  isChartsAttribute: boolean | null;
  chartPeriods: TimePeriod[] | null;
  services: Service[] | null;
  displayName: Localization | null;
  description: Localization | null;
  article: Localization | null;
  decimals: number | null;
  isAlarmAttribute: boolean | null;
  productIcons: ProductIcon[] | null;
  uiElements: UiElement[] | null;
  homeegramClasses: HomeegramClass[] | null;
}

export const defaultListProperties: string = `
  name
  number
  displayName {
    _id
  }
  productIcons {
    _id
  }
`;

export const defaultDetailProperties: string = `
  name
  number
  descriptionDev
  isChartsAttribute
  chartPeriods
  services {
    _id
  }
  displayName {
    _id
  }
  description {
    _id
  }
  article {
    _id
  }
  decimals
  isAlarmAttribute
  productIcons {
    _id
  }
  uiElements {
    _id
  }
  homeegramClasses {
    _id
  }
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.ENUM_KEY),
      value: 'name',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.ENUM_VALUE),
      value: 'number',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.DISPLAY_NAME),
      value: 'displayName',
      align: 'start',
      sortable: true
    },
    {
      text: '',
      value: 'displayNameKey',
      hidden: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: AttributeType[] = Object.values(
    persistentDocumentsStore.AttributeType
  );
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (
      document: AttributeType
    ): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      name: document.name,
      number: document.number ?? '',
      displayName:
        persistentDocumentsStore.Localization[
          document.displayName?._id ?? ''
        ]?.[userStore.locale],
      displayNameKey:
        persistentDocumentsStore.Localization[document.displayName?._id ?? '']
          ?.stringIdentifier
    })
  );
}

export const sortListBy: string | string[] = 'number';

export const defaults: Partial<AttributeType> = {
  isChartsAttribute: false,
  isAlarmAttribute: false
};

export async function getCategories(
  document: Partial<AttributeType>
): Promise<DetailData> {
  return {
    title: document.name,
    subtitle: document.number?.toString(),
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.ENUM_KEY),
            required: true,
            model: 'name',
            value: document.name,
            prefix: 'CAAttributeType'
          },
          {
            type: FieldTypes.NUMBER,
            label: I18n.get(Translations.ENUM_VALUE),
            required: true,
            model: 'number',
            value: document.number
          },
          {
            type: FieldTypes.MULTILINE,
            label: I18n.get(Translations.DESCRIPTION_DEV),
            required: true,
            model: 'descriptionDev',
            value: document.descriptionDev
          },
          {
            type: FieldTypes.BOOLEAN,
            label: I18n.get(Translations.IS_CHARTS_ATTRIBUTE),
            required: false,
            model: 'isChartsAttribute',
            value: document.isChartsAttribute
          },
          {
            type: FieldTypes.SELECT,
            label: I18n.get(Translations.CHART_PERIODS),
            required: false,
            model: 'chartPeriods',
            value: document.chartPeriods,
            multiple: true,
            options: [
              {
                text: I18n.get(Translations.DAY),
                value: TimePeriod.DAY
              },
              {
                text: I18n.get(Translations.MONTH),
                value: TimePeriod.MONTH
              },
              {
                text: I18n.get(Translations.WEEK),
                value: TimePeriod.WEEK
              },
              {
                text: I18n.get(Translations.YEAR),
                value: TimePeriod.YEAR
              }
            ]
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.SERVICES),
            required: false,
            model: 'services',
            value: document.services,
            docType: DocTypes.Service,
            textField: (item: Document): string =>
              persistentDocumentsStore.Service[item._id]?.name || 'unknown',
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.Service),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.DISPLAY_NAME),
            required: false,
            model: 'displayName',
            value: document.displayName,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_NAME')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.DESCRIPTION),
            required: false,
            model: 'description',
            value: document.description,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                !loc.stringIdentifier.toUpperCase().includes('_VALUE_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ARTICLE),
            required: false,
            model: 'article',
            value: document.article,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter((loc: Localization): boolean =>
              loc.stringIdentifier.toUpperCase().startsWith('GENERAL_ARTICLE_')
            ),
            multiple: false
          },
          {
            type: FieldTypes.NUMBER,
            label: I18n.get(Translations.DECIMALS),
            required: false,
            model: 'decimals',
            value: document.decimals
          },
          {
            type: FieldTypes.BOOLEAN,
            label: I18n.get(Translations.IS_ALARM_ATTRIBUTE),
            required: false,
            model: 'isAlarmAttribute',
            value: document.isAlarmAttribute
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PRODUCT_ICONS),
            required: false,
            model: 'productIcons',
            value: document.productIcons,
            docType: DocTypes.ProductIcon,
            textField: (item: Document): string =>
              persistentDocumentsStore.ProductIcon[item._id]?.name || 'unknown',
            valueField: '_id',
            lightupIcons: true,
            iconField: (item: Document): string => {
              const icon: DocsFile =
                persistentDocumentsStore.ProductIcon[item._id]?.icon;
              if (!icon) {
                return '';
              }
              return `https://${
                process.env.S3_BUCKET
              }/${DocTypes.ProductIcon.toLocaleLowerCase()}/${item._id}/${
                icon.name
              }${icon.extension ? `.${icon.extension}` : ''}?version=${
                icon.version
              }`;
            },
            options: Object.values(persistentDocumentsStore.ProductIcon),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.UI_ELEMENTS),
            required: false,
            model: 'uiElements',
            value: document.uiElements,
            docType: DocTypes.UiElement,
            textField: (item: Document): string =>
              persistentDocumentsStore.UiElement[item._id]?.label || 'unknown',
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.UiElement),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.HOMEEGRAM_CLASSES),
            required: false,
            model: 'homeegramClasses',
            value: document.homeegramClasses,
            docType: DocTypes.HomeegramClass,
            textField: (item: Document): string =>
              persistentDocumentsStore.HomeegramClass[item._id]?.name ||
              'unknown',
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.HomeegramClass),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTEVALUES),
            required: false,
            hideInEditMode: true,
            value: Object.values(persistentDocumentsStore.AttributeValue)
              .filter(
                (item: AttributeValue): boolean =>
                  item.attributeType?._id === document?._id
              )
              .sort((item1: AttributeValue, item2: AttributeValue): number =>
                item1.value
                  .toString()
                  .localeCompare(item2.value.toString(), undefined, {
                    numeric: true,
                    sensitivity: 'base'
                  })
              ),
            docType: DocTypes.AttributeValue,
            textField: (item: Document): string => {
              const doc: AttributeValue =
                persistentDocumentsStore.AttributeValue[item._id];
              return `${
                persistentDocumentsStore.Localization[
                  doc?.display?._id || ''
                ]?.[userStore.locale] ||
                doc?.meaning ||
                'unknown'
              }`;
            },
            additionalText: (item: Document): string =>
              `${persistentDocumentsStore.AttributeValue[item._id]?.value}`,
            valueField: '_id',
            options: Object.values(
              persistentDocumentsStore.AttributeValue
            ).filter(
              (item: AttributeValue): boolean =>
                item.attributeType?._id === document?._id
            ),
            multiple: true
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      label: I18n.get(Translations.ENUM_KEY),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'name'
    },
    {
      label: I18n.get(Translations.ENUM_VALUE),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'le',
        'lt',
        'ge',
        'gt',
        'between',
        'in'
      ],
      property: 'number',
      validator: (data: string): boolean => !isNaN(parseInt(data))
    },
    {
      label: I18n.get(Translations.DESCRIPTION_DEV),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'descriptionDev'
    },
    {
      label: I18n.get(Translations.IS_CHARTS_ATTRIBUTE),
      operators: ['eq', 'ne', 'exists', 'notExists'],
      property: 'isChartsAttribute',
      isBoolean: true
    },
    {
      label: I18n.get(Translations.CHART_PERIODS),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'chartPeriods',
      options: [
        {
          text: I18n.get(Translations.DAY),
          value: TimePeriod.DAY
        },
        {
          text: I18n.get(Translations.MONTH),
          value: TimePeriod.MONTH
        },
        {
          text: I18n.get(Translations.WEEK),
          value: TimePeriod.WEEK
        },
        {
          text: I18n.get(Translations.YEAR),
          value: TimePeriod.YEAR
        }
      ]
    },
    {
      label: I18n.get(Translations.SERVICES),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'services',
      textField: (item: Document): string =>
        persistentDocumentsStore.Service[item._id]?.name || 'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Service)
    },
    {
      label: I18n.get(Translations.DISPLAY_NAME),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'displayName',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_NAME')
      )
    },
    {
      label: I18n.get(Translations.DESCRIPTION),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'description',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          !loc.stringIdentifier.toUpperCase().includes('_VALUE_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
      )
    },
    {
      label: I18n.get(Translations.ARTICLE),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'article',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(
        persistentDocumentsStore.Localization
      ).filter((loc: Localization): boolean =>
        loc.stringIdentifier.toUpperCase().startsWith('GENERAL_ARTICLE_')
      )
    },
    {
      label: I18n.get(Translations.DECIMALS),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'le',
        'lt',
        'ge',
        'gt',
        'between',
        'in'
      ],
      property: 'decimals',
      validator: (data: string): boolean => !isNaN(parseInt(data))
    },
    {
      label: I18n.get(Translations.IS_ALARM_ATTRIBUTE),
      operators: ['eq', 'ne', 'exists', 'notExists'],
      property: 'isAlarmAttribute',
      isBoolean: true
    },
    {
      label: I18n.get(Translations.PRODUCT_ICONS),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'productIcons',
      textField: (item: Document): string =>
        persistentDocumentsStore.ProductIcon[item._id]?.name || 'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.ProductIcon)
    },
    {
      label: I18n.get(Translations.UI_ELEMENTS),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'uiElements',
      textField: (item: Document): string =>
        persistentDocumentsStore.UiElement[item._id]?.label || 'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.UiElement)
    },
    {
      label: I18n.get(Translations.HOMEEGRAM_CLASSES),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'homeegramClasses',
      textField: (item: Document): string =>
        persistentDocumentsStore.HomeegramClass[item._id]?.name || 'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.HomeegramClass)
    }
  ];
}
