import { render, staticRenderFns } from "./TableListComponent.vue?vue&type=template&id=25e12185&scoped=true&"
import script from "./TableListComponent.vue?vue&type=script&lang=ts&"
export * from "./TableListComponent.vue?vue&type=script&lang=ts&"
import style0 from "./TableListComponent.vue?vue&type=style&index=0&id=25e12185&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e12185",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VDataTable,VIcon,VSkeletonLoader,VTooltip})
