/* eslint-disable no-console */

import { register } from 'register-service-worker';
// TODO: https://medium.com/@webmaxru/workbox-4-implementing-refresh-to-update-version-flow-using-the-workbox-window-module-41284967e79c

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registered(registration: ServiceWorkerRegistration): void {
      setInterval((): void => {
        registration.update();
      }, 1000 * 60 * 60); // hourly checks
    },
    updated(registration: ServiceWorkerRegistration): void {
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    }
  });
}
