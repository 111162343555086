import { Document, DocTypes, DocsFile, DocsFilter } from './document';
import type { AttributeType } from './attributeType';
import type { ProductIcon } from './productIcon';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import { FieldTypes, DetailData } from '../typings/field';
import { persistentDocumentsStore, userStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface Profile extends Document {
  _docType: DocTypes.Profile;
  name: string;
  number: number;
  primaryAttributes: AttributeType[];
  secondaryAttributes: AttributeType[] | null;
  tertiaryAttributes: AttributeType[] | null;
  productIcon: ProductIcon | null;
  selectableProductIcons: ProductIcon[] | null;
}

export const defaultListProperties: string = `
  name
  number
  primaryAttributes{
    _id
  }
  secondaryAttributes{
    _id
  }
  tertiaryAttributes{
    _id
  }
`;

export const defaultDetailProperties: string = `
  name
  number
  primaryAttributes{
    _id
  }
  secondaryAttributes{
    _id
  }
  tertiaryAttributes{
    _id
  }
  productIcon {
    _id
  }
  selectableProductIcons {
    _id
  }
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.ENUM_KEY),
      value: 'name',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.ENUM_VALUE),
      value: 'number',
      align: 'start',
      sortable: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: Profile[] = Object.values(persistentDocumentsStore.Profile);
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (document: Profile): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      name: document.name,
      number: document.number ?? ''
    })
  );
}

export const sortListBy: string | string[] = 'number';

export async function getCategories(
  document: Partial<Profile>
): Promise<DetailData> {
  return {
    title: document.name,
    subtitle: document.number?.toString(),
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.ENUM_KEY),
            required: true,
            model: 'name',
            value: document.name,
            prefix: 'CANodeProfile'
          },
          {
            type: FieldTypes.NUMBER,
            label: I18n.get(Translations.ENUM_VALUE),
            required: true,
            model: 'number',
            value: document.number
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTETYPES_PRIMARY),
            infoText: I18n.get(Translations.HELPER_PRIMARY_ATTRIBUTES),
            required: true,
            model: 'primaryAttributes',
            value: document.primaryAttributes,
            docType: DocTypes.AttributeType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.AttributeType[item._id]?.displayName
                  ?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: AttributeType =
                persistentDocumentsStore.AttributeType[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.AttributeType).sort(
              (item1: AttributeType, item2: AttributeType): number =>
                item1.number - item2.number
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTETYPES_SECONDARY),
            infoText: I18n.get(Translations.HELPER_SECONDARY_ATTRIBUTES),
            required: false,
            model: 'secondaryAttributes',
            value: document.secondaryAttributes,
            docType: DocTypes.AttributeType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.AttributeType[item._id]?.displayName
                  ?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: AttributeType =
                persistentDocumentsStore.AttributeType[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.AttributeType).sort(
              (item1: AttributeType, item2: AttributeType): number =>
                item1.number - item2.number
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTETYPES_TERTIARY),
            infoText: I18n.get(Translations.HELPER_TERTIARY_ATTRIBUTES),
            required: false,
            model: 'tertiaryAttributes',
            value: document.tertiaryAttributes,
            docType: DocTypes.AttributeType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.AttributeType[item._id]?.displayName
                  ?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: AttributeType =
                persistentDocumentsStore.AttributeType[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.AttributeType).sort(
              (item1: AttributeType, item2: AttributeType): number =>
                item1.number - item2.number
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.DEFAULT_PRODUCT_ICON),
            required: false,
            model: 'productIcon',
            value: document.productIcon,
            docType: DocTypes.ProductIcon,
            textField: (item: Document): string =>
              persistentDocumentsStore.ProductIcon[item._id]?.name || 'unknown',
            valueField: '_id',
            lightupIcons: true,
            iconField: (item: Document): string => {
              const icon: DocsFile =
                persistentDocumentsStore.ProductIcon[item._id]?.icon;
              if (!icon) {
                return '';
              }
              return `https://${
                process.env.S3_BUCKET
              }/${DocTypes.ProductIcon.toLocaleLowerCase()}/${item._id}/${
                icon.name
              }${icon.extension ? `.${icon.extension}` : ''}?version=${
                icon.version
              }`;
            },
            options: (document.selectableProductIcons || []).map(
              (item: Document | string): Document =>
                persistentDocumentsStore.ProductIcon[
                  !item || typeof item !== 'object' ? item : item._id
                ]
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.SELECTABLE_PRODUCT_ICONS),
            required: false,
            model: 'selectableProductIcons',
            value: document.selectableProductIcons,
            docType: DocTypes.ProductIcon,
            textField: (item: Document): string =>
              persistentDocumentsStore.ProductIcon[item._id]?.name || 'unknown',
            valueField: '_id',
            lightupIcons: true,
            iconField: (item: Document): string => {
              const icon: DocsFile =
                persistentDocumentsStore.ProductIcon[item._id]?.icon;
              if (!icon) {
                return '';
              }
              return `https://${
                process.env.S3_BUCKET
              }/${DocTypes.ProductIcon.toLocaleLowerCase()}/${item._id}/${
                icon.name
              }${icon.extension ? `.${icon.extension}` : ''}?version=${
                icon.version
              }`;
            },
            options: Object.values(
              (document.primaryAttributes || []).reduce(
                (
                  profileIcons: Record<string, ProductIcon>,
                  attribute: Document | string
                ): Record<string, ProductIcon> => ({
                  ...profileIcons,
                  ...(
                    persistentDocumentsStore.AttributeType[
                      !attribute || typeof attribute !== 'object'
                        ? attribute
                        : attribute._id
                    ]?.productIcons || []
                  ).reduce(
                    (
                      attributeIcons: Record<string, ProductIcon>,
                      icon: Document
                    ): Record<string, ProductIcon> => ({
                      ...attributeIcons,
                      [icon._id]: persistentDocumentsStore.ProductIcon[icon._id]
                    }),
                    {} as Record<string, ProductIcon>
                  )
                }),
                {} as Record<string, ProductIcon>
              )
            ),
            multiple: true
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.ENUM_KEY),
      property: 'name'
    },
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'le',
        'lt',
        'ge',
        'gt',
        'between',
        'in'
      ],
      label: I18n.get(Translations.ENUM_VALUE),
      validator: (data: string): boolean => !isNaN(parseInt(data)),
      property: 'number'
    },
    {
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      label: I18n.get(Translations.ATTRIBUTETYPES_PRIMARY),
      property: 'primaryAttributes',
      textField: (item: Document): string => {
        const doc: AttributeType =
          persistentDocumentsStore.AttributeType[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.AttributeType).sort(
        (item1: AttributeType, item2: AttributeType): number =>
          item1.number - item2.number
      )
    },
    {
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      label: I18n.get(Translations.ATTRIBUTETYPES_SECONDARY),
      property: 'secondaryAttributes',
      textField: (item: Document): string => {
        const doc: AttributeType =
          persistentDocumentsStore.AttributeType[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.AttributeType).sort(
        (item1: AttributeType, item2: AttributeType): number =>
          item1.number - item2.number
      )
    },
    {
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      label: I18n.get(Translations.ATTRIBUTETYPES_TERTIARY),
      property: 'tertiaryAttributes',
      textField: (item: Document): string => {
        const doc: AttributeType =
          persistentDocumentsStore.AttributeType[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.AttributeType).sort(
        (item1: AttributeType, item2: AttributeType): number =>
          item1.number - item2.number
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.DEFAULT_PRODUCT_ICON),
      property: 'productIcon',
      textField: (item: Document): string =>
        persistentDocumentsStore.ProductIcon[item._id]?.name || 'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.ProductIcon)
    },
    {
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      label: I18n.get(Translations.SELECTABLE_PRODUCT_ICONS),
      property: 'selectableProductIcons',
      textField: (item: Document): string =>
        persistentDocumentsStore.ProductIcon[item._id]?.name || 'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.ProductIcon)
    }
  ];
}
