import type _Vue from 'vue';
import { I18n } from '@aws-amplify/core';

export enum Locale {
  de = 'de',
  en = 'en'
}

export const supportedLanguages: Locale[] = Object.values(Locale);

export const Translations: Record<string, string> = {
  BACK_TO_SIGN_IN: 'Back to Login',
  CODE_LABEL: 'Verification Code *',
  CODE_PLACEHOLDER: 'Enter the Verification Code',
  CODE_SENT: 'Verification Code has been sent.',
  CONFIRM_USER_HEADER: 'Confirm Account',
  LOST_CODE: 'Lost your Code?',
  RESEND_CODE: 'Resend Code',
  CONFIRM_BUTTON: 'Confirm',
  REGISTER: 'Create Account',
  DOMAIN_NOT_ALLOWED: 'Domain not allowed.',
  EMAIL_LABEL: 'Email Address *',
  EMAIL_PLACEHOLDER: 'Enter your Email Address',
  FORGOT_PASSWORD: 'Forgot your Password?',
  INVALID_MAIL_ADDRESS: 'Invalid Mail Address.',
  NAME_LABEL: 'Full Name *',
  NAME_PLACEHOLDER: 'Enter your full Name',
  NEW_PASSWORD_LABEL: 'New Password *',
  NEW_PASSWORD_PLACEHOLDER: 'Enter a new Password',
  NO_ACCOUNT: 'No Account?',
  ONLY_NUMBERS: 'Must only contain numeric Characters.',
  PASSWORD_LABEL: 'Password *',
  PASSWORD_PLACEHOLDER: 'Enter your Password',
  PASSWORD_A_PLACEHOLDER: 'Enter a Password',
  TOO_SHORT: 'Is too short.',
  TOO_LONG: 'Is too long.',
  REQUIRE_LOWERCASE: 'Must contain lowercase Characters.',
  REQUIRE_NUMBERS: 'Must contain numeric Characters.',
  REQUIRE_SYMBOLS: 'Must contain symbol Characters.',
  REQUIRE_UPPERCASE: 'Must contain uppercase Characters.',
  REQUIRED: 'Required.',
  RESET_PASSWORD: 'Reset Password',
  RESET_PASSWORD_HEADER: 'Reset your Password',
  SEND_CODE: 'Send Code',
  LOGIN_BUTTON: 'Login',
  LOGIN_HEADER: 'Login to your Account',
  LOGIN: 'Login',
  HAVE_ACCOUNT: 'Have an Account?',
  REGISTER_HEADER: 'Create a new Account',
  REGISTER_BUTTON: 'Create Account',
  LOGOUT: 'Logout',
  // TODO
  PREPARE_DATABASE: 'Synchronizing Database ...',
  PREPARE_DATABASE_WARNING: 'Please do NOT close or refresh the page!',
  EDIT_PROFILE: 'Edit Profile',
  EMAIL: 'Email',
  SAVE: 'Save',
  COULD_NOT_SAVE_PROFILE: 'Could not save profile!',
  ABORT: 'Abort',
  DELETE: 'Delete',
  EDIT: 'Edit',
  SEARCH_IN_PRODUCTS: 'Search in Products',
  SEARCH_IN_PROFILES: 'Search in Profiles',
  SEARCH_PRIMARY_IN_PROFILES: 'Search in Profiles (primary)',
  SEARCH_SECONDARY_IN_PROFILES: 'Search in Profiles (secondary)',
  SEARCH_TERTIARY_IN_PROFILES: 'Search in Profiles (tertiary)',
  SEARCH_IN_HOMEEGRAM_CLASSES: 'Search in Homeegram Classes',
  SEARCH_IN_PROTOCOLS: 'Search in Protocols',
  SEARCH_IN_ATTRIBUTE_TYPES: 'Search in Attribute Types',
  SEARCH_IN_PRODUCT_TYPES: 'Search in Product Types',
  SEARCH_IN_LOCALIZATIONS: 'Search in Localizations',
  CLOSE: 'Close',
  MORE_PRODUCT: 'For Products',
  MORE_ATTRIBUTE: 'For Attributes',
  MORE_HOMEEGRAM: 'For Homeegrams',
  MORE_LOCALIZATION: 'For Localization',
  UNKNOWN_DOCTYPE: 'Unknown DocType!',
  DISCARD: 'Discard',
  DISCARD_CHANGES: 'Discard Changes',
  CONFIRM_DISCARD_CHANGES:
    'The document contains unsaved changes. Do you want to discard these changes?',
  DELETE_DOCUMENT: 'Delete Document',
  CONFIRM_DELETE_DOCUMENT:
    'Are you sure that you want to delete this Document?',
  DELETE_DOCUMENTS: 'Delete Documents',
  CONFIRM_DELETE_DOCUMENTS:
    'Are you sure that you want to delete these Documents?',
  DELETE_DOCUMENTS_ERROR: 'Could not delete all Documents!',
  MALFORMED_ATTACHMENT: 'Attachment guidelines',
  CONFIRM_MALFORMED_ATTACHMENT:
    'Attachment does not comply with guidelines.\nAre you sure that you want to save this Document?',
  CURRENTLY_LOCKED: 'Currently locked!',
  CLONE: 'Clone Document',
  UNIQUE: 'Value has to be unique.',
  WRONG_RESOLUTION: 'Resolution to low.',
  WRONG_FILETYPE: 'Wrong Filetype.',
  WRONG_FILESIZE: 'Too big.',
  REPLACE_PLACEHOLDERS: 'Contains product-specific Terms.',
  COPIED_TO_CLIPBOARD: 'Key was copied to Clipboard.',
  COPY_TO_CLIPBOARD_FAILED: 'Key could not be copied to Clipboard.',
  WARN_UNCATEGORIZED: 'These Profiles do not include all selected Attributes!',
  ORIGINAL_FILE: 'Original File',
  LANGUAGE: 'Language',
  GERMAN: 'German',
  ENGLISH: 'English',
  PRODUCTS: 'Products',
  FILE_UPLOAD: 'File Selection',
  FILE_UPLOAD_DROPZONE: 'Drop here or click to upload.',
  FILE_UPLOAD_DROPZONE_PDF_MULTI: 'Use PDF files with reduced size (<5MB)!',
  FILE_UPLOAD_DROPZONE_PDF_SINGLE: 'Use PDF file with reduced size (<5MB)!',
  FILE_UPLOAD_DROPZONE_XLSX_MULTI: 'Use XLSX files!',
  FILE_UPLOAD_DROPZONE_XLSX_SINGLE: 'Use XLSX file!',
  FILE_UPLOAD_DROPZONE_TIFF_MULTI:
    'Use TIFF files with high resolution (>12MP) and transparent background!',
  FILE_UPLOAD_DROPZONE_TIFF_SINGLE:
    'Use TIFF file with high resolution (>12MP) and transparent background!',
  FILE_UPLOAD_DROPZONE_SVG_MULTI: 'Use SVG files with transparent background!',
  FILE_UPLOAD_DROPZONE_SVG_SINGLE: 'Use SVG file with transparent background!',
  UPLOAD: 'Upload',
  CREATE_NEW_KEYS: 'Create new Keys',
  SHEET_SELECTION: 'Sheet Selection',
  COLUMN_SELECTION: 'Column Selection',
  SHEET: 'Sheet',
  HAS_HEADLINE: 'Table has Header',
  GALLERY: 'Gallery',
  LOCALIZATION_IMPORT: 'Localization Bulkimport',
  IMPORT_LANGUAGE: 'Import',
  IMPORT_NO_CHANGE: 'Nothing changed.',
  IMPORT_DONE: 'Import finished',
  IMPORT_SKIP: 'Nothing changed',
  IMPORT_OK: 'Import succeeded for the following Keys:',
  IMPORT_ERROR: 'Import failed for the following Keys:',
  COULD_NOT_LOAD_XLSX: 'Could not load XLSX!',
  COLUMN: 'Column',
  IMPORT_OVERWRITE: 'Overwrite',
  REQUIRED_FIELD: 'Marks mandatory Fields',
  ATTRIBUTETYPES: 'Attribute Types',
  ATTRIBUTETYPES_UNCATEGORIZED: 'Uncategorized Attribute Types',
  ATTRIBUTETYPES_PRIMARY: 'Primary Attribute Types',
  ATTRIBUTETYPES_SECONDARY: 'Secondary Attribute Types',
  ATTRIBUTETYPES_TERTIARY: 'Tertiary Attribute Types',
  VALUE: 'Value',
  VALUES: 'Values',
  MEANING: 'Meaning',
  COMMAND: 'Command',
  TRIGGER_DESCRIPTION: 'Trigger Description',
  TRIGGER_BRICK: 'Trigger Brick',
  CONDITION_DESCRIPTION: 'Condition Description',
  CONDITION_BRICK: 'Condition Brick',
  CONDITION_DESCRIPTION_NOT: 'Opposite Condition Description',
  CONDITION_BRICK_NOT: 'Opposite Condition Brick',
  ACTION_DESCRIPTION: 'Action Description',
  ACTION_BRICK: 'Action Brick',
  STRING_IDENTIFIER: 'String Identifier',
  NOTE: 'Note',
  PROFILES: 'Profiles',
  SEARCH: 'Search',
  ADD: 'Add',
  GENERAL: 'General',
  CORE: 'Core',
  EXCEL: 'Excel',
  JSON: 'JSON',
  ALL: 'All',
  APPS: 'Apps',
  WEBSITE: 'Website',
  PRODUCT_NAME: 'Product name',
  MANUFACTURER: 'Manufacturer',
  INTEGRATION_STATE: 'Integration State',
  ANDROID: 'Android',
  IOS: 'iOS',
  WEB: 'Web',
  NOTIFICATION_SERVER: 'Notification Server',
  TODO: 'ToDo',
  IN_DEVELOPMENT: 'In Development',
  DONE: 'Done',
  EXPORT_APPS: 'Export Apps',
  EXPORT_WEBSITE: 'Export Website',
  EXPORT_STATE: 'Export State',
  YES: 'Yes',
  NO: 'No',
  COPY: 'Copy',
  NO_EXPORT: 'No Export',
  INVISIBLE_EXPORT: 'Export, only visible with Cube',
  VISIBLE_EXPORT: 'Export, always visible',
  PRIVATE: 'Private',
  STAGING: 'Staging',
  PUBLIC: 'Public',
  PRODUCT_LOCATION: 'Product Location',
  OFFICE: 'Office',
  TEAM: 'Team',
  PARTNER: 'Partner company',
  CUSTOMER: 'Customer',
  MISSING: 'Missing',
  MISSING_LOWER: 'missing',
  BIT: 'Bit',
  EXPLANATION_DEV: 'Explanation (for Developers)',
  LABEL: 'Label',
  PREPARE_DOWNLOAD: 'Preparing Download ...',
  CUBES: 'Cubes',
  PROCESS_TEXT_LONG: 'Process Text Long (deprecated)',
  PROCESS_TEXT_SHORT: 'Process Text Short (deprecated)',
  PRE_PROCESS_TEXT: 'Pre-Process Text(s)',
  PROCESS_TEXT: 'Process Text(s)',
  POST_PROCESS_TEXT: 'Post-Process Text(s)',
  COMPATIBILITY: 'Compatible from',
  DISPLAY_NAME: 'Display Name',
  DISPLAY: 'Display',
  IS_TRIGGER: 'Is a Trigger',
  IS_CONDITION: 'Is a Condition',
  IS_ACTION: 'Is an Action',
  HAS_ACTIONS: 'Has Actions',
  PREFIX: 'Prefix',
  SUFFIX: 'Suffix',
  COLOR: 'Color',
  NAME: 'Name',
  PRODUCT_TYPE: 'Product Type',
  PROTOCOLS: 'Protocols',
  PLACEHOLDERS: 'Placeholders',
  PRODUCTTYPES: 'Product Types',
  PRODUCTICONS: 'Product Icons',
  ATTRIBUTEVALUES: 'Attribute Values',
  UI_ELEMENTS: 'UI-Elements',
  SERVICES: 'Services',
  TRIGGER_OPERATORS: 'Trigger Operators',
  CONDITION_OPERATORS: 'Condition Operators',
  HOMEEGRAM_CLASSES: 'Homeegram Classes',
  PRODUCT_ICON: 'Product Icon',
  PRODUCT_ICONS: 'Product Icons',
  DEFAULT_PRODUCT_ICON: 'Default Product Icon',
  SELECTABLE_PRODUCT_ICONS: 'Selectable Product Icons',
  PRODUCT_IMAGE: 'Product Image',
  PRODUCT_MANUALS: 'Product Manuals',
  MISCELLANEOUS: 'Miscellaneous',
  ASIN: 'ASIN',
  USECASES: 'UseCases',
  ENOCEAN_EEP: 'EnOcean EEP',
  LOCATION_DETAIL: 'Detailed Location',
  LEARN_DEV: 'Learn-In',
  MANUAL_LINK: 'Manual Link',
  ADDITIONAL_INFO: 'Additional Info',
  NODE_JSON: 'Node JSON',
  INVALID_SEMVER: 'Invalid Version Number.',
  INVALID_JSON: 'Invalid JSON.',
  INVALID_GTIN: 'Invalid GTIN.',
  LEARN_MODE_PARAMETERS: 'Learn-Mode Parameters',
  USER_INTERACTION_PARAMETERS: 'User Interaction Parameters',
  OZW_CONFIG: 'OpenZWave Config',
  RESET_DEV: 'Reset',
  UNLEARN_DEV: 'Learn-Out',
  ZIGBEE_ADDITIONAL_INFO: 'ZigBee Additional Info',
  HOMEE_SHOP_HANDLE: 'homee Shop Handle',
  HOMEE_WORLD_HANDLE: 'homee World Handle',
  EAN_CODES: 'GTINs',
  ZIGBEE_DEVICE_ID: 'ZigBee DeviceID',
  ZIGBEE_PROFILE_ID: 'ZigBee ProfileID',
  ZWAVE_ALLIANCE_LINK: 'Z-Wave Alliance Link',
  AVATAR: 'Avatar',
  UNKNOWN: 'Unknown',
  LOAD_ERROR: 'Could not load document!',
  DELETE_ERROR: 'Could not delete document!',
  LOCK_ERROR: 'Could not (un)lock document!',
  SAVE_ERROR: 'Could not save document!',
  UPDATE_ALERT: 'A new version of homee Docs is available. Click to update!',
  COLLAPSE_ALL: 'Collapse all fields',
  EXPAND_ALL: 'Expand all fields',
  FIELD: 'Field',
  OPERATOR: 'Operator',
  ENUM_KEY: 'Enum Key',
  ENUM_VALUE: 'Enum Value',
  DESCRIPTION_DEV: 'Description (for Developers)',
  DESCRIPTION: 'Description',
  DECIMALS: 'Decimals',
  IS_ALARM_ATTRIBUTE: 'Is an Alarm Attribute',
  IS_CHARTS_ATTRIBUTE: 'Is a Charts Attribute',
  CHART_PERIODS: 'Chart Time Periods',
  ARTICLE: 'Article',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
  ATTRIBUTETYPE: 'Attribute Type',
  ATTRIBUTETYPE_LIST: 'Attribute Types',
  ATTRIBUTEVALUE: 'Attribute Value',
  ATTRIBUTEVALUE_LIST: 'Attribute Values',
  CONDITIONOPERATOR: 'Condition Operator',
  CONDITIONOPERATOR_LIST: 'Condition Operators',
  CUBE: 'Cube',
  CUBE_LIST: 'Cubes',
  HOMEEGRAMCLASS: 'Homeegram Class',
  HOMEEGRAMCLASS_LIST: 'Homeegram Classes',
  LOCALIZATION: 'Localization',
  LOCALIZATION_LIST: 'Localizations',
  PLACEHOLDER: 'Placeholder',
  PLACEHOLDER_LIST: 'Placeholders',
  PRODUCT: 'Product',
  PRODUCT_LIST: 'Products',
  PRODUCTICON: 'Product Icon',
  PRODUCTICON_LIST: 'Product Icons',
  PRODUCTTYPE: 'Product Type',
  PRODUCTTYPE_LIST: 'Product Types',
  PROFILE: 'Profile',
  PROFILE_LIST: 'Profiles',
  PROTOCOL_LIST: 'Protocols',
  SERVICE: 'Service',
  SERVICE_LIST: 'Services',
  TRIGGEROPERATOR: 'Trigger Operator',
  TRIGGEROPERATOR_LIST: 'Trigger Operators',
  UIELEMENT: 'UI Element',
  UIELEMENT_LIST: 'UI Elements',
  USECASE: 'Usecase',
  USECASE_LIST: 'Usecases',
  DOWNLOAD: 'Download',
  DOCUMENT_DELETED_ALERT: 'The Document has been deleted!',
  STRICT_SEARCH: 'Strict Search',
  FUZZY_SEARCH: 'Fuzzy Search',
  SYNTAX_SEARCH: 'Syntax Search',
  FILTER: 'Filter',
  ADD_FILTER: 'Add Filter',
  DELETE_FILTER: 'Delete Filter',
  DELETE_ALL_FILTER: 'Delete all',
  FILTER_LIST: 'Filter List',
  NO_FILTERS: 'No Filters',
  FILTER_ERROR: 'An error occurred while applying the filters!',
  HELPER_GTIN:
    'Global Trade Item Number. Also known under the former name EAN. The product can be found more quickly when learning-in in the app by scanning the code on the device.',
  HELPER_LEARN: 'A short description how to learn-in the device.',
  HELPER_ASIN:
    'Amazon Standard Identification Number. Used to create affiliate links.',
  HELPER_UNCATEGORIZED_ATTRIBUTES:
    'All attributes that are not included in any of the selected profiles.',
  HELPER_PRIMARY_ATTRIBUTES:
    'Formerly Main\n\nAll attributes that apps display directly in the device list.',
  HELPER_SECONDARY_ATTRIBUTES:
    'Formerly Other\n\nAll attributes that apps display under "Measurements" but not in the device list.',
  HELPER_TERTIARY_ATTRIBUTES:
    'Formerly Status + Configuration\n\nAll attributes that apps display under "Status" or "Configuration". The apps differentiate depending on whether an attribute is editable.',
  KEYBOARD_SHORTCUTS: 'Keyboard Shortcuts',
  KEYBOARD_SHORTCUTS_GLOBAL: 'Global',
  KEYBOARD_SHORTCUTS_DIALOG: 'Dialog',
  KEYBOARD_SHORTCUTS_LIST: 'List Screen',
  KEYBOARD_SHORTCUTS_DETAIL: 'Detail Screen',
  TOGGLE_HELP: 'Toggle this dialog',
  BLUR_FIELD: 'Leave field',
  JUMP_GENERAL: 'Jump to Tab General',
  JUMP_CORE: 'Jump to Tab Core',
  JUMP_APPS: 'Jump to Tab Apps',
  JUMP_WEBSITE: 'Jump to Tab Website',
  JUMP_MISCELLANEOUS: 'Jump to Tab Miscellaneous',
  JUMP_NUMBER: 'Jump to specific Tab',
  JUMP_RIGHT: 'Jump to next Tab',
  JUMP_LEFT: 'Jump to previous Tab',
  stringIdentifier: 'String Identifier',
  de: 'German',
  en: 'English',
  fr: 'French',
  it: 'Italian',
  es: 'Spanish',
  cs: 'Czech',
  nl: 'Dutch',
  nb: 'Norwegian',
  sv: 'Swedish',
  hu: 'Hungarian',
  sk: 'Slovak',
  ru: 'Russian',
  pl: 'Polish',
  da: 'Danish',
  AttributeType: 'Attribute Type',
  AttributeValue: 'Attribute Value',
  ConditionOperator: 'Condition Operator',
  Cube: 'Cube',
  HomeegramClass: 'Homeegram Class',
  Localization: 'Localization',
  Placeholder: 'Placeholder',
  Product: 'Product',
  ProductIcon: 'Product Icon',
  ProductType: 'Product Type',
  Profile: 'Profile',
  Protocol: 'Protocol',
  Service: 'Service',
  TriggerOperator: 'Trigger Operator',
  UiElement: 'UI Element',
  UseCase: 'Usecase',
  eq: '=',
  ne: '!=',
  exists: 'exists',
  notExists: 'does not exists',
  contains: 'contains',
  notContains: 'does not contain',
  le: '<=',
  lt: '<',
  ge: '>=',
  gt: '>',
  between: 'between',
  in: 'is any of',
  all: 'contains all of',
  any: 'contains any of',
  none: 'contains none of'
} as const;

export const de: Record<string, string> = {
  [Translations.BACK_TO_SIGN_IN]: 'Zurück zur Anmeldung',
  [Translations.CODE_LABEL]: 'Verifizierungscode *',
  [Translations.CODE_PLACEHOLDER]: 'Verifizierungscode eingeben',
  [Translations.CODE_SENT]: 'Verifizierungscode wurde versendet.',
  [Translations.CONFIRM_USER_HEADER]: 'Konto bestätigen',
  [Translations.LOST_CODE]: 'Verifizierungscode verloren?',
  [Translations.RESEND_CODE]: 'Erneut senden',
  [Translations.CONFIRM_BUTTON]: 'Bestätigen',
  [Translations.REGISTER]: 'Registrieren',
  [Translations.DOMAIN_NOT_ALLOWED]: 'Domain ist nicht erlaubt.',
  [Translations.EMAIL_LABEL]: 'E-Mail Adresse *',
  [Translations.EMAIL_PLACEHOLDER]: 'E-Mail Adresse eingeben',
  [Translations.FORGOT_PASSWORD]: 'Passwort vergessen?',
  [Translations.INVALID_MAIL_ADDRESS]: 'Ungültige E-Mail Adresse.',
  [Translations.NAME_LABEL]: 'Name *',
  [Translations.NAME_PLACEHOLDER]: 'Vollständigen Namen eingeben',
  [Translations.NEW_PASSWORD_LABEL]: 'Neues Passwort *',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Neues Passwort eingeben',
  [Translations.NO_ACCOUNT]: 'Noch kein Konto?',
  [Translations.ONLY_NUMBERS]: 'Darf nur Nummern enthalten.',
  [Translations.PASSWORD_LABEL]: 'Passwort *',
  [Translations.PASSWORD_PLACEHOLDER]: 'Passwort eingeben',
  [Translations.PASSWORD_A_PLACEHOLDER]: 'Ein Passwort eingeben',
  [Translations.TOO_SHORT]: 'Zu kurz.',
  [Translations.TOO_LONG]: 'Zu lang.',
  [Translations.REQUIRE_LOWERCASE]: 'Muss Kleinbuchstaben enthalten.',
  [Translations.REQUIRE_NUMBERS]: 'Muss Nummern enthalten.',
  [Translations.REQUIRE_SYMBOLS]: 'Muss Symbole enthalten.',
  [Translations.REQUIRE_UPPERCASE]: 'Muss Großbuchstaben enthalten.',
  [Translations.REQUIRED]: 'Pflichtfeld.',
  [Translations.RESET_PASSWORD]: 'Zurücksetzen',
  [Translations.RESET_PASSWORD_HEADER]: 'Passwort Zurücksetzen',
  [Translations.SEND_CODE]: 'Verifizierungscode anfordern',
  [Translations.LOGIN_BUTTON]: 'Anmelden',
  [Translations.LOGIN_HEADER]: 'Anmelden',
  [Translations.LOGIN]: 'Anmelden',
  [Translations.HAVE_ACCOUNT]: 'Bereits registriert?',
  [Translations.REGISTER_HEADER]: 'Registrieren',
  [Translations.REGISTER_BUTTON]: 'Registrieren',
  [Translations.LOGOUT]: 'Abmelden',

  [Translations.PREPARE_DATABASE]: 'Datenbank wird synchronisiert ...',
  [Translations.PREPARE_DATABASE_WARNING]:
    'Bitte die Seite NICHT schließen oder neu laden!',
  [Translations.EDIT_PROFILE]: 'Profil bearbeiten',
  [Translations.EMAIL]: 'E-Mail',
  [Translations.SAVE]: 'Speichern',
  [Translations.COULD_NOT_SAVE_PROFILE]:
    'Profil konnte nicht gespeichert werden!',
  [Translations.ABORT]: 'Abbrechen',
  [Translations.DELETE]: 'Löschen',
  [Translations.EDIT]: 'Bearbeiten',
  [Translations.SEARCH_IN_PRODUCTS]: 'In Produkten suchen',
  [Translations.SEARCH_IN_PROFILES]: 'In Profilen suchen',
  [Translations.SEARCH_PRIMARY_IN_PROFILES]: 'In Profilen suchen (primär)',
  [Translations.SEARCH_SECONDARY_IN_PROFILES]: 'In Profilen suchen (sekundär)',
  [Translations.SEARCH_TERTIARY_IN_PROFILES]: 'In Profilen suchen (tertiär)',
  [Translations.SEARCH_IN_HOMEEGRAM_CLASSES]: 'In Homeegrammklassen suchen',
  [Translations.SEARCH_IN_PROTOCOLS]: 'In Protokollen suchen',
  [Translations.SEARCH_IN_ATTRIBUTE_TYPES]: 'In Attributtypen suchen',
  [Translations.SEARCH_IN_PRODUCT_TYPES]: 'In Produkttypen suchen',
  [Translations.SEARCH_IN_LOCALIZATIONS]: 'In Lokalisierungen suchen',
  [Translations.CLOSE]: 'Schließen',
  [Translations.MORE_PRODUCT]: 'Zu Produkten',
  [Translations.MORE_ATTRIBUTE]: 'Zu Attributen',
  [Translations.MORE_HOMEEGRAM]: 'Zu Homeegrammen',
  [Translations.MORE_LOCALIZATION]: 'Zu Lokalisierung',
  [Translations.UNKNOWN_DOCTYPE]: 'Unbekannter Dokumenttyp!',
  [Translations.DISCARD]: 'Verwerfen',
  [Translations.DISCARD_CHANGES]: 'Änderungen verwerfen',
  [Translations.CONFIRM_DISCARD_CHANGES]:
    'Das Dokument enthält ungespeicherte Änderungen. Sollen diese Änderungen verworfen werden?',
  [Translations.DELETE_DOCUMENT]: 'Dokument löschen',
  [Translations.CONFIRM_DELETE_DOCUMENT]:
    'Sicher, dass das Dokument gelöscht werden soll?',
  [Translations.DELETE_DOCUMENTS]: 'Dokumente löschen',
  [Translations.CONFIRM_DELETE_DOCUMENTS]:
    'Sicher, dass die Dokumente gelöscht werden sollen?',
  [Translations.DELETE_DOCUMENTS_ERROR]:
    'Es konnten nicht alle Dokumente gelöscht werden!',
  [Translations.MALFORMED_ATTACHMENT]: 'Anhangsrichtlinien',
  [Translations.CONFIRM_MALFORMED_ATTACHMENT]:
    'Anhang entspricht nicht den Richtlinien.\nSicher, dass das Dokument gespeichert werden soll?',
  [Translations.CURRENTLY_LOCKED]: 'Momentan gesperrt!',
  [Translations.CLONE]: 'Dokument klonen',
  [Translations.UNIQUE]: 'Wert muss einzigartig sein.',
  [Translations.WRONG_RESOLUTION]: 'Auflösung zu niedrig.',
  [Translations.WRONG_FILETYPE]: 'Falsches Dateiformat.',
  [Translations.WRONG_FILESIZE]: 'Zu groß.',
  [Translations.REPLACE_PLACEHOLDERS]: 'Enthält produktspezifische Begriffe.',
  [Translations.COPIED_TO_CLIPBOARD]:
    'Schlüssel wurde in die Zwischenablage kopiert.',
  [Translations.COPY_TO_CLIPBOARD_FAILED]:
    'Schlüssel konnte nicht in die Zwischenablage kopiert werden.',
  [Translations.WARN_UNCATEGORIZED]:
    'Nicht alle ausgewählten Attribute sind in diesen Profilen enthalten!',
  [Translations.ORIGINAL_FILE]: 'Originaldatei',
  [Translations.LANGUAGE]: 'Sprache',
  [Translations.GERMAN]: 'Deutsch',
  [Translations.ENGLISH]: 'Englisch',
  [Translations.PRODUCTS]: 'Produkte',
  [Translations.FILE_UPLOAD]: 'Dateiauswahl',
  [Translations.FILE_UPLOAD_DROPZONE]: 'Per Drag&Drop oder Klicken hinzufügen.',
  [Translations.FILE_UPLOAD_DROPZONE_PDF_MULTI]:
    'PDF Dateien mit reduzierter Größe (<5MB) verwenden!',
  [Translations.FILE_UPLOAD_DROPZONE_PDF_SINGLE]:
    'PDF Datei mit reduzierter Größe (<5MB) verwenden!',
  [Translations.FILE_UPLOAD_DROPZONE_XLSX_MULTI]: 'XLSX Dateien verwenden!',
  [Translations.FILE_UPLOAD_DROPZONE_XLSX_SINGLE]: 'XLSX Datei verwenden!',
  [Translations.FILE_UPLOAD_DROPZONE_TIFF_MULTI]:
    'TIFF Dateien mit hoher Auflösung (>12MP) und transparentem Hintergrund verwenden!',
  [Translations.FILE_UPLOAD_DROPZONE_TIFF_SINGLE]:
    'TIFF Datei mit hoher Auflösung (>12MP) und transparentem Hintergrund verwenden!',
  [Translations.FILE_UPLOAD_DROPZONE_SVG_MULTI]:
    'SVG Dateien mit transparentem Hintergrund verwenden!',
  [Translations.FILE_UPLOAD_DROPZONE_SVG_SINGLE]:
    'SVG Datei mit transparentem Hintergrund verwenden!',
  [Translations.UPLOAD]: 'Upload',
  [Translations.CREATE_NEW_KEYS]: 'Neue Keys anlegen',
  [Translations.SHEET_SELECTION]: 'Blattauswahl',
  [Translations.COLUMN_SELECTION]: 'Spaltenauswahl',
  [Translations.SHEET]: 'Tabellenblatt',
  [Translations.HAS_HEADLINE]: 'Tabelle hat Kopfzeile',
  [Translations.GALLERY]: 'Galerie',
  [Translations.LOCALIZATION_IMPORT]: 'Lokalisierung Massenimport',
  [Translations.IMPORT_LANGUAGE]: 'Importieren',
  [Translations.IMPORT_NO_CHANGE]:
    'Alle Übersetzungen sind auf dem neusten Stand. Es wurden kein Dokument verändert.',
  [Translations.IMPORT_DONE]: 'Import ist abgeschlossen',
  [Translations.IMPORT_SKIP]: 'Keine Änderung',
  [Translations.IMPORT_OK]: 'Import ist für die folgenden Keys erfolgreich:',
  [Translations.IMPORT_ERROR]:
    'Import ist für die folgenden Keys fehlgeschlagen:',
  [Translations.COULD_NOT_LOAD_XLSX]: 'XLSX konnte nicht geladen werden!',
  [Translations.COLUMN]: 'Spalte',
  [Translations.IMPORT_OVERWRITE]: 'Überschreiben',
  [Translations.REQUIRED_FIELD]: 'Kennzeichnet Pflichtfelder',
  [Translations.ATTRIBUTETYPES]: 'Attributtypen',
  [Translations.ATTRIBUTETYPES_UNCATEGORIZED]: 'Nicht zugeordnet Attributtypen',
  [Translations.ATTRIBUTETYPES_PRIMARY]: 'Primäre Attributtypen',
  [Translations.ATTRIBUTETYPES_SECONDARY]: 'Sekundäre Attributtypen',
  [Translations.ATTRIBUTETYPES_TERTIARY]: 'Tertiäre Attributtypen',
  [Translations.VALUE]: 'Wert',
  [Translations.VALUES]: 'Werte',
  [Translations.MEANING]: 'Bedeutung',
  [Translations.COMMAND]: 'Befehl',
  [Translations.TRIGGER_DESCRIPTION]: 'Auslöserbeschreibung',
  [Translations.TRIGGER_BRICK]: 'Auslösersatzbaustein',
  [Translations.CONDITION_DESCRIPTION]: 'Bedingungsbeschreibung',
  [Translations.CONDITION_BRICK]: 'Bedingungssatzbaustein',
  [Translations.CONDITION_DESCRIPTION_NOT]:
    'Gegenteiliger Bedingungsbeschreibung',
  [Translations.CONDITION_BRICK_NOT]: 'Gegenteiliger Bedingungssatzbaustein',
  [Translations.ACTION_DESCRIPTION]: 'Aktionsbeschreibung',
  [Translations.ACTION_BRICK]: 'Aktionssatzbaustein',
  [Translations.STRING_IDENTIFIER]: 'String Identifier',
  [Translations.NOTE]: 'Notiz',
  [Translations.PROFILES]: 'Profile',
  [Translations.SEARCH]: 'Suchen',
  [Translations.ADD]: 'Hinzufügen',
  [Translations.GENERAL]: 'Allgemein',
  [Translations.CORE]: 'Core',
  [Translations.EXCEL]: 'Excel',
  [Translations.JSON]: 'JSON',
  [Translations.ALL]: 'Alle',
  [Translations.APPS]: 'Apps',
  [Translations.WEBSITE]: 'Website',
  [Translations.PRODUCT_NAME]: 'Produktname',
  [Translations.MANUFACTURER]: 'Hersteller',
  [Translations.INTEGRATION_STATE]: 'Intergationsstatus',
  [Translations.ANDROID]: 'Android',
  [Translations.IOS]: 'iOS',
  [Translations.WEB]: 'Web',
  [Translations.NOTIFICATION_SERVER]: 'Notification Server',
  [Translations.TODO]: 'ToDo',
  [Translations.IN_DEVELOPMENT]: 'In Entwicklung',
  [Translations.DONE]: 'Abgeschlossen',
  [Translations.EXPORT_APPS]: 'Export Apps',
  [Translations.EXPORT_WEBSITE]: 'Export Website',
  [Translations.EXPORT_STATE]: 'Exportstatus',
  [Translations.YES]: 'Ja',
  [Translations.NO]: 'Nein',
  [Translations.COPY]: 'Kopieren',
  [Translations.NO_EXPORT]: 'Kein Export',
  [Translations.INVISIBLE_EXPORT]: 'Export, nur sichtbar mit Würfel',
  [Translations.VISIBLE_EXPORT]: 'Export, immer sichtbar',
  [Translations.PRIVATE]: 'Privat',
  [Translations.STAGING]: 'Staging',
  [Translations.PUBLIC]: 'Öffentlich',
  [Translations.PRODUCT_LOCATION]: 'Produktstandort',
  [Translations.OFFICE]: 'Büro',
  [Translations.TEAM]: 'Team',
  [Translations.PARTNER]: 'Partnerfirma',
  [Translations.CUSTOMER]: 'Kunde',
  [Translations.MISSING]: 'Fehlt',
  [Translations.MISSING_LOWER]: 'fehlt',
  [Translations.BIT]: 'Bit',
  [Translations.EXPLANATION_DEV]: 'Erklärung (für Entwickler)',
  [Translations.LABEL]: 'Bezeichnung',
  [Translations.PREPARE_DOWNLOAD]: 'Der Download wird vorbereitet ...',
  [Translations.CUBES]: 'Würfel',
  [Translations.PROCESS_TEXT_LONG]: 'Prozesstext lang (deprecated)',
  [Translations.PROCESS_TEXT_SHORT]: 'Prozesstext kurz (deprecated)',
  [Translations.PRE_PROCESS_TEXT]: 'Pre-Prozesstext(e)',
  [Translations.PROCESS_TEXT]: 'Prozesstext(e)',
  [Translations.POST_PROCESS_TEXT]: 'Post-Prozesstext(e)',
  [Translations.COMPATIBILITY]: 'Kompatibel ab',
  [Translations.DISPLAY_NAME]: 'Anzeigename',
  [Translations.DISPLAY]: 'Darstellung',
  [Translations.IS_TRIGGER]: 'Ist ein Auslöser',
  [Translations.IS_CONDITION]: 'Ist eine Bedingung',
  [Translations.IS_ACTION]: 'Ist eine Aktion',
  [Translations.HAS_ACTIONS]: 'Hat Aktionen',
  [Translations.PREFIX]: 'Prefix',
  [Translations.SUFFIX]: 'Suffix',
  [Translations.COLOR]: 'Farbe',
  [Translations.NAME]: 'Name',
  [Translations.PRODUCT_TYPE]: 'Produkttyp',
  [Translations.PROTOCOLS]: 'Protokolle',
  [Translations.PLACEHOLDERS]: 'Platzhalter',
  [Translations.PRODUCTTYPES]: 'Produkttypen',
  [Translations.PRODUCTICONS]: 'Produkticons',
  [Translations.ATTRIBUTEVALUES]: 'Attributwerte',
  [Translations.UI_ELEMENTS]: 'UI-Elemente',
  [Translations.SERVICES]: 'Dienste',
  [Translations.TRIGGER_OPERATORS]: 'Auslöseoperatoren',
  [Translations.CONDITION_OPERATORS]: 'Bedingungsoperatoren',
  [Translations.HOMEEGRAM_CLASSES]: 'Homeegrammklassen',
  [Translations.PRODUCT_ICON]: 'Produkticon',
  [Translations.PRODUCT_ICONS]: 'Produkticons',
  [Translations.DEFAULT_PRODUCT_ICON]: 'Standardprodukticon',
  [Translations.SELECTABLE_PRODUCT_ICONS]: 'Auswählbare Produkticons',
  [Translations.PRODUCT_IMAGE]: 'Produktbild',
  [Translations.PRODUCT_MANUALS]: 'Produkthandbücher',
  [Translations.MISCELLANEOUS]: 'Verschiedenes',
  [Translations.ASIN]: 'ASIN',
  [Translations.USECASES]: 'Anwendungen',
  [Translations.ENOCEAN_EEP]: 'EnOcean EEP',
  [Translations.LOCATION_DETAIL]: 'Genauer Standort',
  [Translations.LEARN_DEV]: 'Einlernen',
  [Translations.MANUAL_LINK]: 'Anleitung Link',
  [Translations.ADDITIONAL_INFO]: 'Zusatzinformationen',
  [Translations.NODE_JSON]: 'Node JSON',
  [Translations.INVALID_SEMVER]: 'Invalide Versionsnummer.',
  [Translations.INVALID_JSON]: 'Invalider JSON.',
  [Translations.INVALID_GTIN]: 'Invalide GTIN.',
  [Translations.LEARN_MODE_PARAMETERS]: 'Learn-Mode Parameter',
  [Translations.USER_INTERACTION_PARAMETERS]: 'User Interaction Parameter',
  [Translations.OZW_CONFIG]: 'OpenZWave Konfig',
  [Translations.RESET_DEV]: 'Zurücksetzen',
  [Translations.UNLEARN_DEV]: 'Auslernen',
  [Translations.ZIGBEE_ADDITIONAL_INFO]: 'Zigbee Zusatzinfo',
  [Translations.HOMEE_SHOP_HANDLE]: 'homee Shop Handle',
  [Translations.HOMEE_WORLD_HANDLE]: 'homee Welt Handle',
  [Translations.EAN_CODES]: 'GTINs',
  [Translations.ZIGBEE_DEVICE_ID]: 'ZigBee Device ID',
  [Translations.ZIGBEE_PROFILE_ID]: 'ZigBee Profile ID',
  [Translations.ZWAVE_ALLIANCE_LINK]: 'Z-Wave Alliance Link',
  [Translations.AVATAR]: 'Avatar',
  [Translations.UNKNOWN]: 'Unbekannt',
  [Translations.LOAD_ERROR]: 'Dokument konnte nicht geladen werden!',
  [Translations.DELETE_ERROR]: 'Dokument konnte nicht gelöscht werden!',
  [Translations.LOCK_ERROR]: 'Dokument konnte nicht (ent)gesperrt werden!',
  [Translations.SAVE_ERROR]: 'Dokument konnte nicht gespeichert werden!',
  [Translations.UPDATE_ALERT]:
    'Eine neue Version der homee Docs sind verfügbar. Klicken zum Aktualisieren!',
  [Translations.COLLAPSE_ALL]: 'Alle Felder einklappen',
  [Translations.EXPAND_ALL]: 'Alle Felder ausklappen',
  [Translations.FIELD]: 'Feld',
  [Translations.OPERATOR]: 'Operator',
  [Translations.ENUM_KEY]: 'Enum Key',
  [Translations.ENUM_VALUE]: 'Enum Wert',
  [Translations.DESCRIPTION_DEV]: 'Beschreibung (für Entwickler)',
  [Translations.DESCRIPTION]: 'Beschreibung',
  [Translations.DECIMALS]: 'Dezimalstellen',
  [Translations.IS_ALARM_ATTRIBUTE]: 'Ist ein Alarmattribut',
  [Translations.IS_CHARTS_ATTRIBUTE]: 'Ist ein Chartsattribut',
  [Translations.CHART_PERIODS]: 'Diagrammzeiträume',
  [Translations.ARTICLE]: 'Artikel',
  [Translations.DAY]: 'Tag',
  [Translations.WEEK]: 'Woche',
  [Translations.MONTH]: 'Monat',
  [Translations.YEAR]: 'Jahr',
  [Translations.ATTRIBUTETYPE]: 'Attributtyp',
  [Translations.ATTRIBUTETYPE_LIST]: 'Attributtypen',
  [Translations.ATTRIBUTEVALUE]: 'Attributwert',
  [Translations.ATTRIBUTEVALUE_LIST]: 'Attributwerte',
  [Translations.CONDITIONOPERATOR]: 'Bedingugsoperator',
  [Translations.CONDITIONOPERATOR_LIST]: 'Bedingugsoperatoren',
  [Translations.CUBE]: 'Würfel',
  [Translations.CUBE_LIST]: 'Würfel',
  [Translations.HOMEEGRAMCLASS]: 'Homeegrammklasse',
  [Translations.HOMEEGRAMCLASS_LIST]: 'Homeegrammklassen',
  [Translations.LOCALIZATION]: 'Lokalisierung',
  [Translations.LOCALIZATION_LIST]: 'Lokalisierungen',
  [Translations.PLACEHOLDER]: 'Platzhalter',
  [Translations.PLACEHOLDER_LIST]: 'Platzhalter',
  [Translations.PRODUCT]: 'Produkt',
  [Translations.PRODUCT_LIST]: 'Produkte',
  [Translations.PRODUCTICON]: 'Produkticon',
  [Translations.PRODUCTICON_LIST]: 'Produkticons',
  [Translations.PRODUCTTYPE]: 'Produkttyp',
  [Translations.PRODUCTTYPE_LIST]: 'Produkttypen',
  [Translations.PROFILE]: 'Profil',
  [Translations.PROFILE_LIST]: 'Profile',
  [Translations.PROTOCOL_LIST]: 'Protokolle',
  [Translations.SERVICE]: 'Dienst',
  [Translations.SERVICE_LIST]: 'Dienste',
  [Translations.TRIGGEROPERATOR]: 'Auslöseoperator',
  [Translations.TRIGGEROPERATOR_LIST]: 'Auslöseoperatoren',
  [Translations.UIELEMENT]: 'UI-Element',
  [Translations.UIELEMENT_LIST]: 'UI-Elemente',
  [Translations.USECASE]: 'Anwendung',
  [Translations.USECASE_LIST]: 'Anwendungen',
  [Translations.DOWNLOAD]: 'Download',
  [Translations.DOCUMENT_DELETED_ALERT]: 'Das Dokument wurde gelöscht!',
  [Translations.STRICT_SEARCH]: 'Strikte Suche',
  [Translations.FUZZY_SEARCH]: 'Unscharfe Suche',
  [Translations.SYNTAX_SEARCH]: 'Syntax Suche',
  [Translations.FILTER]: 'Filtern',
  [Translations.ADD_FILTER]: 'Filter hinzufügen',
  [Translations.DELETE_FILTER]: 'Filter löschen',
  [Translations.DELETE_ALL_FILTER]: 'Alle löschen',
  [Translations.FILTER_LIST]: 'Liste filtern',
  [Translations.NO_FILTERS]: 'Keine Filter',
  [Translations.FILTER_ERROR]:
    'Beim Anwenden der Filter ist ein Fehler aufgetreten!',
  [Translations.HELPER_GTIN]:
    'Global Trade Item Number\n\nAuch unter der früheren Bezeichnung EAN bekannt. Das Produkt kann beim Einlernen in der App durch Einscannen des Codes am Gerät schneller gefunden werden.',
  [Translations.HELPER_LEARN]:
    'Kurze Beschreibung, wie man das Gerät einlernen muss.',
  [Translations.HELPER_ASIN]:
    'Amazon Standard Identification Number\n\nWird für die Erstellung von Affiliate Links verwendet.',
  [Translations.HELPER_UNCATEGORIZED_ATTRIBUTES]:
    'Alle Attribute, die in keinem der ausgewählten Profile enthalten sind.',
  [Translations.HELPER_PRIMARY_ATTRIBUTES]:
    'Ehemals Hauptmesswerte\n\nAlle Attribute, die in den Apps direkt in der Geräteliste angezeigt werden.',
  [Translations.HELPER_SECONDARY_ATTRIBUTES]:
    'Ehemals weitere Messwerte\n\nAlle Attribute, die unter "Messwerte" angezeigt werden, aber nicht direkt in der Geräteliste.',
  [Translations.HELPER_TERTIARY_ATTRIBUTES]:
    'Ehemals Status + Konfiguration\n\nAlle Attribute, die unter "Status" oder "Konfiguration" angezeigt werden. Die Apps unterscheiden jenachdem, ob ein Attribut bearbeitbar ist.',
  [Translations.KEYBOARD_SHORTCUTS]: 'Tastenkombinationen',
  [Translations.KEYBOARD_SHORTCUTS_GLOBAL]: 'Global',
  [Translations.KEYBOARD_SHORTCUTS_DIALOG]: 'Dialog',
  [Translations.KEYBOARD_SHORTCUTS_LIST]: 'Listen Ansicht',
  [Translations.KEYBOARD_SHORTCUTS_DETAIL]: 'Detail Ansicht',
  [Translations.TOGGLE_HELP]: 'Diesen Dialog ein-/ausblenden',
  [Translations.BLUR_FIELD]: 'Feld verlassen',
  [Translations.JUMP_GENERAL]: 'Zum Tab Allgemein springen',
  [Translations.JUMP_CORE]: 'Zum Tab Core springen',
  [Translations.JUMP_APPS]: 'Zum Tab Apps springen',
  [Translations.JUMP_WEBSITE]: 'Zum Tab Website springen',
  [Translations.JUMP_MISCELLANEOUS]: 'Zum Tab Verschiedenes springen',
  [Translations.JUMP_NUMBER]: 'Zu spezifischem Tab springen',
  [Translations.JUMP_RIGHT]: 'Zum nächsten Tab springen',
  [Translations.JUMP_LEFT]: 'Zum vorherigen Tab springen',
  [Translations.stringIdentifier]: 'String Identifier',
  [Translations.de]: 'Deutsch',
  [Translations.en]: 'Englisch',
  [Translations.fr]: 'Französisch',
  [Translations.it]: 'Italienisch',
  [Translations.es]: 'Spanisch',
  [Translations.cs]: 'Tschechisch',
  [Translations.nl]: 'Niederländisch',
  [Translations.nb]: 'Norwegisch',
  [Translations.sv]: 'Schwedisch',
  [Translations.hu]: 'Ungarisch',
  [Translations.sk]: 'Slowakisch',
  [Translations.ru]: 'Russisch',
  [Translations.pl]: 'Polnisch',
  [Translations.da]: 'Dänisch',
  [Translations.AttributeType]: 'Attributtyp',
  [Translations.AttributeValue]: 'Attributwert',
  [Translations.ConditionOperator]: 'Bedingugsoperator',
  [Translations.Cube]: 'Würfel',
  [Translations.HomeegramClass]: 'Homeegrammklasse',
  [Translations.Localization]: 'Lokalisierung',
  [Translations.Placeholder]: 'Platzhalter',
  [Translations.Product]: 'Produkt',
  [Translations.ProductIcon]: 'Produkticon',
  [Translations.ProductType]: 'Produkttyp',
  [Translations.Profile]: 'Profil',
  [Translations.Protocol]: 'Protokoll',
  [Translations.Service]: 'Dienst',
  [Translations.TriggerOperator]: 'Auslöseoperator',
  [Translations.UiElement]: 'UI-Element',
  [Translations.UseCase]: 'Anwendung',
  [Translations.eq]: '=',
  [Translations.ne]: '!=',
  [Translations.exists]: 'existiert',
  [Translations.notExists]: 'existiert nicht',
  [Translations.contains]: 'enthält',
  [Translations.notContains]: 'enthält nicht',
  [Translations.le]: '<=',
  [Translations.lt]: '<',
  [Translations.ge]: '>=',
  [Translations.gt]: '>',
  [Translations.between]: 'zwischen',
  [Translations.in]: 'ist eines aus',
  [Translations.all]: 'enthält alle aus',
  [Translations.any]: 'enthält eines aus',
  [Translations.none]: 'enthält keines aus'
} as const;

export default function VueI18nPlugin(Vue: typeof _Vue): void {
  I18n.putVocabularies({
    de
  });

  Vue.prototype.$Translations = Translations;
  Vue.prototype.$I18n = I18n;
}

declare module 'vue/types/vue' {
  interface Vue {
    $Translations: typeof Translations;
    $I18n: I18n;
  }
}
