import { render, staticRenderFns } from "./ObjectEditorComponent.vue?vue&type=template&id=26f2f4b9&scoped=true&"
import script from "./ObjectEditorComponent.vue?vue&type=script&lang=ts&"
export * from "./ObjectEditorComponent.vue?vue&type=script&lang=ts&"
import style0 from "./ObjectEditorComponent.vue?vue&type=style&index=0&id=26f2f4b9&lang=scss&scoped=true&"
import style1 from "./ObjectEditorComponent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f2f4b9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VMessages } from 'vuetify/lib/components/VMessages';
installComponents(component, {VLabel,VMessages})
