import { Document, DocTypes, DocsFilter } from './document';
import type { Localization } from './localization';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import { FieldTypes, DetailData } from '../typings/field';
import { persistentDocumentsStore, userStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface Cube extends Document {
  _docType: DocTypes.Cube;
  name: string;
  number: number;
  displayName: Localization | null;
  color: string | null;
}

export const defaultListProperties: string = `
  name
  number
  displayName {
    _id
  }
  color
`;

export const defaultDetailProperties: string = `
  name
  number
  displayName {
    _id
  }
  color
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.COLOR),
      value: 'color',
      align: 'start',
      sortable: false
    },
    {
      text: I18n.get(Translations.ENUM_KEY),
      value: 'name',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.ENUM_VALUE),
      value: 'number',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.DISPLAY_NAME),
      value: 'displayName',
      align: 'start',
      sortable: true
    },
    {
      text: '',
      value: 'displayNameKey',
      hidden: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: Cube[] = Object.values(persistentDocumentsStore.Cube);
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (document: Cube): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      color: document.color,
      name: document.name,
      number: document.number ?? '',
      displayName:
        persistentDocumentsStore.Localization[
          document.displayName?._id ?? ''
        ]?.[userStore.locale],
      displayNameKey:
        persistentDocumentsStore.Localization[document.displayName?._id ?? '']
          ?.stringIdentifier
    })
  );
}

export const sortListBy: string | string[] = 'number';

export async function getCategories(
  document: Partial<Cube>
): Promise<DetailData> {
  return {
    title: document.name,
    subtitle: document.number?.toString(),
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.ENUM_KEY),
            required: true,
            model: 'name',
            value: document.name,
            prefix: 'CACubeType'
          },
          {
            type: FieldTypes.NUMBER,
            label: I18n.get(Translations.ENUM_VALUE),
            required: true,
            model: 'number',
            value: document.number
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.DISPLAY_NAME),
            required: false,
            model: 'displayName',
            value: document.displayName,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter((loc: Localization): boolean =>
              loc.stringIdentifier.toUpperCase().startsWith('GENERAL_CUBE_')
            ),
            multiple: false
          },
          {
            type: FieldTypes.COLOR,
            label: I18n.get(Translations.COLOR),
            required: false,
            model: 'color',
            value: document.color
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.ENUM_KEY),
      property: 'name'
    },
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'le',
        'lt',
        'ge',
        'gt',
        'between',
        'in'
      ],
      label: I18n.get(Translations.ENUM_VALUE),
      validator: (data: string): boolean => !isNaN(parseInt(data)),
      property: 'number'
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.DISPLAY_NAME),
      property: 'displayName',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(
        persistentDocumentsStore.Localization
      ).filter((loc: Localization): boolean =>
        loc.stringIdentifier.toUpperCase().startsWith('GENERAL_CUBE_')
      )
    },
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.COLOR),
      property: 'color'
    }
  ];
}
