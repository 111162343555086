// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace homee {
  export enum CANodeProtocol {
    ZWave = 1,
    ZigBee = 2,
    EnOcean = 3,
    HTTPAVM = 8,
    HTTPNetatmo = 9,
    HTTPCCU2 = 13,
    HTTPUPnP = 14,
    HTTPNuki = 15,
    HTTPSEMS = 16,
    SigmaZWave = 17,
    BiSecur = 19,
    HTTPWolf = 20,
    ExternalHomee = 21,
    CentronicPlus = 22,
    WMS = 23,
    HTTPMyStrom = 24,
    HTTPSteca = 25,
    ApeLabs = 26
  }
}
