






































import { mdiFilter, mdiFilterOutline } from '@mdi/js';
import { Vue, Component, Prop } from 'vue-property-decorator';
import type { RawLocation } from 'vue-router';

@Component
export default class FilterButtonComponent extends Vue {
  private readonly filterIconSvg: string = mdiFilter;
  private readonly filterIconOutlineSvg: string = mdiFilterOutline;

  @Prop({ type: String, default: '' })
  private readonly icon!: string;

  @Prop({ type: String, default: '' })
  private readonly text!: string;

  @Prop({ type: [String, Object], default: '' })
  private readonly to!: RawLocation;
}
