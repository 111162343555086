import { render, staticRenderFns } from "./ImageEditorComponent.vue?vue&type=template&id=12270229&scoped=true&"
import script from "./ImageEditorComponent.vue?vue&type=script&lang=ts&"
export * from "./ImageEditorComponent.vue?vue&type=script&lang=ts&"
import style0 from "./ImageEditorComponent.vue?vue&type=style&index=0&id=12270229&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12270229",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VMessages } from 'vuetify/lib/components/VMessages';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VFadeTransition,VHover,VIcon,VImg,VLabel,VMessages,VOverlay,VRow})
