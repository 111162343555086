import Vue from 'vue';
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  MutationAction
} from 'vuex-module-decorators';
import type { Document } from '../../api/document';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VolatileDocumentsState {
  loading: boolean;
  documents: Record<string, Document>;
}

export const namespace: 'volatileDocuments' = 'volatileDocuments' as const;

@Module({
  name: namespace,
  namespaced: true
})
export default class VolatileDocumentsModule
  extends VuexModule
  implements VolatileDocumentsState {
  public loading: boolean = false;
  public documents: Record<string, Document> = {};

  @Mutation
  private _setDocument(doc: Document): void {
    Vue.set(this.documents, doc._id, doc);
  }

  @Action({ commit: '_setDocument' })
  public setDocument(doc: Document): Document {
    return doc;
  }

  @Action({ commit: '_setDocument' })
  public updateDocument(doc: Partial<Document> & { _id: string }): Document {
    if (!doc._id) {
      throw 'Missing Id!';
    }
    return {
      ...this.documents[doc._id],
      ...doc
    };
  }

  @Mutation
  private _deleteDocument(doc: Document): void {
    Vue.delete(this.documents, doc._id);
  }

  @Action({ commit: '_deleteDocument' })
  public deleteDocument(doc: Document): Document {
    return doc;
  }

  @MutationAction({ mutate: ['documents'] })
  public async clearStore(): Promise<{ documents: Record<string, Document> }> {
    return { documents: {} };
  }

  @MutationAction({ mutate: ['loading'] })
  public async setLoading(loading: boolean): Promise<{ loading: boolean }> {
    return { loading };
  }
}
