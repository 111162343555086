




















































import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator';
import JSONEditor from 'jsoneditor';
import type { JsonObject } from 'type-fest';
import { userStore } from '../plugins/store';
import { Locale, de, Translations } from '../plugins/i18n';
import '../styles/ace-theme';
import type { ValidationRule, ValidationRules } from '../typings/field';
import _ from 'lodash';

const languages: Record<Locale.de, Record<string, string>> = {
  [Locale.de]: {
    array: 'Array',
    auto: 'Auto',
    appendText: 'Hinzufügen',
    appendTitle:
      'Fügt ein neues Feld vom Typ "Auto" nach diesem Feld hinzu (Strg+Umschalt+Einfg)',
    appendSubmenuTitle: 'Typ des hinzuzufügenden Feldes auswählen',
    appendTitleAuto:
      'Fügt ein neues Feld vom Typ "Auto" hinzu (Strg+Umschalt+Einfg)',
    ascending: 'Aufsteigend',
    ascendingTitle:
      'Sortiert die Kindelemente dieses ${type}s in aufsteigender Reihenfolge',
    actionsMenu: 'Zum Öffnen des Aktionsmenüs klicken (Strg+M)',
    cannotParseFieldError: 'Feld kann nicht als JSON geparst werden',
    cannotParseValueError: 'Wert kann nicht als JSON geparst werden',
    collapseAll: de[Translations.COLLAPSE_ALL],
    compactTitle: 'JSON minifizieren (Strg+Umschalt+\\)',
    descending: 'Absteigend',
    descendingTitle:
      'Sortiert die Kindelemente dieses ${type}s in absteigender Reihenfolge',
    drag: 'Ziehen zum Verschieben des Feldes (Alt+Umschalt+Pfeil)',
    duplicateKey: 'doppelter Schlüssel',
    duplicateText: 'Duplizieren',
    duplicateTitle: 'Ausgewählte Felder duplizieren (Strg+D)',
    duplicateField: 'Dieses Feld duplizieren (Strg+D)',
    duplicateFieldError: 'Feld Name duplizieren',
    empty: 'leer',
    expandAll: de[Translations.EXPAND_ALL],
    expandTitle:
      'Zum Ausklappen/Einklappen dieses Feldes klicken (Strg+E). \n' +
      'Strg+Klick um auch alle Kindelemente auszuklappen/einzuklappen.',
    formatTitle:
      'JSON Daten mit korrekter Einrückung und Zeilenumbrüchen formatieren (Strg+\\)',
    insert: 'Einfügen',
    insertTitle:
      'Fügt ein neues Feld vom Typ "Auto" vor diesem Feld ein (Strg+Einfg)',
    insertSub: 'Typ des einzufügenden Feldes auswählen',
    object: 'Objekt',
    ok: 'OK',
    redo: 'Wiederholen (Strg+Umschalt+Z)',
    removeText: 'Entfernen',
    removeTitle: 'Ausgewählte Felder entfernen (Strg+Enf)',
    removeField: 'Dieses Feld entfernen (Strg+Enf)',
    repairTitle:
      'JSON reparieren: Anführungszeichen korrigieren und Zeichen escapen, Kommentare und JSONP-Notation entfernen, JavaScript-Objekte in JSON umwandeln.',
    searchTitle: 'Feld Namen und Werte durchsuchen',
    searchNextResultTitle: 'Nächstes Ergebnis (Eingabe)',
    searchPreviousResultTitle: 'Vorheriges Ergebnis (Umschalt+Eingabe)',
    selectNode: 'Einen Knoten auswählen...',
    showAll: 'alle anzeigen',
    showMore: 'mehr anzeigen',
    showMoreStatus:
      '${visibleChilds} von ${totalChilds} Elemente werden angezeigt.',
    sort: 'Sortieren',
    sortTitle: 'Die Kindelemente dieses ${type}s sortieren',
    sortTitleShort: 'Inhalt sortieren',
    sortFieldLabel: 'Feld:',
    sortDirectionLabel: 'Reihenfolge:',
    sortFieldTitle:
      'Das verschachtelte Feld auswählen, nach dem das Array oder Objekt sortiert werden soll',
    sortAscending: 'Aufsteigend',
    sortAscendingTitle:
      'Sortiert das ausgewählte Feld in aufsteigender Reihenfolge',
    sortDescending: 'Absteigend',
    sortDescendingTitle:
      'Sortiert das ausgewählte Feld in absteigender Reihenfolge',
    string: 'String',
    transform: 'Transformieren',
    transformTitle:
      'Filtert, sortiert oder transformiert die Kindelemente dieses ${type}s',
    transformTitleShort: 'Inhalte filtern, sortieren oder transformieren',
    extract: 'Extrahieren',
    extractTitle: 'Dieses ${type} extrahieren',
    transformQueryTitle: 'Ein JMESPath-Abfrage angeben',
    transformWizardLabel: 'Assistent',
    transformWizardFilter: 'Filter',
    transformWizardSortBy: 'Sortieren nach',
    transformWizardSelectFields: 'Felder auswählen',
    transformQueryLabel: 'Abfrage',
    transformPreviewLabel: 'Vorschau',
    type: 'Typ',
    typeTitle: 'Den Typ dieses Feldes ändern',
    openUrl:
      'Strg+Klick oder Strg+Eingabe zum Öffnen der URL in einem neuen Tab',
    undo: 'Letzte Aktion rückgängig machen (Strg+Z)',
    validationCannotMove:
      'Ein Feld kann nicht in sein eigenes Kindelement verschoben werden',
    autoType:
      'Feldtyp "Auto". ' +
      'Der Feldtyp wird automatisch aus dem Wert ermittelt ' +
      'und kann ein String, eine Zahl, ein Boolean oder Null sein.',
    objectType:
      'Feldtyp "Objekt". ' +
      'Ein Objekt enthält einen ungeordneten Satz von Schlüssel-/Wert-Paaren.',
    arrayType:
      'Feldtyp "Array". ' +
      'Ein Array enthält eine geordnete Sammlung von Werten.',
    stringType:
      'Feldtyp "String". ' +
      'Der Feldtyp wird nicht aus dem Wert ermittelt, ' +
      'sondern immer als String interpretiert.',
    modeEditorTitle: 'Editor-Modus wechseln',
    modeCodeText: 'Code-Editor',
    modeCodeTitle: 'Zu Text-Editor mit Syntaxhervorhebung wechseln',
    modeFormText: 'Formular-Editor',
    modeFormTitle: 'Zu Formular-Editor wechseln',
    modeTextText: 'Text-Editor',
    modeTextTitle: 'Zu Text-Editor ohne Syntaxhervorhebung wechseln',
    modeTreeText: 'Baum-Editor',
    modeTreeTitle: 'Zu Baum-Editor wechseln',
    modeViewText: 'Baum-Ansicht',
    modeViewTitle: 'Zu Baum-Ansicht wechseln',
    modePreviewText: 'Text-Ansicht',
    modePreviewTitle: 'Zu Text-Ansicht wechseln',
    examples: 'Beispiele',
    default: 'Standard'
  }
};

@Component
export default class ObjectEditorComponent extends Vue {
  private editor: JSONEditor | null = null;
  private errors: string[] = [];

  @Prop({ type: [String, Boolean], default: null })
  private hint!: string | null;

  public get hasError(): boolean {
    return !!this.errors.length;
  }

  @Inject({ default: null }) readonly form!: {
    register: (input: Vue) => void;
    unregister: (input: Vue) => void;
  } | null;

  @Prop({ type: String, default: '' })
  private readonly label!: string;

  @Prop({ type: Object, default: null })
  private readonly value!: JsonObject;

  @Watch('value', { deep: true })
  private onValueChanged(value: JsonObject): void {
    if (this.editor) {
      this.editor.update(_.defaultsDeep(value, this.defaultValue));
    }
  }

  @Prop({
    type: Array,
    default: (): ValidationRules => []
  })
  private readonly rules!: ValidationRules;

  @Prop({ type: Object, default: (): JsonObject => ({}) })
  private readonly defaultValue!: JsonObject;

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean;

  @Watch('disabled')
  private onDisabledChanged(disabled: boolean): void {
    this.errors = [];
    if (this.editor) {
      this.editor.setMode(disabled ? 'view' : 'tree');
    }
  }

  private onChange(): void {
    if (this.editor) {
      let value: JsonObject | null = this.editor.get();
      if (_.isEqual(value, this.defaultValue)) {
        value = null;
      }
      this.errors = this.rules.reduce(
        (errors: string[], rule: ValidationRule): string[] => {
          const error: boolean | string = rule(value);
          if (typeof error === 'string') {
            errors.push(error);
          }
          return errors;
        },
        []
      );
      this.$emit('input', value);
    }
  }

  private created(): void {
    this.form && this.form.register(this);
  }

  private mounted(): void {
    this.createEditor();
  }

  private createEditor(): void {
    const container: HTMLElement = this.$refs.jsoneditor as HTMLElement;
    this.editor = new JSONEditor(container, {
      onChange: this.onChange,
      mode: this.disabled ? 'view' : 'tree',
      search: false,
      mainMenuBar: false,
      navigationBar: false,
      statusBar: false,
      enableSort: false,
      enableTransform: false,
      theme: 'ace/theme/homeeTheme',
      language: userStore.locale,
      languages
    });
    this.editor.set(_.defaultsDeep(this.value, this.defaultValue));
  }

  private destroyEditor(): void {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  }

  private beforeDestroy(): void {
    this.form && this.form.unregister(this);
    this.destroyEditor();
  }
}
