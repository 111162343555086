import { noop } from 'lodash';
import type { ComponentOptions } from 'vue';
import { createDecorator, VueDecorator } from 'vue-class-component';

interface WaitForPromiseOptions {
  promise: Promise<unknown> | WaitForPromisePromise;
}

type WaitForPromisePromise = Promise<unknown> & {
  done: boolean;
} & {
  [key: string]: Promise<unknown>;
};

export const WaitForPromise: (
  options: WaitForPromiseOptions
) => VueDecorator = (decoratorOptions: WaitForPromiseOptions): VueDecorator =>
  createDecorator(
    (componentOptions: ComponentOptions<Vue>, key: string): void => {
      if (
        !decoratorOptions ||
        !decoratorOptions.promise ||
        !componentOptions.methods ||
        !componentOptions.methods[key]
      ) {
        return;
      }
      const method: (this: Vue, ...args: unknown[]) => unknown =
        componentOptions.methods[key];
      const promise: WaitForPromisePromise = decoratorOptions.promise as WaitForPromisePromise;

      componentOptions.methods[key] = async function wrapperMethod(
        ...args: unknown[]
      ): Promise<unknown> {
        if (promise.done) {
          return method.apply(this, args);
        }
        if (promise[`${key}Waiting`]) {
          return promise[`${key}Waiting`];
        }
        promise[`${key}Waiting`] = promise.catch(noop).then((): unknown => {
          promise.done = true;
          return method.apply(this, args);
        });
        return promise[`${key}Waiting`];
      };
    }
  );
