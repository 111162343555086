import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';
import type { CognitoUser } from '@aws-amplify/auth';
import { I18n } from '@aws-amplify/core';
import { Storage } from '@aws-amplify/storage';
import { Auth } from '@aws-amplify/auth';
import type { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Locale, supportedLanguages } from '../../plugins/i18n';
import { noop } from 'lodash';
import { persistentDocumentsStore } from '.';
import { mdiAccountCircle } from '@mdi/js';

export interface UserState {
  isAuthenticated: boolean;
  name: string;
  email: string;
  avatar: string;
  avatarUrl: string;
  locale: Locale;
  groups: string[];
}

export const namespace: 'user' = 'user' as const;

const browserLocale: Locale = (
  navigator.language ||
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (navigator as any).browserLanguage ||
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (navigator as any).userLanguage ||
  'en-US'
).split('-')[0] as Locale;
const defaultLocale: Locale = supportedLanguages.includes(browserLocale)
  ? browserLocale
  : Locale.en;
const defaultAvatarUrl: string =
  'data:image/svg+xml;base64,' +
  btoa(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 20 20"><path fill="#6F6F6F" d="${mdiAccountCircle}"></path></svg>`
  );

async function loadCurrentUserState(): Promise<UserState> {
  const user: CognitoUser = await Auth.currentAuthenticatedUser();
  const attributes: Record<string, string> = (
    await Auth.userAttributes(user)
  ).reduce(
    (
      attributes: Record<string, string>,
      attribute: CognitoUserAttribute
    ): Record<string, string> => ({
      ...attributes,
      [attribute.getName()]: attribute.getValue()
    }),
    {}
  );

  let groups: string[] = [];
  try {
    groups = (await Auth.userSession(user)).getAccessToken().payload[
      'cognito:groups'
    ];
  } catch (e) {
    //
  }

  let avatarUrl: string = defaultAvatarUrl;
  if (attributes['custom:avatar']) {
    await Storage.get(attributes['custom:avatar'], {
      level: 'private'
    })
      .then(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (url: Record<string, any> | string): void => {
          avatarUrl = url as string;
        }
      )
      .catch(noop);
  }

  const locale: Locale = attributes.locale as Locale;
  I18n.setLanguage(locale);

  return {
    isAuthenticated: true,
    name: attributes.name,
    email: attributes.email,
    avatar: attributes['custom:avatar'] ?? '',
    avatarUrl,
    locale,
    groups
  };
}

export async function restoreState(): Promise<{ [namespace]?: UserState }> {
  try {
    return {
      [namespace]: await loadCurrentUserState()
    };
  } catch (e) {
    return {
      [namespace]: {
        isAuthenticated: false,
        locale: defaultLocale
      } as UserState
    };
  }
}

@Module({
  name: namespace,
  namespaced: true
})
export default class UserModule extends VuexModule implements UserState {
  public isAuthenticated: boolean = false;
  public name: string = '';
  public email: string = '';
  public avatar: string = '';
  public avatarUrl: string = defaultAvatarUrl;
  public locale: Locale = defaultLocale;
  public groups: string[] = [];

  @MutationAction({
    mutate: ['name', 'avatar', 'avatarUrl', 'locale']
  })
  public async updateUser(
    changes: Partial<{
      name: string;
      avatar: string;
      locale: Locale;
    }>
  ): Promise<{
    name: string;
    avatar: string;
    avatarUrl: string;
    locale: Locale;
  }> {
    const state: UserState = this.state as UserState;

    let avatarUrl: string = state.avatarUrl;
    if (changes.avatar) {
      await Storage.get(changes.avatar, {
        level: 'private'
      })
        .then(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (url: Record<string, any> | string): void => {
            avatarUrl = url as string;
          }
        )
        .catch(noop);
    }

    const name: string = changes.name || state.name;
    const avatar: string = changes.avatar || state.avatar;
    const locale: Locale = changes.locale || state.locale;

    I18n.setLanguage(locale);

    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      name,
      'custom:avatar': avatar,
      locale
    });

    return {
      name,
      avatar,
      avatarUrl,
      locale
    };
  }

  @MutationAction({
    mutate: [
      'isAuthenticated',
      'name',
      'email',
      'avatar',
      'avatarUrl',
      'locale',
      'groups'
    ]
  })
  public login(): Promise<UserState> {
    return loadCurrentUserState();
  }

  @MutationAction({
    mutate: [
      'isAuthenticated',
      'name',
      'email',
      'avatar',
      'avatarUrl',
      'locale',
      'groups'
    ]
  })
  public async logout(): Promise<UserState> {
    I18n.setLanguage(defaultLocale);
    await Promise.allSettled([
      localStorage.removeItem('initialized'),
      persistentDocumentsStore.clearStore()
    ]);
    return {
      isAuthenticated: false,
      name: '',
      email: '',
      avatar: '',
      avatarUrl: defaultAvatarUrl,
      locale: defaultLocale,
      groups: []
    };
  }
}
