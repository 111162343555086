






















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Bind, Debounce } from 'lodash-decorators';
import TooltipOnTruncateComponent from '../components/TooltipOnTruncateComponent.vue';
import { ResizeObserver } from 'vue-resize';
import { mdiImageOutline } from '@mdi/js';
import { Translations } from '../plugins/i18n';
import { I18n } from '@aws-amplify/core';
import type { Card } from '../typings/card';

@Component({
  components: {
    TooltipOnTruncateComponent,
    ResizeObserver
  }
})
export default class CardListComponent extends Vue {
  private readonly margin: number = 20;
  private readonly rowBuffer: number = 2;
  private readonly defaultTitle: string = I18n.get(Translations.UNKNOWN);
  private readonly defaultImage: string =
    'data:image/svg+xml;base64,' +
    btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" height="200px" width="300px" viewBox="-37.5 -37.5 100 100" style="background-color: #FFF;"><path fill="#6F6F6F" d="${mdiImageOutline}"></path></svg>`
    );

  private visibleColumnCount: number = 0;
  private visibleRowCount: number = 0;
  private currentRenderStart: number = 0;

  private get containerHeight(): number {
    return (
      Math.ceil(this.cards.length / this.visibleColumnCount) *
        (this.cardHeight + this.margin * 2) +
      this.margin
    );
  }

  private get scrollOffset(): number {
    return this.currentRenderStart * (this.cardHeight + this.margin * 2);
  }

  private get renderedCards(): Card[] {
    const start: number = Math.min(
        this.currentRenderStart * this.visibleColumnCount,
        this.cards.length
      ),
      end: number = Math.min(
        start +
          this.visibleColumnCount * (this.visibleRowCount + this.rowBuffer * 2),
        this.cards.length
      );
    if (this.sort) {
      return this.orderedCards.slice(start, end);
    } else {
      return this.cards.slice(start, end);
    }
  }

  private get orderedCards(): Card[] {
    return (this.cards || []).sort(
      (item1: Card, item2: Card): number => item2.changedAt - item1.changedAt
    );
  }

  @Prop({ type: Array, default: (): Card[] => [] })
  private readonly cards!: Card[];

  @Prop({ type: Boolean, default: true })
  private readonly sort!: boolean;

  @Prop({ type: Number, default: 300 })
  private readonly cardWidth!: number;

  @Prop({ type: Number, default: 264 })
  private readonly cardHeight!: number;

  @Debounce(50)
  @Bind()
  private handleResize(): void {
    const container: Element = this.$refs.container as Element;
    if (!container) {
      return;
    }
    this.visibleColumnCount = Math.max(
      Math.floor(
        (container.clientWidth - this.margin * 2) /
          (this.cardWidth + this.margin * 2)
      ),
      1
    );
    this.visibleRowCount = Math.ceil(
      (container.clientHeight - this.margin * 2) /
        (this.cardHeight + this.margin * 2)
    );
  }

  @Debounce(50)
  @Bind()
  private handleScroll(event: UIEvent): void {
    const container: Element = event.target as Element;
    this.currentRenderStart = Math.max(
      Math.ceil(
        (container.scrollTop - this.margin) /
          (this.cardHeight + this.margin * 2)
      ) - this.rowBuffer,
      0
    );
  }

  private mounted(): void {
    this.handleResize();
  }
}
