











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TooltipOnTruncateComponent extends Vue {
  @Prop({ type: String, default: '' })
  private readonly tooltipText!: string;

  private disableTooltip: boolean = true;

  private setVisibility(event: MouseEvent): void {
    if (
      (event.target as HTMLElement).offsetWidth !==
      (event.target as HTMLElement).scrollWidth
    ) {
      this.disableTooltip = false;
    }
  }
}
