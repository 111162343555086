import {
  DocTypes,
  LocalizedDocument,
  supportedLocalizations
} from './document';
import { Translations } from '../plugins/i18n';
import {
  supportedBrands,
  Placeholder,
  Brand,
  defaultDetailProperties as defaultPlaceholderDetailProperties
} from './placeholder';
import JSZip from 'jszip';
import * as XLSX from 'xlsx';
import { I18n } from '@aws-amplify/core';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { queryWholeDocumentList } from '.';
import { Localization, defaultDetailProperties } from './localization';

const emojiRegex: Promise<RegExp> = fetch(
  'https://cors-anywhere.herokuapp.com/https://www.unicode.org/Public/UCD/latest/ucd/emoji/emoji-data.txt',
  {
    //cors-anywhere allows us to bypass CORS restricitons
    headers: {
      origin: location.origin
    }
  }
)
  .then((response: Response): Promise<string> => response.text())
  .then(
    (data: string): RegExp => {
      const pattern: string[] = [];
      const unicode: string[] = data
        .replace(/^#.*?\n/gm, '')
        .replace(/;.*?\n/gm, '||')
        .replace(/\n/gm, '')
        .split('||');
      unicode.pop();
      unicode.forEach((code: string): void => {
        code = code.trim();
        switch (code.length) {
          case 4:
          case 5:
            pattern.push(`\\u{${code}}`);
            break;
          case 10:
          case 12: {
            const codes: string[] = code.split('..');
            pattern.push(`[\\u{${codes[0]}}-\\u{${codes[1]}}]`);
            break;
          }
        }
      });
      //Disincludes NUMBER SIGN(#), ASTERISK(*), and DIGIT ZERO..DIGIT NINE(0-9)
      return new RegExp(
        pattern
          .join('|')
          .replace(
            /\\\u\{0023\}\|\\\u\{002A\}\|\[\\\u\{0030\}-\\\u\{0039\}\]\|/g,
            ''
          ),
        'u'
      );
    }
  );

export enum DownloadTarget {
  Android = 'Android',
  iOS = 'iOS',
  Web = 'Web',
  Notification = 'Notification',
  Excel = 'Excel',
  Excel_Missing = 'Excel_Missing',
  All = 'All'
}

export async function triggerDownload(target: DownloadTarget): Promise<void> {
  let p1: Promise<Localization[]>;
  if (target === DownloadTarget.Excel_Missing) {
    p1 = Promise.all(
      supportedLocalizations.map(
        (loc: keyof LocalizedDocument): Promise<Localization[]> =>
          queryWholeDocumentList<Localization>(
            DocTypes.Localization,
            defaultDetailProperties,
            {
              [loc]: {
                exists: false
              }
            }
          )
      )
    ).then((results: Localization[][]): Localization[] =>
      _.uniqBy(results.flat(1), '_id')
    );
  } else {
    p1 = queryWholeDocumentList<Localization>(
      DocTypes.Localization,
      defaultDetailProperties
    );
  }
  const p2: Promise<Placeholder[]> = queryWholeDocumentList<Placeholder>(
    DocTypes.Placeholder,
    defaultPlaceholderDetailProperties
  );
  const localizationList: Localization[] = (await p1).sort(
    (item1: Localization, item2: Localization): number =>
      item1.stringIdentifier.localeCompare(item2.stringIdentifier, undefined, {
        sensitivity: 'base'
      })
  );
  const placeholderList: Placeholder[] = await p2;
  const brandedLocalization: Record<
    Brand,
    Record<string, LocalizedDocument>
  > = await getBrandedLocalization(localizationList, placeholderList);
  const zip: JSZip = new JSZip();
  switch (target) {
    case DownloadTarget.Android:
      Object.entries(await formatForAndroid(brandedLocalization, true)).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(path, content)
      );
      Object.entries(
        await formatForAndroid(brandedLocalization, false)
      ).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(path, content)
      );
      break;
    case DownloadTarget.iOS:
      Object.entries(formatForiOS(brandedLocalization)).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(path, content)
      );
      break;
    case DownloadTarget.Web:
      Object.entries(formatForWeb(brandedLocalization)).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(path, content)
      );
      break;
    case DownloadTarget.Notification:
      Object.entries(formatForNotificationServer(brandedLocalization)).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(path, content)
      );
      break;
    case DownloadTarget.Excel:
      XLSX.writeFile(
        formatForExcel(localizationList),
        `${I18n.get(Translations.LOCALIZATION)}.xlsx`
      );
      return Promise.resolve();
    case DownloadTarget.Excel_Missing:
      XLSX.writeFile(
        formatForExcel(localizationList),
        `${I18n.get(Translations.LOCALIZATION)}_${I18n.get(
          Translations.MISSING
        )}.xlsx`
      );
      return Promise.resolve();
    case DownloadTarget.All:
      Object.entries(await formatForAndroid(brandedLocalization, true)).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(`${DownloadTarget.Android}/${path}`, content)
      );
      Object.entries(
        await formatForAndroid(brandedLocalization, false)
      ).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(`${DownloadTarget.Android}/${path}`, content)
      );
      Object.entries(formatForiOS(brandedLocalization)).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(`${DownloadTarget.iOS}/${path}`, content)
      );
      Object.entries(formatForWeb(brandedLocalization)).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(`${DownloadTarget.Web}/${path}`, content)
      );
      Object.entries(formatForNotificationServer(brandedLocalization)).forEach(
        ([path, content]: [string, string]): void =>
          void zip.file(`${DownloadTarget.Web}/${path}`, content)
      );
      zip.file(
        `${I18n.get(Translations.LOCALIZATION)}.xlsx`,
        XLSX.write(formatForExcel(localizationList), { type: 'array' })
      );
      break;
  }
  return zip
    .generateAsync({ type: 'blob' })
    .then((file: Blob): void => saveAs(file, `${target}.zip`));
}

async function getBrandedLocalization(
  localizationList: Localization[],
  placeholderList: Placeholder[]
): Promise<Record<Brand, Record<string, LocalizedDocument>>> {
  const brandedLocalization: Record<
    Brand,
    Record<string, LocalizedDocument>
  > = Object.values(supportedBrands).reduce(
    (
      brandedLocalization: Record<Brand, Record<string, LocalizedDocument>>,
      brand: string
    ): Record<Brand, Record<string, LocalizedDocument>> => ({
      ...brandedLocalization,
      [brand]: {}
    }),
    {} as Record<Brand, Record<string, LocalizedDocument>>
  );
  for (const localization of localizationList) {
    for (const brand of supportedBrands) {
      const doc: LocalizedDocument = {} as LocalizedDocument;
      for (const locale of supportedLocalizations) {
        let fallbackLocale: keyof LocalizedDocument = locale;
        if (locale !== 'de') {
          fallbackLocale = localization[locale]
            ? locale
            : localization['en']
            ? 'en'
            : localization['de']
            ? 'de'
            : locale;
        }
        doc[locale] = localization[fallbackLocale] ?? '';
        for (const placeholder of placeholderList) {
          doc[locale] = doc[locale].replaceAll(
            `%${placeholder.stringIdentifier}%`,
            placeholder[brand][fallbackLocale] ?? ''
          );
        }
      }
      brandedLocalization[brand][localization.stringIdentifier] = doc;
    }
  }
  return brandedLocalization;
}

async function formatForAndroid(
  brandedLocalization: Record<Brand, Record<string, LocalizedDocument>>,
  withEmoji: boolean
): Promise<Record<string, string>> {
  const emoji: RegExp = withEmoji
    ? /notused/
    : new RegExp(`\\s?(${(await emojiRegex).source})`, 'gmu');
  const getPath: (brand: string, locale: string) => string = (
    brand: string,
    locale: string
  ): string =>
    `${withEmoji ? 'emoji' : 'noemoji'}/${brand}/values${
      locale === 'en' ? '' : `-${locale}`
    }/strings.xml`;
  const zipFiles: Record<string, string> = {};
  for (const brand of supportedBrands) {
    for (const locale of supportedLocalizations) {
      const path: string = getPath(brand, locale);
      zipFiles[path] = '<?xml version="1.0" encoding="utf-8"?>\n<resources>\n';
      for (const stringIdentifier in brandedLocalization[brand]) {
        if (
          stringIdentifier.startsWith('CORE_NOTIFICATION_') ||
          stringIdentifier.startsWith('EMAIL_NOTIFICATION_')
        ) {
          continue;
        }
        zipFiles[path] += `    <string name="${stringIdentifier.replaceAll(
          '-1',
          'n1'
        )}">${
          withEmoji
            ? brandedLocalization[brand][stringIdentifier][locale]
                .replace(/\n/g, '\\n')
                .replace(/"/g, '\\"')
                .replace(/'/g, "\\'")
            : brandedLocalization[brand][stringIdentifier][locale]
                .replace(/\n/g, '\\n')
                .replace(/"/g, '\\"')
                .replace(/'/g, "\\'")
                .replace(emoji, '')
        }</string>\n`;
      }
      zipFiles[path] += '</resources>';
    }
  }
  return zipFiles;
}

function formatForiOS(
  brandedLocalization: Record<Brand, Record<string, LocalizedDocument>>
): Record<string, string> {
  const getPath: (brand: string, locale: string) => string = (
    brand: string,
    locale: string
  ): string => `${brand}/${locale}/Localizable.strings`;
  const zipFiles: Record<string, string> = {};
  for (const brand of supportedBrands) {
    for (const locale of supportedLocalizations) {
      const path: string = getPath(brand, locale);
      zipFiles[path] = '';
      for (const stringIdentifier in brandedLocalization[brand]) {
        if (
          stringIdentifier.startsWith('CORE_NOTIFICATION_') ||
          stringIdentifier.startsWith('EMAIL_NOTIFICATION_')
        ) {
          continue;
        }
        zipFiles[path] += `"${stringIdentifier}" = "${brandedLocalization[
          brand
        ][stringIdentifier][locale]
          .replace(/\n/g, '\\n')
          .replace(/"/g, '\\"')}";\n`;
      }
    }
  }
  return zipFiles;
}

function formatForWeb(
  brandedLocalization: Record<Brand, Record<string, LocalizedDocument>>
): Record<string, string> {
  const getPath: (brand: string, locale: string) => string = (
    brand: string,
    locale: string
  ): string => `${brand}/translations-${locale}.json`;
  const zipFiles: Record<string, string> = {};
  for (const brand of supportedBrands) {
    for (const locale of supportedLocalizations) {
      const json: Record<string, string> = {};
      for (const stringIdentifier in brandedLocalization[brand]) {
        if (
          stringIdentifier.startsWith('CORE_NOTIFICATION_') ||
          stringIdentifier.startsWith('EMAIL_NOTIFICATION_')
        ) {
          continue;
        }
        json[stringIdentifier] =
          brandedLocalization[brand][stringIdentifier][locale];
      }
      zipFiles[getPath(brand, locale)] = JSON.stringify(json, null, 4);
    }
  }
  return zipFiles;
}

function formatForNotificationServer(
  brandedLocalization: Record<Brand, Record<string, LocalizedDocument>>
): Record<string, string> {
  const getPath: (brand: string, locale: string) => string = (
    brand: string,
    locale: string
  ): string => `${brand}/${locale}.json`;
  const zipFiles: Record<string, string> = {};
  for (const brand of supportedBrands) {
    for (const locale of supportedLocalizations) {
      const json: Record<string, string> = {};
      for (const stringIdentifier in brandedLocalization[brand]) {
        if (!stringIdentifier.startsWith('EMAIL_NOTIFICATION_')) {
          continue;
        }
        json[stringIdentifier] =
          brandedLocalization[brand][stringIdentifier][locale];
      }
      zipFiles[getPath(brand, locale)] = JSON.stringify(json, null, 4);
    }
  }
  return zipFiles;
}

function formatForExcel(localizationList: Localization[]): XLSX.WorkBook {
  const xlsx: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    xlsx,
    XLSX.utils.json_to_sheet(
      localizationList.map(
        (localization: Localization): Partial<Localization> =>
          _.pickBy(
            localization,
            (_value: unknown, key: string): boolean =>
              key === 'stringIdentifier' ||
              supportedLocalizations.includes(key as keyof LocalizedDocument)
          )
      ),
      {
        header: ['stringIdentifier', ...supportedLocalizations]
      }
    ),
    I18n.get(Translations.LOCALIZATION)
  );
  return xlsx;
}
