import { Document, DocTypes, DocsFilter } from './document';
import type { Localization } from './localization';
import { Translations } from '../plugins/i18n';
import { I18n } from '@aws-amplify/core';
import { FieldTypes, DetailData } from '../typings/field';
import { persistentDocumentsStore, userStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface TriggerOperator extends Document {
  _docType: DocTypes.TriggerOperator;
  name: string;
  number: number;
  prefix: Localization | null;
  suffix: Localization | null;
}

export const defaultListProperties: string = `
  name
  number
`;

export const defaultDetailProperties: string = `
  name
  number
  prefix {
    _id
  }
  suffix {
    _id
  }
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.ENUM_KEY),
      value: 'name',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.ENUM_VALUE),
      value: 'number',
      align: 'start',
      sortable: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: TriggerOperator[] = Object.values(
    persistentDocumentsStore.TriggerOperator
  );
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (
      document: TriggerOperator
    ): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      name: document.name,
      number: document.number ?? ''
    })
  );
}

export const sortListBy: string | string[] = 'number';

export async function getCategories(
  document: Partial<TriggerOperator>
): Promise<DetailData> {
  return {
    title: document.name,
    subtitle: document.number?.toString(),
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.ENUM_KEY),
            required: true,
            model: 'name',
            value: document.name,
            prefix: 'CATriggerOperator'
          },
          {
            type: FieldTypes.NUMBER,
            label: I18n.get(Translations.ENUM_VALUE),
            required: true,
            model: 'number',
            value: document.number
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PREFIX),
            required: false,
            model: 'prefix',
            value: document.prefix,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier
                  .toUpperCase()
                  .startsWith('HOMEEGRAMS_LOGIC_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_PREFIX')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.SUFFIX),
            required: false,
            model: 'suffix',
            value: document.suffix,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier
                  .toUpperCase()
                  .startsWith('HOMEEGRAMS_LOGIC_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_SUFFIX')
            ),
            multiple: false
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.ENUM_KEY),
      property: 'name'
    },
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'le',
        'lt',
        'ge',
        'gt',
        'between',
        'in'
      ],
      label: I18n.get(Translations.ENUM_VALUE),
      validator: (data: string): boolean => !isNaN(parseInt(data)),
      property: 'number'
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.PREFIX),
      property: 'prefix',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('HOMEEGRAMS_LOGIC_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_PREFIX')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.SUFFIX),
      property: 'suffix',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('HOMEEGRAMS_LOGIC_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_SUFFIX')
      )
    }
  ];
}
