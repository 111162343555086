












































































































































































































































































































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import {
  mdiCloseThick,
  mdiCheckBold,
  mdiCodeJson,
  mdiLock,
  mdiCloudLock,
  mdiCloudCheck,
  mdiImageOutline
} from '@mdi/js';
import { IntegrationState, ExportState } from '../api/product';
import { DELETE_GROUP, Rows } from '../api';
import store, { persistentDocumentsStore, userStore } from '../plugins/store';
import type { DataTableHeader } from 'vuetify';
import Row from 'vuetify/lib/components/VDataTable/Row';
import MobileRow from 'vuetify/lib/components/VDataTable/MobileRow';
import { caiCube } from '../plugins/vuetify';

@Component({
  components: {
    'v-row': Row,
    'v-mobile-row': MobileRow
  }
})
export default class TableListComponent extends Vue {
  private readonly IntegrationState: typeof IntegrationState = IntegrationState;
  private readonly ExportState: typeof ExportState = ExportState;
  private readonly closeIconSvg: string = mdiCloseThick;
  private readonly codeIconSvg: string = mdiCodeJson;
  private readonly checkIconSvg: string = mdiCheckBold;
  private readonly cloudCheckIconSvg: string = mdiCloudCheck;
  private readonly cloudLockIconSvg: string = mdiCloudLock;
  private readonly lockIconSvg: string = mdiLock;
  private readonly cubeIconSvg: string = caiCube;
  private readonly defaultImage: string =
    'data:image/svg+xml;base64,' +
    btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" height="56px" viewBox="-8 -8 40 40"><path fill="#6F6F6F" d="${mdiImageOutline}"></path></svg>`
    );
  private restored: boolean = false;

  @Prop({ type: Array })
  private readonly headers: DataTableHeader[] | undefined;

  @Prop({ type: Array })
  private readonly items: Rows | undefined;

  @Prop({ type: String, default: '/' })
  private readonly baseUrl!: string;

  @Prop({ type: [String, Array], default: '' })
  private readonly sortBy!: string | string[];

  @Prop({ type: Boolean, default: false })
  private readonly loading!: boolean;

  private get itemsPerPage(): number {
    return persistentDocumentsStore.userPreferences.itemsPerPage;
  }

  private set itemsPerPage(itemsPerPage: number) {
    persistentDocumentsStore.updateItemsPerPage(itemsPerPage);
  }

  @PropSync('page', {
    type: Number,
    default: 1
  })
  private _page!: number;

  @PropSync('selected', {
    type: Array,
    default: (): Array<Record<string, string | null>> => []
  })
  private _selected!: string[];

  private selectedItems: Array<Record<string, string | null>> = [];

  @Watch('selectedItems')
  private mapSelectedItems(): void {
    this._selected = this.selectedItems.map(
      (item: Record<string, string | null>): string => item._id as string
    );
  }

  private get deletePermission(): boolean {
    return userStore.groups.includes(DELETE_GROUP);
  }

  private created(): void {
    store.restored.finally((): void => void (this.restored = true));
  }

  private isOrContains(node: Node | null, container: HTMLElement): boolean {
    while (node) {
      if (node === container) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  private elementContainsSelection(element: HTMLElement): boolean {
    if (window.getSelection) {
      const selection: Selection | null = window.getSelection();
      if (selection && selection.type === 'Range' && selection.rangeCount > 0) {
        for (let i: number = 0; i < selection.rangeCount; ++i) {
          if (
            !this.isOrContains(
              selection.getRangeAt(i).commonAncestorContainer,
              element
            )
          ) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }
}
