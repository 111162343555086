import { DocTypes, DocumentFilter } from './document';
import { userStore } from '../plugins/store';
import { I18n } from '@aws-amplify/core';
import type { AttributeType } from './attributeType';
import type { Cube } from './cube';
import type { Localization } from './localization';
import { Translations } from '../plugins/i18n';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { queryWholeDocumentList } from '.';
import type { Product } from './product';
import { ExportState } from './product';
import _ from 'lodash';
import type { Protocol } from './protocol';

export enum DownloadTarget {
  JSON = 'JSON',
  EXCEL = 'EXCEL'
}

export async function triggerDownload(
  target: DownloadTarget,
  filter: DocumentFilter | undefined = undefined
): Promise<void> {
  switch (target) {
    case DownloadTarget.JSON:
      return saveAs(
        new Blob(
          [
            new TextEncoder().encode(
              JSON.stringify(
                formatForJSON(
                  await queryWholeDocumentList<Product>(
                    DocTypes.Product,
                    `
                      productType {
                        displayName {
                          stringIdentifier
                        }
                        productIcon {
                          name
                        }
                      }
                      exportApps
                      compatibility
                      displayName {
                        stringIdentifier
                      }
                      integrationStateAndroid
                      integrationStateIos
                      integrationStateWeb
                      processShort {
                        stringIdentifier
                      }
                      processLong {
                        stringIdentifier
                      }
                      preProcess {
                        stringIdentifier
                      }
                      process {
                        stringIdentifier
                      }
                      postProcess {
                        stringIdentifier
                      }
                      protocols {
                        number
                      }
                      cubes {
                        number
                      }
                      eanCodes
                      learnModeParameters
                      userInteractionParameters
                    `,
                    {
                      exportApps: {
                        exists: true,
                        ne: ExportState.PRIVATE
                      },
                      productType: {
                        exists: true
                      },
                      displayName: {
                        exists: true
                      },
                      protocols: {
                        exists: true
                      },
                      cubes: {
                        exists: true
                      }
                    }
                  )
                ),
                null,
                4
              )
            )
          ],
          {
            type: 'application/json;charset=utf-8'
          }
        ),
        `products.json`
      );
    case DownloadTarget.EXCEL:
      return XLSX.writeFile(
        formatForExcel(
          await queryWholeDocumentList<Product>(
            DocTypes.Product,
            `
              displayName {
                ${userStore.locale}
              }
              manufacturer
              protocols {
                displayName {
                  ${userStore.locale}
                }
              }
              productName
              attributes {
                name
              }
              asin
              eanCodes
          `,
            filter
          )
        ),
        'products.xlsx'
      );
    default:
      throw new Error('Not implemented!');
  }
}

interface JsonDownload {
  typeKey: string;
  typeIcon: string;
  devices: Array<{
    nameKey: string;
    nodeProtocols: number[];
    cubeTypes: number[];
    export: ExportState;
    processShortKey?: string;
    processLongKey?: string;
    preProcessKeys?: string[];
    processKeys?: string[];
    postProcessKeys?: string[];
    gtinCodes?: string[];
    learnModeParameters?: unknown;
    userInteractionParameters?: unknown;
  }>;
}

function formatForJSON(productList: Product[]): JsonDownload[] {
  return _.sortBy(
    Object.values(
      _.sortBy(productList, '_createdAt').reduce(
        (
          groupedList: Record<string, JsonDownload>,
          product: Product
        ): Record<string, JsonDownload> => {
          const gtinCodes: string[] = ([] as string[])
            .concat(product.eanCodes || [])
            .filter((code: string): boolean => code !== 'n/a');
          if (
            !product?.exportApps ||
            product?.exportApps === ExportState.PRIVATE ||
            !product?.productType?.displayName?.stringIdentifier ||
            !product?.displayName?.stringIdentifier ||
            !product?.protocols ||
            !product?.protocols?.length ||
            !product?.cubes ||
            !product?.cubes?.length
          ) {
            return groupedList;
          }
          groupedList[
            product.productType.displayName.stringIdentifier
          ] = groupedList[product.productType.displayName.stringIdentifier] || {
            typeKey: product.productType.displayName.stringIdentifier,
            typeIcon: product.productType.productIcon?.name,
            devices: []
          };
          groupedList[
            product.productType.displayName.stringIdentifier
          ].devices.push({
            nameKey: product.displayName.stringIdentifier,
            nodeProtocols: ([] as Protocol[])
              .concat(product.protocols || [])
              .map((protocol: Protocol): number => protocol.number),
            cubeTypes: (product.cubes || []).map(
              (cube: Cube): number => cube.number
            ),
            export: product.exportApps,
            ...(product.compatibility
              ? {
                  compatibility: product.compatibility
                }
              : {}),
            ...(product.processShort
              ? { processShortKey: product.processShort.stringIdentifier }
              : {}),
            ...(product.processLong
              ? { processLongKey: product.processLong.stringIdentifier }
              : {}),
            ...(product.preProcess && product.preProcess.length
              ? {
                  preProcessKeys: product.preProcess.map(
                    (localization: Localization): string =>
                      localization.stringIdentifier
                  )
                }
              : {}),
            ...(product.process && product.process.length
              ? {
                  processKeys: product.process.map(
                    (localization: Localization): string =>
                      localization.stringIdentifier
                  )
                }
              : {}),
            ...(product.postProcess && product.postProcess.length
              ? {
                  postProcessKeys: product.postProcess.map(
                    (localization: Localization): string =>
                      localization.stringIdentifier
                  )
                }
              : {}),
            ...(gtinCodes.length ? { gtinCodes } : {}),
            ...(product.learnModeParameters
              ? { learnModeParameters: JSON.parse(product.learnModeParameters) }
              : {}),
            ...(product.userInteractionParameters
              ? {
                  userInteractionParameters: JSON.parse(
                    product.userInteractionParameters
                  )
                }
              : {})
          });
          return groupedList;
        },
        {}
      )
    ),
    'typeKey'
  );
}

function formatForExcel(productList: Product[]): XLSX.WorkBook {
  const xlsx: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    xlsx,
    XLSX.utils.json_to_sheet(
      productList.map(
        (product: Product): Record<string, string> => ({
          [I18n.get(Translations.DISPLAY_NAME)]:
            product.displayName?.[userStore.locale] ||
            `<<${product.productName}>>`,
          [I18n.get(Translations.MANUFACTURER)]: product.manufacturer,
          [I18n.get(Translations.PROTOCOLS)]: ([] as Protocol[])
            .concat(product.protocols || [])
            .reduce((protocols: string, protocol: Protocol): string => {
              const text: string | null =
                protocol?.displayName?.[userStore.locale] || protocol?.name;
              if (text) {
                protocols += `${protocols ? ', ' : ''}${text}`;
              }
              return protocols;
            }, ''),
          [I18n.get(Translations.ATTRIBUTETYPES)]: (product.attributes || [])
            .map((attribute: AttributeType): string => attribute.name)
            .join(', '),
          [I18n.get(Translations.ASIN)]: product.asin || '',
          [I18n.get(Translations.EAN_CODES)]: (product.eanCodes || [])
            .filter((code: string): boolean => code !== 'n/a')
            .join(', ')
        })
      ),
      I18n.get(Translations.PRODUCTS)
    )
  );
  return xlsx;
}
