import type { JsonValue, JsonArray } from 'type-fest';

export enum DocTypes {
  AttributeType = 'AttributeType',
  AttributeValue = 'AttributeValue',
  ConditionOperator = 'ConditionOperator',
  Cube = 'Cube',
  HomeegramClass = 'HomeegramClass',
  Localization = 'Localization',
  Placeholder = 'Placeholder',
  Product = 'Product',
  ProductIcon = 'ProductIcon',
  ProductType = 'ProductType',
  Profile = 'Profile',
  Protocol = 'Protocol',
  Service = 'Service',
  TriggerOperator = 'TriggerOperator',
  UiElement = 'UiElement',
  UseCase = 'UseCase'
}

export const supportedDocTypes: DocTypes[] = Object.values(DocTypes);

export interface Document {
  _id: string;
  _docType: DocTypes;
  _changedAt: number;
  _changedBy: string;
  _createdAt: number;
  _createdBy: string;
  _lockedAt: number;
  _deleted: boolean;
}

export interface DocsFile {
  name: string;
  extension?: string;
  size?: number;
  width?: number;
  height?: number;
  error?: true;
  url?: string;
  s3Url?: string;
  version: number;
}

export interface FieldFilter {
  eq?: JsonValue;
  ne?: JsonValue;
  exists?: boolean;
  notExists?: boolean;
  contains?: JsonValue;
  notContains?: JsonValue;
  le?: JsonValue;
  lt?: JsonValue;
  ge?: JsonValue;
  gt?: JsonValue;
  between?: JsonArray;
  in?: JsonArray;
  all?: JsonArray;
  any?: JsonArray;
  none?: JsonArray;
}

export type DocumentFilter = {
  [key: string]: FieldFilter;
};

export type FilterOperators = keyof FieldFilter;

export interface DocsFilter {
  label: string;
  property: string;
  operators: FilterOperators[];
  options?: Array<{ value: string; text: string } | Document>;
  textField?: (item: Document) => string;
  valueField?: string;
  validator?: (data: string) => boolean;
  upperCase?: true;
  isBoolean?: true;
}

export interface LocalizedDocument<T = string> {
  de: T;
  en: T;
  cs: T;
  da: T;
  es: T;
  fr: T;
  hu: T;
  it: T;
  nb: T;
  nl: T;
  pl: T;
  ru: T;
  sk: T;
  sv: T;
}

const supportedLocalizationsHelper: Record<keyof LocalizedDocument, true> = {
  de: true,
  en: true,
  cs: true,
  da: true,
  es: true,
  fr: true,
  hu: true,
  it: true,
  nb: true,
  nl: true,
  pl: true,
  ru: true,
  sk: true,
  sv: true
};

export const supportedLocalizations: Array<
  keyof LocalizedDocument
> = Object.keys(supportedLocalizationsHelper) as Array<keyof LocalizedDocument>;

export const minimalProperties: string = `
  _id
  _docType
  _changedAt
  _deleted
`;

export const defaultProperties: string = `
  _id
  _docType
  _changedAt
  _changedBy
  _createdAt
  _createdBy
  _lockedAt
  _deleted
`;
