import { Document, DocTypes, DocsFile, DocsFilter } from './document';
import { userStore, persistentDocumentsStore } from '../plugins/store';
import { I18n } from '@aws-amplify/core';
import type { AttributeType } from './attributeType';
import type { Cube } from './cube';
import type { Localization } from './localization';
import type { Profile } from './profile';
import type { Protocol } from './protocol';
import type { ProductType } from './productType';
import {
  FieldTypes,
  FileTypes,
  isValidJson,
  DetailData,
  ImageTypes,
  FileField,
  CodeEditorMode,
  formatJson,
  isValidSemver
} from '../typings/field';
import { Translations } from '../plugins/i18n';
import _ from 'lodash';
import type { UseCase } from './useCase';
import { homee } from '../typings/homee';
import Storage from '@aws-amplify/storage';
import type { MyDataTableHeader } from '../typings';
import type { Card } from '../typings/card';
import mime from 'mime-types';
import { getFormat, isValid } from 'gtin';
import {
  mdiCellphone,
  mdiCheckBold,
  mdiClockEnd,
  mdiCloseThick,
  mdiCloudCheck,
  mdiCloudLock,
  mdiCodeJson,
  mdiDotsHorizontalCircleOutline,
  mdiHomeMapMarker,
  mdiInformationOutline,
  mdiLock,
  mdiMapMarkerQuestion,
  mdiOfficeBuildingMarker,
  mdiOfficeBuildingMarkerOutline,
  mdiWeb
} from '@mdi/js';
import { caiCore, caiCube } from '../plugins/vuetify';
import { colors } from 'vuetify/lib';
import { getImageDetails, ListFilter, Rows } from '.';

export enum IntegrationState {
  TODO = 'TODO',
  DOING = 'DOING',
  DONE = 'DONE'
}

export enum ExportState {
  PRIVATE = 'PRIVATE',
  STAGING = 'STAGING',
  PUBLIC = 'PUBLIC'
}

export enum ProductLocation {
  OFFICE = 'OFFICE',
  TEAM = 'TEAM',
  PARTNER = 'PARTNER',
  CUSTOMER = 'CUSTOMER',
  MISSING = 'MISSING'
}

export interface Product extends Document {
  _docType: DocTypes.Product;
  manufacturer: string;
  productName: string;
  protocols: Protocol[];
  cubes: Cube[];
  profiles: Profile[];
  attributes: AttributeType[];
  learnDev: string;
  unlearnDev: string | null;
  resetDev: string | null;
  learnModeParameters: string | null;
  userInteractionParameters: string | null;
  zigbeeDeviceId: string | null;
  zigbeeProfileId: string | null;
  zigbeeAdditionalInfo: string | null;
  zwaveAlliance: string | null;
  ozwCfg: string | null;
  enoceanEep: string | null;
  nodeJson: string | null;
  displayName: Localization | null;
  productType: ProductType | null;
  processShort: Localization | null;
  processLong: Localization | null;
  preProcess: Localization[] | null;
  process: Localization[] | null;
  postProcess: Localization[] | null;
  integrationStateIos: IntegrationState | null;
  integrationStateAndroid: IntegrationState | null;
  integrationStateWeb: IntegrationState | null;
  exportWebsite: ExportState | null;
  exportApps: ExportState | null;
  compatibility: string | null;
  useCases: UseCase[] | null;
  asin: string | null;
  eanCodes: string[] | null;
  homeeWorldHandle: string | null;
  homeeShopHandle: string | null;
  image: DocsFile | null;
  additionalInfo: string | null;
  location: ProductLocation | null;
  locationDetail: string | null;
  manualLink: string | null;
  manuals: DocsFile[] | null;
}

export const defaultListProperties: string = `
  manufacturer
  productName
  protocols {
    _id
  }
  exportApps
  displayName {
    _id
  }
  productType {
    _id
  }
  integrationStateIos
  integrationStateAndroid
  integrationStateWeb
  exportWebsite
  image {
    name
    extension
    version
  }
`;

export const defaultDetailProperties: string = `
  manufacturer
  productName
  protocols {
    _id
  }
  cubes {
    _id
  }
  profiles {
    _id
  }
  attributes {
    _id
  }
  learnDev
  unlearnDev
  resetDev
  learnModeParameters
  userInteractionParameters
  zigbeeDeviceId
  zigbeeProfileId
  zigbeeAdditionalInfo
  zwaveAlliance
  ozwCfg
  enoceanEep
  nodeJson
  exportApps
  compatibility
  displayName {
    _id
  }
  productType {
    _id
  }
  processShort {
    _id
  }
  processLong {
    _id
  }
  preProcess {
    _id
  }
  process {
    _id
  }
  postProcess {
    _id
  }
  integrationStateIos
  integrationStateAndroid
  integrationStateWeb
  exportWebsite
  useCases {
    _id
  }
  asin
  eanCodes
  homeeWorldHandle
  homeeShopHandle
  image {
    name
    extension
    version
  }
  additionalInfo
  location
  locationDetail
  manualLink
  manuals {
    name
    extension
    version
  }
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.PRODUCT_IMAGE),
      value: 'image',
      align: 'center',
      sortable: false
    },
    {
      text: I18n.get(Translations.NAME),
      value: 'productTabelName',
      align: 'start',
      sortable: true
    },
    {
      text: '',
      value: '_changedAt',
      hidden: true,
      sortable: true
    },
    {
      text: I18n.get(Translations.IOS),
      value: 'integrationStateIos',
      align: 'center',
      sortable: true
    },
    {
      text: I18n.get(Translations.ANDROID),
      value: 'integrationStateAndroid',
      align: 'center',
      sortable: true
    },
    {
      text: I18n.get(Translations.WEB),
      value: 'integrationStateWeb',
      align: 'center',
      sortable: true
    },
    {
      text: I18n.get(Translations.EXPORT_WEBSITE),
      value: 'exportWebsite',
      align: 'center',
      sortable: true
    },
    {
      text: I18n.get(Translations.EXPORT_APPS),
      value: 'exportApps',
      align: 'center',
      sortable: true
    },
    {
      text: I18n.get(Translations.PROTOCOLS),
      value: 'protocols',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.PRODUCT_TYPE),
      value: 'productType',
      align: 'start',
      sortable: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: Product[] = Object.values(persistentDocumentsStore.Product);
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (document: Product): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      _changedAt: document._changedAt,
      image: document.image
        ? `https://${
            process.env.S3_BUCKET
          }/${document._docType.toLocaleLowerCase()}/${document._id}/${
            document.image.name
          }${
            document.image.extension ? `.${document.image.extension}` : ''
          }?version=${document.image.version}`
        : null,
      productTabelName: `${
        persistentDocumentsStore.Localization[
          document.displayName?._id ?? ''
        ]?.[userStore.locale] || ''
      } ${
        (!document.manufacturer ||
        (document.productName || '')
          .toUpperCase()
          .includes((document.manufacturer || '').toUpperCase())
          ? document.productName
          : `${document.manufacturer} ${document.productName || ''}`) || ''
      }`,
      productName:
        !document.manufacturer && !document.productName
          ? null
          : (!document.manufacturer ||
            (document.productName || '')
              .toUpperCase()
              .includes((document.manufacturer || '').toUpperCase())
              ? document.productName
              : `${document.manufacturer} ${document.productName || ''}`) || '',
      displayName:
        persistentDocumentsStore.Localization[
          document.displayName?._id ?? ''
        ]?.[userStore.locale],
      displayNameKey:
        persistentDocumentsStore.Localization[document.displayName?._id ?? '']
          ?.stringIdentifier,
      integrationStateIos: document.integrationStateIos,
      integrationStateAndroid: document.integrationStateAndroid,
      integrationStateWeb: document.integrationStateWeb,
      exportWebsite: document.exportWebsite,
      exportApps: document.exportApps,
      protocols: ([] as Protocol[])
        .concat(document.protocols || [])
        .reduce((protocols: string, protocol: Protocol): string => {
          const text: string | null =
            persistentDocumentsStore.Localization[
              persistentDocumentsStore.Protocol[protocol?._id ?? '']
                ?.displayName?._id ?? ''
            ]?.[userStore.locale];
          if (text) {
            protocols += `${protocols ? ', ' : ''}${text}`;
          }
          return protocols;
        }, ''),
      productType:
        persistentDocumentsStore.Localization[
          persistentDocumentsStore.ProductType[document.productType?._id ?? '']
            ?.displayName?._id ?? ''
        ]?.[userStore.locale]
    })
  );
}

export function getCards(listFilter?: ListFilter): Card[] {
  let documents: Product[] = Object.values(persistentDocumentsStore.Product);
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (document: Product): Card => ({
      id: document._id,
      changedAt: document._changedAt,
      title: document.displayName?._id
        ? persistentDocumentsStore.Localization[document.displayName._id]?.[
            userStore.locale
          ] ||
          persistentDocumentsStore.Localization[document.displayName._id]?.en ||
          document.productName
        : document.productName,
      ...(document.image
        ? {
            image: `https://${
              process.env.S3_BUCKET
            }/${document._docType.toLocaleLowerCase()}/${document._id}/${
              document.image.name
            }${
              document.image.extension ? `.${document.image.extension}` : ''
            }?version=${document.image.version}&width=300&height=200`
          }
        : {})
    })
  );
}

export const sortListBy: string | string[] = '_changedAt';

export const defaults: Partial<Product> = {
  exportWebsite: ExportState.PRIVATE,
  exportApps: ExportState.PRIVATE,
  integrationStateIos: IntegrationState.TODO,
  integrationStateAndroid: IntegrationState.TODO,
  integrationStateWeb: IntegrationState.TODO,
  location: ProductLocation.OFFICE
};

export async function getCategories(
  document: Partial<Product>
): Promise<DetailData> {
  const name: string =
    persistentDocumentsStore.Localization[
      document?.displayName?._id ||
        ((document?.displayName as unknown) as string)
    ]?.[userStore.locale] ||
    document.productName ||
    '';
  const title: string =
    (Array.isArray(document.manufacturer)
      ? document.manufacturer[0]
      : document.manufacturer) || name;
  const subtitle: string = name.replace(new RegExp(title, 'i'), '').trim();
  const image: DocsFile | null =
    document.image && document._id
      ? !(document.image?.url ?? '').startsWith('blob:')
        ? await getImageDetails(document._id, DocTypes.Product, document.image)
        : document.image
      : null;
  const manualsField: FileField = {
    type: FieldTypes.FILE,
    label: I18n.get(Translations.PRODUCT_MANUALS),
    required: false,
    model: 'manuals',
    value: document.manuals
      ? await Promise.all(
          document.manuals.map(
            (manual: DocsFile): Promise<DocsFile> => {
              if (manual.url && manual.url.startsWith('blob:')) {
                return Promise.resolve(manual);
              }
              if (!document._id) {
                return Promise.reject();
              }
              return Storage.get(
                `${DocTypes.Product.toLocaleLowerCase()}/${document._id}/${
                  manual.name
                }${manual.extension ? `.${manual.extension}` : ''}`,
                { level: 'public' }
              ).then(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (url: Record<string, any> | string): DocsFile => ({
                  ...manual,
                  url: url as string
                })
              );
            }
          )
        )
      : null,
    fileType: FileTypes.DOCUMENT,
    multiple: true,
    rules: [
      (value: DocsFile): boolean | string =>
        !value ||
        !value.size ||
        value.size < 5000000 ||
        I18n.get(Translations.WRONG_FILESIZE)
    ]
  };
  const hideZigbeeFields: boolean = ([] as Protocol[])
    .concat(document.protocols || [])
    .every(
      (protocol: Protocol | string): boolean =>
        (
          persistentDocumentsStore.Protocol[
            (!protocol || typeof protocol !== 'object'
              ? protocol
              : protocol?._id) ?? ''
          ] || {}
        ).number !== homee.CANodeProtocol.ZigBee
    );
  const hideZwaveFields: boolean = ([] as Protocol[])
    .concat(document.protocols || [])
    .every(
      (protocol: Protocol | string): boolean =>
        ![homee.CANodeProtocol.ZWave, homee.CANodeProtocol.SigmaZWave].includes(
          (
            persistentDocumentsStore.Protocol[
              (!protocol || typeof protocol !== 'object'
                ? protocol
                : protocol?._id) ?? ''
            ] || {}
          ).number
        )
    );
  const hideEnoceanFields: boolean = ([] as Protocol[])
    .concat(document.protocols || [])
    .every(
      (protocol: Protocol | string): boolean =>
        (
          persistentDocumentsStore.Protocol[
            (!protocol || typeof protocol !== 'object'
              ? protocol
              : protocol?._id) ?? ''
          ] || {}
        ).number !== homee.CANodeProtocol.EnOcean
    );
  const uncategorizedAttributes: AttributeType[] = ((): AttributeType[] => {
    const attributeMap: Record<string, AttributeType> = (
      document.attributes || []
    ).reduce(
      (
        attributeMap: Record<string, AttributeType>,
        item: Document
      ): Record<string, AttributeType> => {
        const id: string = !item || typeof item !== 'object' ? item : item._id;
        attributeMap[id] = persistentDocumentsStore.AttributeType[id];
        return attributeMap;
      },
      {}
    );
    for (const item of document.profiles || []) {
      const profile: Profile =
        persistentDocumentsStore.Profile[
          !item || typeof item !== 'object' ? item : item._id
        ] || {};
      const profileAttributes: AttributeType[] = [
        ...(profile.primaryAttributes || []),
        ...(profile.secondaryAttributes || []),
        ...(profile.tertiaryAttributes || [])
      ];
      for (const profileAttribute of profileAttributes) {
        delete attributeMap[profileAttribute._id];
      }
    }
    return Object.values(attributeMap).sort(
      (item1: AttributeType, item2: AttributeType): number =>
        item1.number - item2.number
    );
  })();
  return {
    title,
    subtitle,
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        icon: mdiInformationOutline,
        fields: [
          {
            type: FieldTypes.IMAGE,
            label: I18n.get(Translations.PRODUCT_IMAGE),
            required: false,
            model: 'image',
            hideInViewMode: true,
            hideInEditMode: true,
            value: image,
            imageType: ImageTypes.IMAGE,
            multiple: false,
            hint: (value: DocsFile): string | null => {
              if (
                value &&
                value.width &&
                value.height &&
                value.width * value.height < 12000000
              ) {
                return I18n.get(Translations.WRONG_RESOLUTION);
              } else if (
                value &&
                value.extension &&
                value.extension !== mime.extension('image/tiff')
              ) {
                return I18n.get(Translations.WRONG_FILETYPE);
              }
              return null;
            }
          },
          { ...manualsField, hideInViewMode: true, hideInEditMode: true },
          {
            type: FieldTypes.ARRAY,
            label: I18n.get(Translations.MANUFACTURER),
            required: true,
            model: 'manufacturer',
            value: document.manufacturer,
            options: _.uniq(
              Object.values(persistentDocumentsStore.Product).map(
                (prod: Product): string => prod.manufacturer
              )
            ).sort(),
            multiple: false,
            delimiters: []
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.PRODUCT_NAME),
            required: true,
            model: 'productName',
            value: document.productName
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PROTOCOLS),
            required: true,
            model: 'protocols',
            value: document.protocols,
            docType: DocTypes.Protocol,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.Protocol[item._id]?.displayName?._id ??
                  ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: Protocol = persistentDocumentsStore.Protocol[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.Protocol).sort(
              (item1: Protocol, item2: Protocol): number =>
                item1.number - item2.number
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.CUBES),
            required: true,
            model: 'cubes',
            value: document.cubes,
            docType: DocTypes.Cube,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.Cube[item._id]?.displayName?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: Cube = persistentDocumentsStore.Cube[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            iconField: (item: Document): string =>
              `data:image/svg+xml;base64,${btoa(
                `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${
                  persistentDocumentsStore.Cube[item?._id]?.color || '#FFF'
                }" d="${caiCube}"></path></svg>`
              )}`,
            valueField: '_id',
            options: ([] as Protocol[])
              .concat(document.protocols || [])
              .reduce((all: Cube[], protocol: Protocol | string): Cube[] => {
                const cubes: Cube[] =
                  (
                    persistentDocumentsStore.Protocol[
                      (!protocol || typeof protocol !== 'object'
                        ? protocol
                        : protocol?._id) ?? ''
                    ] || {}
                  ).cubes || [];
                all.push(...cubes);
                return all;
              }, []),
            multiple: true
          },
          {
            type: FieldTypes.SELECT,
            label: I18n.get(Translations.PRODUCT_LOCATION),
            required: false,
            model: 'location',
            value: document.location,
            multiple: false,
            iconField: (state: string): string => {
              switch (state) {
                case ProductLocation.OFFICE:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#8e8eff" d="${mdiOfficeBuildingMarker}"></path></svg>`
                  )}`;
                case ProductLocation.TEAM:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#FFF" d="${mdiHomeMapMarker}"></path></svg>`
                  )}`;
                case ProductLocation.PARTNER:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.grey.base}" d="${mdiOfficeBuildingMarkerOutline}"></path></svg>`
                  )}`;
                case ProductLocation.CUSTOMER:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.yellow.base}" d="${mdiClockEnd}"></path></svg>`
                  )}`;
                case ProductLocation.MISSING:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.red.base}" d="${mdiMapMarkerQuestion}"></path></svg>`
                  )}`;
                default:
                  return '';
              }
            },
            options: [
              {
                text: I18n.get(Translations.OFFICE),
                value: ProductLocation.OFFICE
              },
              {
                text: I18n.get(Translations.TEAM),
                value: ProductLocation.TEAM
              },
              {
                text: I18n.get(Translations.PARTNER),
                value: ProductLocation.PARTNER
              },
              {
                text: I18n.get(Translations.CUSTOMER),
                value: ProductLocation.CUSTOMER
              },
              {
                text: I18n.get(Translations.MISSING),
                value: ProductLocation.MISSING
              }
            ]
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.LOCATION_DETAIL),
            required: false,
            model: 'locationDetail',
            value: document.locationDetail
          }
        ]
      },
      {
        name: I18n.get(Translations.CORE),
        icon: caiCore,
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PROFILES),
            hint: (value: Profile[]): string | null =>
              value &&
              (!Array.isArray(value) || value.length) &&
              uncategorizedAttributes.length
                ? I18n.get(Translations.WARN_UNCATEGORIZED)
                : null,
            required: true,
            model: 'profiles',
            value: document.profiles,
            docType: DocTypes.Profile,
            textField: (item: Document): string => {
              const doc: Profile = persistentDocumentsStore.Profile[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.Profile).sort(
              (item1: Profile, item2: Profile): number =>
                item1.number - item2.number
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTETYPES),
            required: true,
            hideInViewMode: true,
            model: 'attributes',
            value: document.attributes,
            docType: DocTypes.AttributeType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.AttributeType[item._id]?.displayName
                  ?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: AttributeType =
                persistentDocumentsStore.AttributeType[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.AttributeType).sort(
              (item1: AttributeType, item2: AttributeType): number =>
                item1.number - item2.number
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTETYPES_UNCATEGORIZED),
            infoText: I18n.get(Translations.HELPER_UNCATEGORIZED_ATTRIBUTES),
            required: false,
            hideInEditMode: true,
            value: uncategorizedAttributes,
            docType: DocTypes.AttributeType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.AttributeType[item._id]?.displayName
                  ?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: AttributeType =
                persistentDocumentsStore.AttributeType[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: document.attributes || [],
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTETYPES_PRIMARY),
            infoText: I18n.get(Translations.HELPER_PRIMARY_ATTRIBUTES),
            required: false,
            hideInEditMode: true,
            value: ((): AttributeType[] => {
              const productAttributeIDs: string[] = (
                document.attributes || []
              ).map((item: Document): string =>
                !item || typeof item !== 'object' ? item : item._id
              );
              const attributeMap: Record<string, AttributeType> = {};
              for (const item of document.profiles || []) {
                const profile: Profile =
                  persistentDocumentsStore.Profile[
                    !item || typeof item !== 'object' ? item : item._id
                  ] || {};
                for (const profileAttribute of profile.primaryAttributes ||
                  []) {
                  if (productAttributeIDs.includes(profileAttribute._id)) {
                    attributeMap[profileAttribute._id] =
                      persistentDocumentsStore.AttributeType[
                        profileAttribute._id
                      ];
                  }
                }
              }
              return Object.values(attributeMap).sort(
                (item1: AttributeType, item2: AttributeType): number =>
                  item1.number - item2.number
              );
            })(),
            docType: DocTypes.AttributeType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.AttributeType[item._id]?.displayName
                  ?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: AttributeType =
                persistentDocumentsStore.AttributeType[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: document.attributes || [],
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTETYPES_SECONDARY),
            infoText: I18n.get(Translations.HELPER_SECONDARY_ATTRIBUTES),
            required: false,
            hideInEditMode: true,
            value: ((): AttributeType[] => {
              const productAttributeIDs: string[] = (
                document.attributes || []
              ).map((item: Document): string =>
                !item || typeof item !== 'object' ? item : item._id
              );
              const attributeMap: Record<string, AttributeType> = {};
              for (const item of document.profiles || []) {
                const profile: Profile =
                  persistentDocumentsStore.Profile[
                    !item || typeof item !== 'object' ? item : item._id
                  ] || {};
                for (const profileAttribute of profile.secondaryAttributes ||
                  []) {
                  if (productAttributeIDs.includes(profileAttribute._id)) {
                    attributeMap[profileAttribute._id] =
                      persistentDocumentsStore.AttributeType[
                        profileAttribute._id
                      ];
                  }
                }
              }
              return Object.values(attributeMap).sort(
                (item1: AttributeType, item2: AttributeType): number =>
                  item1.number - item2.number
              );
            })(),
            docType: DocTypes.AttributeType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.AttributeType[item._id]?.displayName
                  ?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: AttributeType =
                persistentDocumentsStore.AttributeType[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: document.attributes || [],
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTETYPES_TERTIARY),
            infoText: I18n.get(Translations.HELPER_TERTIARY_ATTRIBUTES),
            required: false,
            hideInEditMode: true,
            value: ((): AttributeType[] => {
              const productAttributeIDs: string[] = (
                document.attributes || []
              ).map((item: Document): string =>
                !item || typeof item !== 'object' ? item : item._id
              );
              const attributeMap: Record<string, AttributeType> = {};
              for (const item of document.profiles || []) {
                const profile: Profile =
                  persistentDocumentsStore.Profile[
                    !item || typeof item !== 'object' ? item : item._id
                  ] || {};
                for (const profileAttribute of profile.tertiaryAttributes ||
                  []) {
                  if (productAttributeIDs.includes(profileAttribute._id)) {
                    attributeMap[profileAttribute._id] =
                      persistentDocumentsStore.AttributeType[
                        profileAttribute._id
                      ];
                  }
                }
              }
              return Object.values(attributeMap).sort(
                (item1: AttributeType, item2: AttributeType): number =>
                  item1.number - item2.number
              );
            })(),
            docType: DocTypes.AttributeType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.AttributeType[item._id]?.displayName
                  ?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: AttributeType =
                persistentDocumentsStore.AttributeType[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: document.attributes || [],
            multiple: true
          },
          {
            type: FieldTypes.CODE,
            label: I18n.get(Translations.LEARN_DEV),
            infoText: I18n.get(Translations.HELPER_LEARN),
            required: true,
            model: 'learnDev',
            value: document.learnDev,
            mode: CodeEditorMode.MARKDOWN
          },
          {
            type: FieldTypes.CODE,
            label: I18n.get(Translations.UNLEARN_DEV),
            required: false,
            model: 'unlearnDev',
            value: document.unlearnDev,
            mode: CodeEditorMode.MARKDOWN
          },
          {
            type: FieldTypes.CODE,
            label: I18n.get(Translations.RESET_DEV),
            required: false,
            model: 'resetDev',
            value: document.resetDev,
            mode: CodeEditorMode.MARKDOWN
          },
          {
            type: FieldTypes.CODE,
            label: I18n.get(Translations.LEARN_MODE_PARAMETERS),
            required: false,
            model: 'learnModeParameters',
            value: formatJson(document.learnModeParameters),
            mode: CodeEditorMode.JSON,
            rules: [isValidJson]
          },
          {
            type: FieldTypes.CODE,
            label: I18n.get(Translations.USER_INTERACTION_PARAMETERS),
            required: false,
            model: 'userInteractionParameters',
            value: formatJson(document.userInteractionParameters),
            mode: CodeEditorMode.JSON,
            rules: [isValidJson]
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.ZIGBEE_DEVICE_ID),
            required: false,
            hideInEditMode: hideZigbeeFields,
            hideInViewMode: hideZigbeeFields,
            model: 'zigbeeDeviceId',
            value: document.zigbeeDeviceId
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.ZIGBEE_PROFILE_ID),
            required: false,
            hideInEditMode: hideZigbeeFields,
            hideInViewMode: hideZigbeeFields,
            model: 'zigbeeProfileId',
            value: document.zigbeeProfileId
          },
          {
            type: FieldTypes.MULTILINE,
            label: I18n.get(Translations.ZIGBEE_ADDITIONAL_INFO),
            required: false,
            hideInEditMode: hideZigbeeFields,
            hideInViewMode: hideZigbeeFields,
            model: 'zigbeeAdditionalInfo',
            value: document.zigbeeAdditionalInfo
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.ZWAVE_ALLIANCE_LINK),
            required: false,
            hideInEditMode: hideZwaveFields,
            hideInViewMode: hideZwaveFields,
            model: 'zwaveAlliance',
            value: document.zwaveAlliance,
            isLink: true
          },
          {
            type: FieldTypes.CODE,
            label: I18n.get(Translations.OZW_CONFIG),
            required: false,
            hideInEditMode: hideZwaveFields,
            hideInViewMode: hideZwaveFields,
            model: 'ozwCfg',
            value: document.ozwCfg,
            mode: CodeEditorMode.XML
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.ENOCEAN_EEP),
            required: false,
            hideInEditMode: hideEnoceanFields,
            hideInViewMode: hideEnoceanFields,
            model: 'enoceanEep',
            value: document.enoceanEep
          },
          {
            type: FieldTypes.CODE,
            label: I18n.get(Translations.NODE_JSON),
            required: false,
            model: 'nodeJson',
            value: formatJson(document.nodeJson),
            mode: CodeEditorMode.JSON,
            rules: [isValidJson]
          }
        ]
      },
      {
        name: I18n.get(Translations.APPS),
        icon: mdiCellphone,
        fields: [
          {
            type: FieldTypes.SELECT,
            label: I18n.get(Translations.EXPORT_STATE),
            required: false,
            model: 'exportApps',
            value: document.exportApps,
            multiple: false,
            iconField: (state: string): string => {
              switch (state) {
                case ExportState.PUBLIC:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.green.base}" d="${mdiCloudCheck}"></path></svg>`
                  )}`;
                case ExportState.STAGING:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#FFF" d="${mdiCloudLock}"></path></svg>`
                  )}`;
                case ExportState.PRIVATE:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.red.base}" d="${mdiLock}"></path></svg>`
                  )}`;
                default:
                  return '';
              }
            },
            options: [
              {
                text: I18n.get(Translations.NO_EXPORT),
                value: ExportState.PRIVATE
              },
              {
                text: I18n.get(Translations.INVISIBLE_EXPORT),
                value: ExportState.STAGING
              },
              {
                text: I18n.get(Translations.VISIBLE_EXPORT),
                value: ExportState.PUBLIC
              }
            ]
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.COMPATIBILITY),
            required: false,
            model: 'compatibility',
            value: document.compatibility,
            rules: [isValidSemver]
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.DISPLAY_NAME),
            required: false,
            model: 'displayName',
            value: document.displayName,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_NAME')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PRODUCT_TYPE),
            required: false,
            model: 'productType',
            value: document.productType,
            docType: DocTypes.ProductType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.ProductType[item._id]?.displayName?._id
              ]?.[userStore.locale] || 'unknown',
            lightupIcons: true,
            iconField: (item: Document): string => {
              const iconId: string =
                persistentDocumentsStore.ProductType[item._id]?.productIcon
                  ?._id;
              const icon: DocsFile =
                persistentDocumentsStore.ProductIcon[iconId]?.icon;
              if (!icon) {
                return '';
              }
              return `https://${
                process.env.S3_BUCKET
              }/${DocTypes.ProductIcon.toLocaleLowerCase()}/${iconId}/${
                icon.name
              }${icon.extension ? `.${icon.extension}` : ''}?version=${
                icon.version
              }`;
            },
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.ProductType),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PRE_PROCESS_TEXT),
            required: false,
            model: 'preProcess',
            value: document.preProcess,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
                loc.stringIdentifier.toUpperCase().includes('_PREPROCESS_')
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PROCESS_TEXT),
            required: false,
            model: 'process',
            value: document.process,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
                loc.stringIdentifier.toUpperCase().includes('_PROCESS_')
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.POST_PROCESS_TEXT),
            required: false,
            model: 'postProcess',
            value: document.postProcess,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
                loc.stringIdentifier.toUpperCase().includes('_POSTPROCESS_')
            ),
            multiple: true
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PROCESS_TEXT_SHORT),
            required: false,
            model: 'processShort',
            value: document.processShort,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
                loc.stringIdentifier.toUpperCase().includes('_PRESSMODE_')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.PROCESS_TEXT_LONG),
            required: false,
            model: 'processLong',
            value: document.processLong,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_PROCESS')
            ),
            multiple: false
          },
          {
            type: FieldTypes.SELECT,
            label: `${I18n.get(Translations.INTEGRATION_STATE)} ${I18n.get(
              Translations.IOS
            )}`,
            required: false,
            model: 'integrationStateIos',
            value: document.integrationStateIos,
            multiple: false,
            iconField: (state: string): string => {
              switch (state) {
                case IntegrationState.TODO:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.red.base}" d="${mdiCloseThick}"></path></svg>`
                  )}`;
                case IntegrationState.DOING:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#FFF" d="${mdiCodeJson}"></path></svg>`
                  )}`;
                case IntegrationState.DONE:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.green.base}" d="${mdiCheckBold}"></path></svg>`
                  )}`;
                default:
                  return '';
              }
            },
            options: [
              {
                text: I18n.get(Translations.TODO),
                value: IntegrationState.TODO
              },
              {
                text: I18n.get(Translations.IN_DEVELOPMENT),
                value: IntegrationState.DOING
              },
              {
                text: I18n.get(Translations.DONE),
                value: IntegrationState.DONE
              }
            ]
          },
          {
            type: FieldTypes.SELECT,
            label: `${I18n.get(Translations.INTEGRATION_STATE)} ${I18n.get(
              Translations.ANDROID
            )}`,
            required: false,
            model: 'integrationStateAndroid',
            value: document.integrationStateAndroid,
            multiple: false,
            iconField: (state: string): string => {
              switch (state) {
                case IntegrationState.TODO:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.red.base}" d="${mdiCloseThick}"></path></svg>`
                  )}`;
                case IntegrationState.DOING:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#FFF" d="${mdiCodeJson}"></path></svg>`
                  )}`;
                case IntegrationState.DONE:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.green.base}" d="${mdiCheckBold}"></path></svg>`
                  )}`;
                default:
                  return '';
              }
            },
            options: [
              {
                text: I18n.get(Translations.TODO),
                value: IntegrationState.TODO
              },
              {
                text: I18n.get(Translations.IN_DEVELOPMENT),
                value: IntegrationState.DOING
              },
              {
                text: I18n.get(Translations.DONE),
                value: IntegrationState.DONE
              }
            ]
          },
          {
            type: FieldTypes.SELECT,
            label: `${I18n.get(Translations.INTEGRATION_STATE)} ${I18n.get(
              Translations.WEB
            )}`,
            required: false,
            model: 'integrationStateWeb',
            value: document.integrationStateWeb,
            multiple: false,
            iconField: (state: string): string => {
              switch (state) {
                case IntegrationState.TODO:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.red.base}" d="${mdiCloseThick}"></path></svg>`
                  )}`;
                case IntegrationState.DOING:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#FFF" d="${mdiCodeJson}"></path></svg>`
                  )}`;
                case IntegrationState.DONE:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.green.base}" d="${mdiCheckBold}"></path></svg>`
                  )}`;
                default:
                  return '';
              }
            },
            options: [
              {
                text: I18n.get(Translations.TODO),
                value: IntegrationState.TODO
              },
              {
                text: I18n.get(Translations.IN_DEVELOPMENT),
                value: IntegrationState.DOING
              },
              {
                text: I18n.get(Translations.DONE),
                value: IntegrationState.DONE
              }
            ]
          }
        ]
      },
      {
        name: I18n.get(Translations.WEBSITE),
        icon: mdiWeb,
        fields: [
          {
            type: FieldTypes.SELECT,
            label: I18n.get(Translations.EXPORT_STATE),
            required: false,
            model: 'exportWebsite',
            value: document.exportWebsite,
            multiple: false,
            iconField: (state: string): string => {
              switch (state) {
                case ExportState.PUBLIC:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.green.base}" d="${mdiCloudCheck}"></path></svg>`
                  )}`;
                case ExportState.STAGING:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#FFF" d="${mdiCloudLock}"></path></svg>`
                  )}`;
                case ExportState.PRIVATE:
                  return `data:image/svg+xml;base64,${btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="${colors.red.base}" d="${mdiLock}"></path></svg>`
                  )}`;
                default:
                  return '';
              }
            },
            options: [
              {
                text: I18n.get(Translations.PRIVATE),
                value: ExportState.PRIVATE
              },
              {
                text: I18n.get(Translations.STAGING),
                value: ExportState.STAGING
              },
              {
                text: I18n.get(Translations.PUBLIC),
                value: ExportState.PUBLIC
              }
            ]
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.USECASES),
            required: false,
            model: 'useCases',
            value: document.useCases,
            docType: DocTypes.UseCase,
            textField: (item: Document): string =>
              persistentDocumentsStore.UseCase[item._id]?.[userStore.locale] ||
              'unknown',
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.UseCase),
            multiple: true
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.ASIN),
            infoText: I18n.get(Translations.HELPER_ASIN),
            required: false,
            model: 'asin',
            value: document.asin
          },
          {
            type: FieldTypes.ARRAY,
            label: I18n.get(Translations.EAN_CODES),
            infoText: I18n.get(Translations.HELPER_GTIN),
            required: false,
            model: 'eanCodes',
            value: document.eanCodes,
            multiple: true,
            delimiters: [';', ','],
            validator: (data: string): boolean => /\d+|[n/a]+/.test(data),
            rules: [
              (value: string | string[] | null): boolean | string => {
                if (value?.length === 1 && value[0] === 'n/a') {
                  return true;
                }
                if (
                  ([] as string[])
                    .concat(value || [])
                    .every((code: string): boolean => {
                      try {
                        if (isValid(code) && getFormat(code) !== 'GTIN-14') {
                          return true;
                        }
                      } catch (e) {
                        //
                      }
                      return false;
                    })
                ) {
                  return true;
                }
                return I18n.get(Translations.INVALID_GTIN);
              }
            ],
            transform: (data: string): string =>
              (data || '').replaceAll(/[^\d]/g, '')
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.HOMEE_WORLD_HANDLE),
            required: false,
            model: 'homeeWorldHandle',
            value: document.homeeWorldHandle
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.HOMEE_SHOP_HANDLE),
            required: false,
            model: 'homeeShopHandle',
            value: document.homeeShopHandle
          }
        ]
      },
      {
        name: I18n.get(Translations.MISCELLANEOUS),
        icon: mdiDotsHorizontalCircleOutline,
        fields: [
          manualsField,
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.MANUAL_LINK),
            required: false,
            model: 'manualLink',
            value: document.manualLink,
            isLink: true
          },
          {
            type: FieldTypes.MULTILINE,
            label: I18n.get(Translations.ADDITIONAL_INFO),
            required: false,
            model: 'additionalInfo',
            value: document.additionalInfo
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      label: I18n.get(Translations.MANUFACTURER),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'manufacturer',
      options: _.uniq(
        Object.values(persistentDocumentsStore.Product).map(
          (prod: Product): string => prod.manufacturer
        )
      )
        .sort((item1: string, item2: string): number =>
          item1.localeCompare(item2, undefined, { sensitivity: 'base' })
        )
        .map((item: string): { value: string; text: string } => ({
          value: item,
          text: item
        }))
    },
    {
      label: I18n.get(Translations.PRODUCT_NAME),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'productName'
    },
    {
      label: I18n.get(Translations.PROTOCOLS),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'protocols',
      textField: (item: Document): string => {
        const doc: Protocol = persistentDocumentsStore.Protocol[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Protocol).sort(
        (item1: Protocol, item2: Protocol): number =>
          item1.number - item2.number
      )
    },
    {
      label: I18n.get(Translations.CUBES),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'cubes',
      textField: (item: Document): string => {
        const doc: Cube = persistentDocumentsStore.Cube[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Cube).sort(
        (item1: Cube, item2: Cube): number => item1.number - item2.number
      )
    },
    {
      label: I18n.get(Translations.PROFILES),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'profiles',
      textField: (item: Document): string => {
        const doc: Profile = persistentDocumentsStore.Profile[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Profile).sort(
        (item1: Profile, item2: Profile): number => item1.number - item2.number
      )
    },
    {
      label: I18n.get(Translations.ATTRIBUTETYPES),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'attributes',
      textField: (item: Document): string => {
        const doc: AttributeType =
          persistentDocumentsStore.AttributeType[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.AttributeType).sort(
        (item1: AttributeType, item2: AttributeType): number =>
          item1.number - item2.number
      )
    },
    {
      label: I18n.get(Translations.LEARN_DEV),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'learnDev'
    },
    {
      label: I18n.get(Translations.UNLEARN_DEV),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'unlearnDev'
    },
    {
      label: I18n.get(Translations.RESET_DEV),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'resetDev'
    },
    {
      label: I18n.get(Translations.LEARN_MODE_PARAMETERS),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'learnModeParameters'
    },
    {
      label: I18n.get(Translations.USER_INTERACTION_PARAMETERS),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'userInteractionParameters'
    },
    {
      label: I18n.get(Translations.ZIGBEE_DEVICE_ID),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'zigbeeDeviceId'
    },
    {
      label: I18n.get(Translations.ZIGBEE_PROFILE_ID),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'zigbeeProfileId'
    },
    {
      label: I18n.get(Translations.ZIGBEE_ADDITIONAL_INFO),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'zigbeeAdditionalInfo'
    },
    {
      label: I18n.get(Translations.ZWAVE_ALLIANCE_LINK),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'zwaveAlliance'
    },
    {
      label: I18n.get(Translations.OZW_CONFIG),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'ozwCfg'
    },
    {
      label: I18n.get(Translations.ENOCEAN_EEP),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'enoceanEep'
    },
    {
      label: I18n.get(Translations.NODE_JSON),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'nodeJson'
    },
    {
      label: I18n.get(Translations.EXPORT_APPS),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'exportApps',
      options: [
        {
          text: I18n.get(Translations.NO_EXPORT),
          value: ExportState.PRIVATE
        },
        {
          text: I18n.get(Translations.INVISIBLE_EXPORT),
          value: ExportState.STAGING
        },
        {
          text: I18n.get(Translations.VISIBLE_EXPORT),
          value: ExportState.PUBLIC
        }
      ]
    },
    {
      label: I18n.get(Translations.COMPATIBILITY),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'compatibility'
    },
    {
      label: I18n.get(Translations.DISPLAY_NAME),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'displayName',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_NAME')
      )
    },
    {
      label: I18n.get(Translations.PRODUCT_TYPE),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'productType',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[
          persistentDocumentsStore.ProductType[item._id]?.displayName?._id
        ]?.[userStore.locale] || 'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.ProductType)
    },
    {
      label: I18n.get(Translations.PROCESS_TEXT_SHORT),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'processShort',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
          loc.stringIdentifier.toUpperCase().includes('_PRESSMODE_')
      )
    },
    {
      label: I18n.get(Translations.PROCESS_TEXT_LONG),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'processLong',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_PROCESS')
      )
    },
    {
      label: I18n.get(Translations.PRE_PROCESS_TEXT),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'preProcess',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
          loc.stringIdentifier.toUpperCase().includes('_PREPROCESS_')
      )
    },
    {
      label: I18n.get(Translations.PROCESS_TEXT),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'process',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
          loc.stringIdentifier.toUpperCase().includes('_PROCESS_')
      )
    },
    {
      label: I18n.get(Translations.POST_PROCESS_TEXT),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'postProcess',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('DEVICES_') &&
          loc.stringIdentifier.toUpperCase().includes('_POSTPROCESS_')
      )
    },
    {
      label: `${I18n.get(Translations.INTEGRATION_STATE)} ${I18n.get(
        Translations.IOS
      )}`,
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'integrationStateIos',
      options: [
        {
          text: I18n.get(Translations.TODO),
          value: IntegrationState.TODO
        },
        {
          text: I18n.get(Translations.IN_DEVELOPMENT),
          value: IntegrationState.DOING
        },
        {
          text: I18n.get(Translations.DONE),
          value: IntegrationState.DONE
        }
      ]
    },
    {
      label: `${I18n.get(Translations.INTEGRATION_STATE)} ${I18n.get(
        Translations.ANDROID
      )}`,
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'integrationStateAndroid',
      options: [
        {
          text: I18n.get(Translations.TODO),
          value: IntegrationState.TODO
        },
        {
          text: I18n.get(Translations.IN_DEVELOPMENT),
          value: IntegrationState.DOING
        },
        {
          text: I18n.get(Translations.DONE),
          value: IntegrationState.DONE
        }
      ]
    },
    {
      label: `${I18n.get(Translations.INTEGRATION_STATE)} ${I18n.get(
        Translations.WEB
      )}`,
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'integrationStateWeb',
      options: [
        {
          text: I18n.get(Translations.TODO),
          value: IntegrationState.TODO
        },
        {
          text: I18n.get(Translations.IN_DEVELOPMENT),
          value: IntegrationState.DOING
        },
        {
          text: I18n.get(Translations.DONE),
          value: IntegrationState.DONE
        }
      ]
    },
    {
      label: I18n.get(Translations.EXPORT_WEBSITE),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'exportWebsite',
      options: [
        {
          text: I18n.get(Translations.PRIVATE),
          value: ExportState.PRIVATE
        },
        {
          text: I18n.get(Translations.STAGING),
          value: ExportState.STAGING
        },
        {
          text: I18n.get(Translations.PUBLIC),
          value: ExportState.PUBLIC
        }
      ]
    },
    {
      label: I18n.get(Translations.USECASES),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'useCases',
      textField: (item: Document): string =>
        persistentDocumentsStore.UseCase[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.UseCase)
    },
    {
      label: I18n.get(Translations.ASIN),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'asin'
    },
    {
      label: I18n.get(Translations.EAN_CODES),
      operators: ['all', 'any', 'none', 'exists', 'notExists'],
      property: 'eanCodes'
    },
    {
      label: I18n.get(Translations.HOMEE_WORLD_HANDLE),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'homeeWorldHandle'
    },
    {
      label: I18n.get(Translations.HOMEE_SHOP_HANDLE),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'homeeShopHandle'
    },
    {
      label: I18n.get(Translations.PRODUCT_IMAGE),
      operators: ['exists', 'notExists'],
      property: 'image'
    },
    {
      label: I18n.get(Translations.ADDITIONAL_INFO),
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      property: 'additionalInfo'
    },
    {
      label: I18n.get(Translations.PRODUCT_LOCATION),
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      property: 'location',
      options: [
        {
          text: I18n.get(Translations.OFFICE),
          value: ProductLocation.OFFICE
        },
        {
          text: I18n.get(Translations.TEAM),
          value: ProductLocation.TEAM
        },
        {
          text: I18n.get(Translations.PARTNER),
          value: ProductLocation.PARTNER
        },
        {
          text: I18n.get(Translations.CUSTOMER),
          value: ProductLocation.CUSTOMER
        },
        {
          text: I18n.get(Translations.MISSING),
          value: ProductLocation.MISSING
        }
      ]
    },
    {
      label: I18n.get(Translations.LOCATION_DETAIL),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'locationDetail'
    },
    {
      label: I18n.get(Translations.MANUAL_LINK),
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      property: 'manualLink'
    },
    {
      label: I18n.get(Translations.PRODUCT_MANUALS),
      operators: ['exists', 'notExists'],
      property: 'manuals'
    }
  ];
}
