import {
  Document,
  DocTypes,
  LocalizedDocument,
  supportedLocalizations,
  DocsFilter
} from './document';
import { Translations } from '../plugins/i18n';
import {
  FieldTypes,
  ObjectField,
  isValidJson,
  DetailData
} from '../typings/field';
import { I18n } from '@aws-amplify/core';
import type { JsonObject } from 'type-fest';
import type { MyDataTableHeader } from '../typings';
import { persistentDocumentsStore, userStore } from '../plugins/store';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export enum Brand {
  homee = 'homee',
  AFRISOhome = 'AFRISOhome',
  VARIA3 = 'VARIA3'
}

export const supportedBrands: Brand[] = Object.values(Brand);

type EnumKeyFields = { [brand in Brand]: LocalizedDocument };

export interface Placeholder extends Document, EnumKeyFields {
  _docType: DocTypes.Placeholder;
  stringIdentifier: string;
  note?: string | null;
}

export const defaultListProperties: string = `
  stringIdentifier
  ${supportedBrands.map(
    (brand: string): string => `${brand} {
      ${supportedLocalizations.join('\n')}
    }`
  )}
`;

export const defaultDetailProperties: string = `
  stringIdentifier
  note
  ${supportedBrands.map(
    (brand: string): string => `${brand} {
      ${supportedLocalizations.join('\n')}
    }`
  )}
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.STRING_IDENTIFIER),
      value: 'stringIdentifier',
      align: 'start',
      sortable: true
    },
    {
      text: Brand.homee,
      value: Brand.homee,
      align: 'start',
      sortable: true
    },
    {
      text: Brand.AFRISOhome,
      value: Brand.AFRISOhome,
      align: 'start',
      sortable: true
    },
    {
      text: Brand.VARIA3,
      value: Brand.VARIA3,
      align: 'start',
      sortable: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: Placeholder[] = Object.values(
    persistentDocumentsStore.Placeholder
  );
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (
      document: Placeholder
    ): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      stringIdentifier: document.stringIdentifier,
      [Brand.homee]: document[Brand.homee]?.[userStore.locale],
      [Brand.AFRISOhome]: document[Brand.AFRISOhome]?.[userStore.locale],
      [Brand.VARIA3]: document[Brand.VARIA3]?.[userStore.locale]
    })
  );
}

export const sortListBy: string | string[] = 'stringIdentifier';

export async function getCategories(
  document: Partial<Placeholder>
): Promise<DetailData> {
  return {
    title: document.stringIdentifier,
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.STRING_IDENTIFIER),
            required: true,
            unique: Object.entries(persistentDocumentsStore.Placeholder)
              .filter(
                ([key]: [string, Placeholder]): boolean => key !== document._id
              )
              .map(
                ([, item]: [string, Placeholder]): string =>
                  item.stringIdentifier
              ),
            model: 'stringIdentifier',
            value: document.stringIdentifier
          },
          ...supportedBrands.map(
            (brand: keyof typeof Brand): ObjectField => ({
              type: FieldTypes.OBJECT,
              label: brand,
              required: true,
              model: brand,
              defaultValue: supportedLocalizations.reduce(
                (defaultValue: JsonObject, key: string): JsonObject => ({
                  ...defaultValue,
                  [key]: ''
                }),
                {}
              ),
              value: (document[brand] as unknown) as JsonObject,
              rules: [isValidJson]
            })
          ),
          {
            type: FieldTypes.MULTILINE,
            label: I18n.get(Translations.NOTE),
            required: false,
            model: 'note',
            value: document.note
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.STRING_IDENTIFIER),
      property: 'stringIdentifier'
    },
    ...supportedBrands.map(
      (brand: keyof typeof Brand): DocsFilter => ({
        operators: ['exists', 'notExists'],
        label: brand,
        property: brand
      })
    ),
    {
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      label: I18n.get(Translations.NOTE),
      property: 'note'
    }
  ];
}
