






















































































































import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator';
import ace, { Ace } from 'ace-builds';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-xml';
import '../styles/ace-theme';
import {
  CodeEditorMode,
  ValidationRule,
  ValidationRules
} from '../typings/field';

@Component
export default class CodeEditorComponent extends Vue {
  private editor?: Ace.Editor;
  private errors: string[] = [];
  private collapsed: boolean = false;
  private CodeEditorMode: typeof CodeEditorMode = CodeEditorMode;

  @Prop({ type: [String, Boolean], default: null })
  private hint!: string | null;

  public get hasError(): boolean {
    return !!this.errors.length;
  }

  @Inject({ default: null }) readonly form!: {
    register: (input: Vue) => void;
    unregister: (input: Vue) => void;
  } | null;

  @Prop({ type: String, default: '' })
  private readonly label!: string;

  @Prop({ type: String, default: '' })
  private readonly value!: string;

  @Watch('value', { deep: true })
  private onValueChanged(value: string): void {
    if (this.editor && this.editor.getValue() !== value) {
      this.editor.setValue(value ?? '');
    }
  }

  @Prop({
    type: Array,
    default: (): ValidationRules => []
  })
  private readonly rules!: ValidationRules;

  @Prop({ type: String, default: CodeEditorMode.MARKDOWN })
  private readonly mode!: CodeEditorMode;

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean;

  @Watch('disabled')
  private onDisabledChanged(disabled: boolean): void {
    this.errors = [];
    if (this.editor) {
      this.editor.setOptions({
        readOnly: disabled,
        highlightActiveLine: !disabled,
        highlightGutterLine: !disabled
      });
      if (disabled) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.editor.renderer as any).$cursorLayer.element.style.opacity = 0;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.editor.renderer as any).$cursorLayer.element.style.opacity = 1;
      }
      if (this.mode !== CodeEditorMode.MARKDOWN) {
        this.editor.session.foldAll();
      }
    }
  }

  private copyToClipboard(): void {
    if (this.editor) {
      this.editor.selectAll();
      this.editor.focus();
      document.execCommand('copy');
    }
  }

  private created(): void {
    this.form && this.form.register(this);
  }

  private beforeDestroy(): void {
    this.form && this.form.unregister(this);
  }

  private mounted(): void {
    this.editor = ace.edit(this.$refs.editor as HTMLElement, {
      readOnly: this.disabled,
      highlightActiveLine: !this.disabled,
      highlightGutterLine: !this.disabled,
      showPrintMargin: false,
      fontSize: 13,
      tabSize: 2,
      useSoftTabs: true,
      maxLines: Infinity,
      minLines: 1,
      value: this.value ?? '',
      mode: this.mode,
      theme: 'ace/theme/homeeTheme'
    });
    this.disabled &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((this.editor.renderer as any).$cursorLayer.element.style.opacity = 0);

    this.editor.session.on('changeFold', (): void =>
      this.$nextTick(
        (): void =>
          void (
            this.editor &&
            (this.collapsed = this.editor.session.getAllFolds().length > 0)
          )
      )
    );
    if (this.mode !== CodeEditorMode.MARKDOWN) {
      this.editor.session.foldAll();
    }
    this.editor.session.setUseWrapMode(true);
    this.editor.on('input', (): void => {
      if (this.editor) {
        const value: string = this.editor.getValue();
        this.$emit('input', value);
        this.errors = this.rules.reduce(
          (errors: string[], rule: ValidationRule): string[] => {
            const error: boolean | string = rule(value);
            if (typeof error === 'string') {
              errors.push(error);
            }
            return errors;
          },
          []
        );
      }
    });
  }
}
