





































































































































































































































































































































































































































































import CardListComponent from '../components/CardListComponent.vue';
import type { Card } from '../typings/card';
import TableListComponent from '../components/TableListComponent.vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  getColumns as getAttributeTypeColumns,
  getRows as getAttributeTypeRows,
  sortListBy as sortAttributeTypeListBy,
  getFilters as getAttributeTypeFilters
} from '../api/attributeType';
import {
  getColumns as getAttributeValueColumns,
  getRows as getAttributeValueRows,
  sortListBy as sortAttributeValueListBy,
  getFilters as getAttributeValueFilters
} from '../api/attributeValue';
import {
  getColumns as getConditionOperatorColumns,
  getRows as getConditionOperatorRows,
  sortListBy as sortConditionOperatorListBy,
  getFilters as getConditionOperatorFilters
} from '../api/conditionOperator';
import {
  getColumns as getCubeColumns,
  getRows as getCubeRows,
  sortListBy as sortCubeListBy,
  getFilters as getCubeFilters
} from '../api/cube';
import {
  getColumns as getHomeegramClassColumns,
  getRows as getHomeegramClassRows,
  sortListBy as sortHomeegramClassListBy,
  getFilters as getHomeegramClassFilters
} from '../api/homeegramClass';
import {
  getColumns as getLocalizationColumns,
  getRows as getLocalizationRows,
  sortListBy as sortLocalizationListBy,
  getFilters as getLocalizationFilters
} from '../api/localization';
import {
  getColumns as getPlaceholderColumns,
  getRows as getPlaceholderRows,
  sortListBy as sortPlaceholderListBy,
  getFilters as getPlaceholderFilters
} from '../api/placeholder';
import {
  getColumns as getProductColumns,
  getRows as getProductRows,
  sortListBy as sortProductListBy,
  getCards,
  getFilters as getProductFilters
} from '../api/product';
import {
  getColumns as getProductIconColumns,
  getRows as getProductIconRows,
  sortListBy as sortProductIconListBy,
  getFilters as getProductIconFilters
} from '../api/productIcon';
import {
  getColumns as getProductTypeColumns,
  getRows as getProductTypeRows,
  sortListBy as sortProductTypeListBy,
  getFilters as getProductTypeFilters
} from '../api/productType';
import {
  getColumns as getProfileColumns,
  getRows as getProfileRows,
  sortListBy as sortProfileListBy,
  getFilters as getProfileFilters
} from '../api/profile';
import {
  getColumns as getProtocolColumns,
  getRows as getProtocolRows,
  sortListBy as sortProtocolListBy,
  getFilters as getProtocolFilters
} from '../api/protocol';
import {
  getColumns as getServiceColumns,
  getRows as getServiceRows,
  sortListBy as sortServiceListBy,
  getFilters as getServiceFilters
} from '../api/service';
import {
  getColumns as getTriggerOperatorColumns,
  getRows as getTriggerOperatorRows,
  sortListBy as sortTriggerOperatorListBy,
  getFilters as getTriggerOperatorFilters
} from '../api/triggerOperator';
import {
  getColumns as getUiElementColumns,
  getRows as getUiElementRows,
  sortListBy as sortUiElementListBy,
  getFilters as getUiElementFilters
} from '../api/uiElement';
import {
  getColumns as getUseCaseColumns,
  getRows as getUseCaseRows,
  sortListBy as sortUseCaseListBy,
  getFilters as getUseCaseFilters
} from '../api/useCase';
import {
  mdiMagnify,
  mdiClose,
  mdiDownload,
  mdiAndroid,
  mdiApple,
  mdiWeb,
  mdiMicrosoftExcel,
  mdiCodeJson,
  mdiFileMultiple,
  mdiPlus,
  mdiDelete,
  mdiFilter,
  mdiFilterOff,
  mdiEmailAlert,
  mdiApproximatelyEqual,
  mdiEqual
} from '@mdi/js';
import type { DataTableHeader } from 'vuetify';
import type { MyDataTableHeader } from '../typings';
import { DocTypes, DocumentFilter, DocsFilter } from '../api/document';
import {
  triggerDownload as triggerProductListDownload,
  DownloadTarget as ProductListDownloadTarget
} from '../api/product.download';
import {
  triggerDownload as triggerLocalizationListDownload,
  DownloadTarget as LocalizationListDownloadTarget
} from '../api/localization.download';
import store, { persistentDocumentsStore, userStore } from '../plugins/store';
import type { Route, NavigationGuardNext } from 'vue-router';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import {
  batchDeleteDocument,
  CREATE_GROUP,
  DELETE_GROUP,
  ListFilter,
  queryWholeDocumentList,
  Rows,
  searchDocumentList
} from '../api';
import FilterComponent from './FilterComponent.vue';
import { noop } from 'lodash';
import { Bind, Debounce } from 'lodash-decorators';
import _ from 'lodash';
import { SearchType } from '../plugins/store/persistentDocuments';
import { WaitForPromise } from '../plugins/decorators';

const filterMap: Record<DocTypes, () => DocsFilter[]> = {
  [DocTypes.AttributeType]: getAttributeTypeFilters,
  [DocTypes.AttributeValue]: getAttributeValueFilters,
  [DocTypes.ConditionOperator]: getConditionOperatorFilters,
  [DocTypes.Cube]: getCubeFilters,
  [DocTypes.HomeegramClass]: getHomeegramClassFilters,
  [DocTypes.Localization]: getLocalizationFilters,
  [DocTypes.Placeholder]: getPlaceholderFilters,
  [DocTypes.Product]: getProductFilters,
  [DocTypes.ProductIcon]: getProductIconFilters,
  [DocTypes.ProductType]: getProductTypeFilters,
  [DocTypes.Profile]: getProfileFilters,
  [DocTypes.Protocol]: getProtocolFilters,
  [DocTypes.Service]: getServiceFilters,
  [DocTypes.TriggerOperator]: getTriggerOperatorFilters,
  [DocTypes.UiElement]: getUiElementFilters,
  [DocTypes.UseCase]: getUseCaseFilters
};

const columnsMap: Record<DocTypes, () => MyDataTableHeader[]> = {
  [DocTypes.AttributeType]: getAttributeTypeColumns,
  [DocTypes.AttributeValue]: getAttributeValueColumns,
  [DocTypes.ConditionOperator]: getConditionOperatorColumns,
  [DocTypes.Cube]: getCubeColumns,
  [DocTypes.HomeegramClass]: getHomeegramClassColumns,
  [DocTypes.Localization]: getLocalizationColumns,
  [DocTypes.Placeholder]: getPlaceholderColumns,
  [DocTypes.Product]: getProductColumns,
  [DocTypes.ProductIcon]: getProductIconColumns,
  [DocTypes.ProductType]: getProductTypeColumns,
  [DocTypes.Profile]: getProfileColumns,
  [DocTypes.Protocol]: getProtocolColumns,
  [DocTypes.Service]: getServiceColumns,
  [DocTypes.TriggerOperator]: getTriggerOperatorColumns,
  [DocTypes.UiElement]: getUiElementColumns,
  [DocTypes.UseCase]: getUseCaseColumns
};

const rowsMap: Record<DocTypes, (listFilter?: ListFilter) => Rows> = {
  [DocTypes.AttributeType]: getAttributeTypeRows,
  [DocTypes.AttributeValue]: getAttributeValueRows,
  [DocTypes.ConditionOperator]: getConditionOperatorRows,
  [DocTypes.Cube]: getCubeRows,
  [DocTypes.HomeegramClass]: getHomeegramClassRows,
  [DocTypes.Localization]: getLocalizationRows,
  [DocTypes.Placeholder]: getPlaceholderRows,
  [DocTypes.Product]: getProductRows,
  [DocTypes.ProductIcon]: getProductIconRows,
  [DocTypes.ProductType]: getProductTypeRows,
  [DocTypes.Profile]: getProfileRows,
  [DocTypes.Protocol]: getProtocolRows,
  [DocTypes.Service]: getServiceRows,
  [DocTypes.TriggerOperator]: getTriggerOperatorRows,
  [DocTypes.UiElement]: getUiElementRows,
  [DocTypes.UseCase]: getUseCaseRows
};

const sortMap: Record<DocTypes, string | string[]> = {
  [DocTypes.AttributeType]: sortAttributeTypeListBy,
  [DocTypes.AttributeValue]: sortAttributeValueListBy,
  [DocTypes.ConditionOperator]: sortConditionOperatorListBy,
  [DocTypes.Cube]: sortCubeListBy,
  [DocTypes.HomeegramClass]: sortHomeegramClassListBy,
  [DocTypes.Localization]: sortLocalizationListBy,
  [DocTypes.Placeholder]: sortPlaceholderListBy,
  [DocTypes.Product]: sortProductListBy,
  [DocTypes.ProductIcon]: sortProductIconListBy,
  [DocTypes.ProductType]: sortProductTypeListBy,
  [DocTypes.Profile]: sortProfileListBy,
  [DocTypes.Protocol]: sortProtocolListBy,
  [DocTypes.Service]: sortServiceListBy,
  [DocTypes.TriggerOperator]: sortTriggerOperatorListBy,
  [DocTypes.UiElement]: sortUiElementListBy,
  [DocTypes.UseCase]: sortUseCaseListBy
};

@Component({
  components: {
    CardListComponent,
    TableListComponent,
    FilterComponent
  }
})
export default class ListComponent extends Vue {
  private readonly searchIconSvg: string = mdiMagnify;
  private readonly closeIconSvg: string = mdiClose;
  private readonly downloadIconSvg: string = mdiDownload;
  private readonly androidIconSvg: string = mdiAndroid;
  private readonly appleIconSvg: string = mdiApple;
  private readonly webIconSvg: string = mdiWeb;
  private readonly mailIconSvg: string = mdiEmailAlert;
  private readonly excelIconSvg: string = mdiMicrosoftExcel;
  private readonly jsonIconSvg: string = mdiCodeJson;
  private readonly filesIconSvg: string = mdiFileMultiple;
  private readonly plusIconSvg: string = mdiPlus;
  private readonly deleteIconSvg: string = mdiDelete;
  private readonly filterIconSvg: string = mdiFilter;
  private readonly deleteFilterIconSvg: string = mdiFilterOff;
  private readonly equalIconSvg: string = mdiEqual;
  private readonly approxEqualIconSvg: string = mdiApproximatelyEqual;
  private readonly DocTypes: typeof DocTypes = DocTypes;
  private readonly SearchType: typeof SearchType = SearchType;
  private readonly ProductListDownloadTarget: typeof ProductListDownloadTarget = ProductListDownloadTarget;
  private readonly LocalizationListDownloadTarget: typeof LocalizationListDownloadTarget = LocalizationListDownloadTarget;
  private fab: boolean = false;
  private selected: string[] = [];
  private showConfirmDelete: boolean = false;
  private showFilterDialog: boolean = false;
  private error: false | string = false;
  private showCardGallery: boolean = false;
  private showWaiting: boolean = false;
  private urlFound: boolean = false;
  private isSearch: boolean = false;
  private gifUrl: string = '';
  private listFilter: ListFilter = false;
  private localLoading: boolean = true;

  private mounted(): void {
    this.updateListFilter();
    this.$mousetrap.bind('g f', (): void => {
      if (this.showFilterDialog) {
        (this.$refs.filterComponent as FilterComponent | undefined)?.reset();
      } else {
        this.showFilterDialog = true;
      }
    });
    this.$mousetrap.bind('/', (e: KeyboardEvent): void => {
      e.preventDefault();
      (this.$refs.searchField as HTMLInputElement)?.focus();
    });
  }

  private activated(): void {
    this.updateListFilter();
  }

  @Prop({ type: String })
  private readonly docType: DocTypes | undefined;

  @Prop({ type: Object, default: (): DocumentFilter => ({}) })
  private readonly filter!: DocumentFilter;

  @Prop({ type: String, default: '' })
  private readonly search!: string;

  private get lastFetch(): number {
    return this.docType ? persistentDocumentsStore.lastFetch[this.docType] : 0;
  }

  private get availableFilters(): DocsFilter[] {
    return _.sortBy(
      this.docType
        ? (filterMap[this.docType] || ((): DocsFilter[] => []))()
        : [],
      (item: DocsFilter): string => item.label.toLowerCase()
    );
  }

  private get columns(): MyDataTableHeader[] {
    return this.docType ? columnsMap[this.docType]() : [];
  }

  private get headers(): DataTableHeader[] {
    return [
      ...(!this.columns
        ? []
        : this.columns.filter((c: MyDataTableHeader): boolean => !c.hidden))
    ];
  }

  private get createPermission(): boolean {
    return userStore.groups.includes(CREATE_GROUP);
  }

  private get deletePermission(): boolean {
    return userStore.groups.includes(DELETE_GROUP);
  }

  @Watch('docType')
  onDocTypeChange(n: DocTypes, o: DocTypes): void {
    if (n !== o) {
      this.updateListFilter();
    }
  }

  @Watch('lastFetch')
  onLastFetchChange(n: number, o: number): void {
    if (n !== o) {
      this.updateListFilter();
    }
  }

  @Watch('search')
  onSearchChange(n: string, o: string): void {
    if (n !== o && !(n.length < 3 && o.length < 3)) {
      this.updateListFilter();
    }
  }

  @Watch('searchType')
  onSearchTypeChange(n: SearchType, o: SearchType): void {
    if (n !== o) {
      this.updateListFilter();
    }
  }

  @Watch('filter', { deep: true })
  onFilterChange(n: DocumentFilter, o: DocumentFilter): void {
    if (JSON.stringify(n) !== JSON.stringify(o)) {
      this.updateListFilter();
    }
  }

  @WaitForPromise({
    promise: store.restored
  })
  private async updateListFilter(): Promise<void> {
    this.localLoading = true;
    const isSearch: boolean = !!this.search && this.search.length >= 3;
    try {
      if (!this.docType || (!isSearch && !this.isFilter)) {
        this.listFilter = false;
        return;
      }
      let searchPromise: Promise<
        Array<{ _id: string; _score?: number }>
      > = Promise.resolve([]);
      let filterPromise: Promise<
        Array<{ _id: string; _score?: number }>
      > = Promise.resolve([]);
      if (isSearch) {
        searchPromise = searchDocumentList(
          this.docType,
          this.search,
          this.searchType
        );
      }
      if (this.isFilter) {
        filterPromise = queryWholeDocumentList(this.docType, '', this.filter);
      }

      let rawListFilter: Array<{ _id: string; _score?: number }> = [];
      if (isSearch && this.isFilter) {
        rawListFilter = _.intersectionBy(
          await searchPromise,
          await filterPromise,
          '_id'
        );
      } else {
        rawListFilter = (await searchPromise).concat(await filterPromise);
      }
      this.listFilter = rawListFilter.reduce(
        (
          filter: ListFilter,
          hit: { _id: string; _score?: number }
        ): ListFilter => ({
          ...filter,
          [hit._id]: hit._score || 0
        }),
        {}
      );
    } catch (error) {
      this.error = I18n.get(Translations.FILTER_ERROR);
      this.$logger.error(error);
      this.listFilter = {};
    } finally {
      this.localLoading = false;
      this.isSearch = isSearch;
    }
  }

  private get sortBy(): string | string[] {
    return this.docType && !this.isSearch ? sortMap[this.docType] : '';
  }

  private get rows(): Rows {
    if (
      !this.docType ||
      (this.listFilter && !Object.keys(this.listFilter).length)
    ) {
      return [];
    }
    return rowsMap[this.docType](this.listFilter);
  }

  private get cards(): Card[] {
    if (
      !this.docType ||
      (this.listFilter && !Object.keys(this.listFilter).length)
    ) {
      return [];
    }
    return getCards(this.listFilter);
  }

  @Debounce(500)
  @Bind()
  private setSearchText(search?: string): void {
    this.$router
      .push({
        query: {
          ...(this.$route.query || {}),
          search: search || undefined
        }
      })
      .catch(noop);
  }

  private get isFilter(): boolean {
    return !!this.filter && Object.keys(this.filter).length > 0;
  }

  private get hasDownload(): boolean {
    return (
      !!this.docType &&
      [DocTypes.Product, DocTypes.Localization].includes(this.docType)
    );
  }

  private get loading(): boolean {
    return this.localLoading || persistentDocumentsStore.loading;
  }

  private get searchType(): SearchType {
    return (
      persistentDocumentsStore.userPreferences.searchType || SearchType.FUZZY
    );
  }

  private set searchType(type: SearchType) {
    persistentDocumentsStore.updateSearchType(type);
  }

  private deleteDocuments(): void {
    if (!this.docType || !this.selected) {
      return;
    }
    const chunks: string[][] = this.selected.reduce(
      (all: string[][], one: string, i: number): string[][] => {
        const ch: number = Math.floor(i / 25);
        all[ch] = ([] as string[]).concat(all[ch] || ([] as string[]), one);
        return all;
      },
      [] as string[][]
    );
    Promise.all(
      chunks.map(
        (chunk: string[]): Promise<void> =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          batchDeleteDocument(chunk, this.docType!)
      )
    )
      .catch((): void => {
        this.error = I18n.get(Translations.DELETE_DOCUMENTS_ERROR);
      })
      .finally((): void => {
        this.showConfirmDelete = false;
        this.selected = [];
      });
  }

  private triggerProductListDownload(target: ProductListDownloadTarget): void {
    this.showWaiting = true;
    triggerProductListDownload(target).finally((): void => {
      this.showWaiting = false;
    });
  }

  private triggerLocalizationListDownload(
    target: LocalizationListDownloadTarget
  ): void {
    this.showWaiting = true;
    triggerLocalizationListDownload(target).finally((): void => {
      this.showWaiting = false;
    });
  }

  private async fetchWaitingGif(): Promise<void> {
    this.urlFound = false;
    const response: Response = await fetch(
      'https://api.giphy.com/v1/gifs/random?api_key=XBZhKxoFKHu3JTnb0ZHo3r9fusAr6UBz&tag=kitten+loading+cat&rating=g'
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: Record<string, any> = await response.json();
    this.urlFound = true;
    this.gifUrl = obj.data.images.original.url;
  }

  private openDetail(id: string): void {
    this.$router.push({
      path: `/${(this.docType ?? '').toLowerCase()}/${id}`
    });
  }

  private openDetailNewTab(id: string): void {
    window.open(`/${(this.docType ?? '').toLowerCase()}/${id}`, '_blank');
  }

  private setNextSearchType(): void {
    switch (this.searchType) {
      case SearchType.FUZZY:
        this.searchType = SearchType.STRICT;
        break;
      case SearchType.STRICT:
        this.searchType = SearchType.SYNTAX;
        break;
      default:
        this.searchType = SearchType.FUZZY;
        break;
    }
  }

  private setFilter(filter: DocumentFilter): void {
    this.showFilterDialog = false;
    this.$router
      .push({
        query: {
          ...(this.$route.query || {}),
          filter: Object.keys(filter).length
            ? JSON.stringify(filter)
            : undefined
        }
      })
      .catch(noop);
  }

  private beforeRouteEnter(
    to: Route,
    _from: Route,
    next: NavigationGuardNext
  ): void {
    document.title = Translations[
      `${(to.params?.docType ?? '').toUpperCase()}_LIST`
    ]
      ? `${I18n.get(
          Translations[`${to.params.docType.toUpperCase()}_LIST`]
        )} - homee Docs`
      : 'homee Docs';
    next();
  }
}
