import {
  Document,
  DocTypes,
  LocalizedDocument,
  supportedLocalizations,
  DocsFilter
} from './document';
import { supportedLanguages, Translations } from '../plugins/i18n';
import { FieldTypes, TextField, DetailData } from '../typings/field';
import { I18n } from '@aws-amplify/core';
import { persistentDocumentsStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface UseCase extends Document, LocalizedDocument<string | null> {
  _docType: DocTypes.UseCase;
  stringIdentifier: string;
}

export const defaultListProperties: string = `
  stringIdentifier
  ${supportedLanguages.join('\n')}
`;

export const defaultDetailProperties: string = `
  stringIdentifier
  ${supportedLocalizations.join('\n')}
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.STRING_IDENTIFIER),
      value: 'stringIdentifier',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.GERMAN),
      value: 'de',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.ENGLISH),
      value: 'en',
      align: 'start',
      sortable: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: UseCase[] = Object.values(persistentDocumentsStore.UseCase);
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (document: UseCase): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      stringIdentifier: document.stringIdentifier,
      de: document.de,
      en: document.en
    })
  );
}

export const sortListBy: string | string[] = 'stringIdentifier';

export async function getCategories(
  document: Partial<UseCase>
): Promise<DetailData> {
  return {
    title: document.stringIdentifier,
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.STRING_IDENTIFIER),
            required: true,
            unique: Object.entries(persistentDocumentsStore.UseCase)
              .filter(
                ([key]: [string, UseCase]): boolean => key !== document._id
              )
              .map(
                ([, item]: [string, UseCase]): string => item.stringIdentifier
              ),
            model: 'stringIdentifier',
            value: document.stringIdentifier
          },
          ...supportedLocalizations.map(
            (locale: keyof LocalizedDocument): TextField => ({
              type: FieldTypes.TEXT,
              label: I18n.get(Translations[locale]),
              required: false,
              model: locale,
              value: document[locale]
            })
          )
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.STRING_IDENTIFIER),
      property: 'stringIdentifier'
    },
    ...supportedLocalizations.map(
      (locale: keyof LocalizedDocument): DocsFilter => ({
        operators: [
          'eq',
          'ne',
          'exists',
          'notExists',
          'contains',
          'notContains',
          'in'
        ],
        label: I18n.get(Translations[locale]),
        property: locale
      })
    )
  ];
}
