














































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import type {
  DocumentFilter,
  DocsFilter,
  FilterOperators
} from '../api/document';
import { mdiPlus, mdiClose } from '@mdi/js';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import type { JsonValue } from 'type-fest';

interface Filter {
  property: string | null;
  operator: FilterOperators | null;
  value: JsonValue | null;
}

@Component
export default class FilterComponent extends Vue {
  private readonly plusIconSvg: string = mdiPlus;
  private readonly closeIconSvg: string = mdiClose;

  private filters: Filter[] = [];

  @Prop({
    type: Object,
    default: (): DocumentFilter => ({})
  })
  private readonly value!: DocumentFilter;

  mounted(): void {
    this.setValue();
    this.$mousetrap.bind('esc', (): void => this.reset());
  }

  @Watch('value')
  private setValue(): void {
    const replacement: Filter[] = [];
    for (const property in this.value) {
      for (let operator in this.value[property]) {
        if (
          operator === 'exists' &&
          this.value[property][operator as FilterOperators] === false
        ) {
          operator = 'notExists';
        }
        replacement.push({
          property: property,
          operator: operator as FilterOperators,
          value: this.value[property][operator as FilterOperators] || null
        });
      }
    }
    this.filters = replacement;
  }

  @Prop({ type: Array, default: (): DocsFilter[] => [] })
  private readonly availableFilters!: DocsFilter[];

  private getAvailableFilter(
    property: string,
    availableFilter: DocsFilter | undefined = this.availableFilters.find(
      (availableFilter: DocsFilter): boolean =>
        availableFilter.property === property
    )
  ): DocsFilter {
    return availableFilter || ({} as DocsFilter);
  }

  private getAvailableOperators(
    property: string,
    currentOperator: FilterOperators | null,
    availableFilter: DocsFilter | undefined = this.availableFilters.find(
      (availableFilter: DocsFilter): boolean =>
        availableFilter.property === property
    )
  ): Array<{ text: string; value: string }> {
    if (!availableFilter) {
      return [];
    }
    return availableFilter.operators
      .filter(
        (operator: FilterOperators): boolean =>
          operator === currentOperator ||
          !this.filters.some(
            (usedFilter: Filter): boolean =>
              usedFilter.property === availableFilter.property &&
              (usedFilter.operator === operator ||
                (!['exists', 'notExists'].includes(currentOperator as string) &&
                  ['exists', 'notExists'].includes(
                    usedFilter.operator as string
                  ) &&
                  ['exists', 'notExists'].includes(operator)))
          )
      )
      .map((operator: FilterOperators): { text: string; value: string } => ({
        text: I18n.get(Translations[operator]),
        value: operator
      }));
  }

  private getAvailableProperties(
    currentProperty: string | null
  ): Array<{ text: string; value: string }> {
    return this.availableFilters
      .filter(
        (availableFilter: DocsFilter): boolean =>
          availableFilter.property === currentProperty ||
          this.getAvailableOperators(
            availableFilter.property,
            null,
            availableFilter
          ).length > 0
      )
      .map((availableFilter: DocsFilter): {
        text: string;
        value: string;
      } => ({
        text: availableFilter.label,
        value: availableFilter.property.toString()
      }));
  }

  private addFilter(): void {
    this.filters.push({
      property: null,
      operator: null,
      value: null
    });
  }

  private removeAllFilter(): void {
    this.filters = [];
  }

  private removeFilter(index: number): void {
    this.filters.splice(index, 1);
  }

  public reset(): void {
    this.setValue();
    this.applyFilters();
  }

  private isValid(): boolean {
    for (const filter of this.filters) {
      if (
        ['exists', 'notExists'].includes(filter.operator as string) ||
        typeof filter.value === 'boolean'
      ) {
        continue;
      }
      for (const prop in filter) {
        if (
          !filter[prop as keyof Filter] ||
          (Array.isArray(filter[prop as keyof Filter]) &&
            (filter[prop as keyof Filter] as string[]).filter(Boolean).length <=
              0)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  private validateKey(
    validator: (data: string) => boolean,
    event: KeyboardEvent
  ): void {
    if (!validator) {
      return;
    }
    const isForbiddenChar: boolean =
      event.key.length === 1 && !validator(event.key);
    const isAllowedModifier: boolean = event.ctrlKey || event.metaKey;
    if (isForbiddenChar && !isAllowedModifier) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validatePaste(
    validator: (data: string) => boolean,
    event: ClipboardEvent
  ): void {
    if (!validator) {
      return;
    }
    if (
      !event.clipboardData ||
      !validator(event.clipboardData.getData('text/plain'))
    ) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validateDrop(
    validator: (data: string) => boolean,
    event: DragEvent
  ): void {
    if (!validator) {
      return;
    }
    if (
      !event.dataTransfer ||
      !validator(event.dataTransfer.getData('text/plain'))
    ) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private applyFilters(): void {
    this.$emit(
      'input',
      this.filters.reduce(
        (input: DocumentFilter, filter: Filter): DocumentFilter => {
          if (filter.operator === 'exists') {
            filter.value = true;
          } else if (filter.operator === 'notExists') {
            filter.operator = 'exists';
            filter.value = false;
          }
          return {
            ...input,
            ...(filter.property
              ? {
                  [filter.property]: {
                    ...(input[filter.property ?? ''] || {}),
                    [filter.operator as FilterOperators]: filter.value || false
                  }
                }
              : {})
          };
        },
        {}
      )
    );
  }
}
