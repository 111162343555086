








































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Hub, I18n } from '@aws-amplify/core';
import type { HubCapsule } from '@aws-amplify/core/lib/Hub';
import {
  mdiPencil,
  mdiAccountCircle,
  mdiTag,
  mdiWeb,
  mdiDotsHorizontal,
  mdiClose,
  mdiKeyboard
} from '@mdi/js';
import { noop } from 'lodash';
import store, { userStore } from './plugins/store';
import { caiProfiles, caiAttributeTypes } from './plugins/vuetify';
import { fetchDocumentLists, setupSubscriptions } from './api';
import { DocTypes, supportedDocTypes } from './api/document';
import { Translations } from './plugins/i18n';
import GlobalEvents from 'vue-global-events';
import Auth from '@aws-amplify/auth';
import type { Route } from 'vue-router';

interface NavItem {
  title: string;
  icon?: string;
  link: string;
}

@Component({
  components: {
    GlobalEvents
  }
})
export default class App extends Vue {
  private readonly defaultAvatar: string =
    'data:image/svg+xml;base64,' +
    btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 20 20"><path fill="#6F6F6F" d="${mdiAccountCircle}"></path></svg>`
    );
  private readonly moreNavIcon: string = mdiDotsHorizontal;
  private readonly version: string = process.env.APP_VERSION ?? '';
  private readonly pencilIconSvg: string = mdiPencil;
  private readonly closeIconSvg: string = mdiClose;
  private readonly shortcutIconSvg: string = mdiKeyboard;
  private avatarFallback: string | null = null;
  private refreshing: boolean = false;
  private drawerCollapsed: boolean = true;
  private promptUpdate: boolean = false;
  private isInitial: boolean = false;
  private showShortcutsDialog: boolean = false;
  private registration?: ServiceWorkerRegistration;
  private get itemsGeneral(): NavItem[] {
    return userStore.locale
      ? [
          {
            title: I18n.get(Translations.PRODUCTS),
            icon: mdiTag,
            link: `/${DocTypes.Product.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.PROFILES),
            icon: caiProfiles,
            link: `/${DocTypes.Profile.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.ATTRIBUTETYPES),
            icon: caiAttributeTypes,
            link: `/${DocTypes.AttributeType.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.LOCALIZATION),
            icon: mdiWeb,
            link: `/${DocTypes.Localization.toLowerCase()}`
          }
        ]
      : [];
  }
  private get itemsProduct(): NavItem[] {
    return userStore.locale
      ? [
          {
            title: I18n.get(Translations.USECASES),
            link: `/${DocTypes.UseCase.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.PRODUCTTYPES),
            link: `/${DocTypes.ProductType.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.PRODUCTICONS),
            link: `/${DocTypes.ProductIcon.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.CUBES),
            link: `/${DocTypes.Cube.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.PROTOCOLS),
            link: `/${DocTypes.Protocol.toLowerCase()}`
          }
        ]
      : [];
  }
  private get itemsAttribute(): NavItem[] {
    return userStore.locale
      ? [
          {
            title: I18n.get(Translations.ATTRIBUTEVALUES),
            link: `/${DocTypes.AttributeValue.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.UI_ELEMENTS),
            link: `/${DocTypes.UiElement.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.SERVICES),
            link: `/${DocTypes.Service.toLowerCase()}`
          }
        ]
      : [];
  }
  private get itemsHomeegram(): NavItem[] {
    return userStore.locale
      ? [
          {
            title: I18n.get(Translations.TRIGGER_OPERATORS),
            link: `/${DocTypes.TriggerOperator.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.CONDITION_OPERATORS),
            link: `/${DocTypes.ConditionOperator.toLowerCase()}`
          },
          {
            title: I18n.get(Translations.HOMEEGRAM_CLASSES),
            link: `/${DocTypes.HomeegramClass.toLowerCase()}`
          }
        ]
      : [];
  }
  private get itemsMore(): NavItem[] {
    return userStore.locale
      ? [
          {
            title: I18n.get(Translations.PLACEHOLDERS),
            link: `/${DocTypes.Placeholder.toLowerCase()}`
          }
        ]
      : [];
  }

  private get isAuthenticated(): boolean {
    return !['/', '/auth'].includes(this.$route.path);
  }
  private get name(): string {
    return userStore.name;
  }
  private get email(): string {
    return userStore.email;
  }

  private get avatarUrl(): string {
    if (this.avatarFallback) {
      return this.avatarFallback;
    } else {
      return userStore.avatarUrl;
    }
  }

  private created(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener<any>(
      'swUpdated',
      (e: CustomEvent): void => this.showRefreshUI(e),
      { once: true }
    );
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', (): void => {
        if (this.refreshing) {
          return;
        }
        this.refreshing = true;
        window.location.reload();
      });
    }

    Hub.listen('auth', ({ payload }: HubCapsule): void => {
      this.drawerCollapsed = true;
      if (payload.event === 'signIn' && !userStore.isAuthenticated) {
        this.isInitial = true;
        userStore
          .login()
          .then(
            (): Promise<Route> =>
              this.$router.replace(
                (this.$route.query.redirect as string) ||
                  `/${DocTypes.Product.toLowerCase()}`
              )
          )
          .catch(
            (): Promise<Route> =>
              this.$router.replace(`/${DocTypes.Product.toLowerCase()}`)
          )
          .catch(noop)
          .then((): Promise<void> => this.initGraphQL())
          .catch((error: Error): void => {
            this.$logger.error(error);
          });
      } else if (payload.event === 'signOut' && userStore.isAuthenticated) {
        userStore
          .logout()
          .then(
            (): Promise<void> =>
              this.$router
                .replace({
                  path: 'auth',
                  query: {
                    redirect: this.$route.fullPath
                  }
                })
                .then(noop)
          )
          .catch(noop);
      }
    });

    this.initGraphQL();
  }

  private async initGraphQL(): Promise<void> {
    try {
      await setupSubscriptions();
      this.isInitial = !localStorage.getItem('initialized');
      await store.restored;
      this.isInitial = !localStorage.getItem('initialized');
      await fetchDocumentLists(
        supportedDocTypes.find(
          (docType: DocTypes): boolean =>
            (this.$route?.params?.docType ?? '').toLowerCase() ===
            docType.toLowerCase()
        ) || DocTypes.Product,
        this.isInitial
      );
    } catch (error) {
      this.$logger.error(error);
    } finally {
      this.isInitial = false;
    }
  }

  private async mounted(): Promise<void> {
    this.$mousetrap.bind(
      '?',
      (): void => void (this.showShortcutsDialog = !this.showShortcutsDialog)
    );
    await store.restored;
    this.$vuetify.lang.current = userStore.locale;
  }

  private getGroupRegex(group: NavItem[]): string {
    return `\\${group.map((item: NavItem): string => item.link).join('|\\')}`;
  }

  private signOut(): void {
    Auth.signOut().catch(noop);
  }

  private showRefreshUI(e: CustomEvent): void {
    this.registration = e.detail;
    this.promptUpdate = true;
  }

  private refreshApp(): void {
    this.promptUpdate = false;
    if (!this.registration?.waiting) {
      return;
    }
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }
}
