import { Translations } from '../plugins/i18n';
import { I18n } from '@aws-amplify/core';
import type { JsonObject } from 'type-fest';
import type { Document, DocsFile, DocTypes } from '../api/document';
import { valid } from 'semver';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationRule = (value: any) => boolean | string;
export type ValidationRules = ValidationRule[];

export interface Field {
  type: FieldTypes;
  label: string;
  required: boolean;
  infoText?: string;
  unique?: unknown[];
  value?: unknown | null;
  model?: string;
  hideInViewMode?: boolean;
  hideInEditMode?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hint?: (value: any) => string | null;
  rules?: ValidationRules;
}

export interface Category {
  name: string;
  icon?: string;
  fields: Array<
    | Field
    | TextField
    | NumberField
    | BooleanField
    | TextArea
    | ObjectField
    | CodeField
    | ArrayField
    | SelectField
    | AutocompleteField
    | FileField
    | ImageField
  >;
}

export interface DetailData {
  title?: string;
  subtitle?: string;
  categories: Category[];
}

export enum FileTypes {
  DOCUMENT = 'DOCUMENT',
  TABLE = 'TABLE'
}

export enum ImageTypes {
  IMAGE = 'IMAGE',
  ICON = 'ICON'
}

export interface TextField extends Field {
  type: FieldTypes.TEXT;
  value?: string | null;
  upperCase?: true;
  validator?: (data: string) => boolean;
  isLink?: true;
  prefix?: string;
}

export interface NumberField extends Field {
  type: FieldTypes.NUMBER;
  value?: number | null;
}

export interface BooleanField extends Field {
  type: FieldTypes.BOOLEAN;
  value?: boolean | null;
}

export interface TextArea extends Field {
  type: FieldTypes.MULTILINE;
  value?: string | null;
}

export interface ObjectField extends Field {
  type: FieldTypes.OBJECT;
  value?: JsonObject | null;
  defaultValue?: JsonObject;
}

export enum CodeEditorMode {
  MARKDOWN = 'ace/mode/markdown',
  JSON = 'ace/mode/json',
  XML = 'ace/mode/xml'
}

export interface CodeField extends Field {
  type: FieldTypes.CODE;
  value?: string | null;
  mode: CodeEditorMode;
}

export interface ArrayField extends Field {
  type: FieldTypes.ARRAY;
  value?: string | string[] | null;
  options?: string[];
  multiple: boolean;
  delimiters: string[];
  validator?: (data: string) => boolean;
  transform?: (data: string) => string;
}

export interface SelectField extends Field {
  type: FieldTypes.SELECT;
  value?: string | string[] | null;
  iconField?: (item: string) => string;
  lightupIcons?: boolean;
  options: Array<{ text: string; value: string }>;
  multiple: boolean;
}

export interface AutocompleteField extends Field {
  type: FieldTypes.AUTOCOMPLETE;
  value?: Document | Document[] | null;
  docType: DocTypes;
  textField: (item: Document) => string;
  valueField: string;
  iconField?: (item: Document) => string;
  lightupIcons?: boolean;
  additionalText?: (item: Document) => string;
  options: Document[];
  multiple: boolean;
}

export interface FileField extends Field {
  type: FieldTypes.FILE;
  value?: DocsFile | DocsFile[] | null;
  fileType: FileTypes;
  mimeType?: string;
  multiple: boolean;
}

export interface ImageField extends Field {
  type: FieldTypes.IMAGE;
  value?: DocsFile | DocsFile[] | null;
  imageType: ImageTypes;
  mimeType?: string;
  multiple: boolean;
}

export interface ColorField extends Field {
  type: FieldTypes.COLOR;
  value?: string | null;
}

export enum FieldTypes {
  NONE = 'NONE',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  MULTILINE = 'MULTILINE',
  OBJECT = 'OBJECT',
  CODE = 'CODE',
  ARRAY = 'ARRAY',
  SELECT = 'SELECT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  COLOR = 'COLOR'
}

export function isValidSemver(value?: string): boolean | string {
  if (typeof value !== 'string' || !value.length) {
    return true;
  }
  return !!valid(value) || I18n.get(Translations.INVALID_SEMVER);
}

export function isValidJson(value: JsonObject | string): boolean | string {
  if (typeof value !== 'string' || !value.length) {
    return true;
  }
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return I18n.get(Translations.INVALID_JSON);
  }
}

export function formatJson(value?: string | null): string | null {
  if (value == null) {
    return null;
  }
  try {
    return JSON.stringify(JSON.parse(value), null, '\t');
  } catch (e) {
    return value;
  }
}
