var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.restored)?_c('div',{style:(("background-color: #424242; height: calc(100vh - 80px - " + (_vm.$vuetify.breakpoint.name === 'xs' ? 0 : 112) + "px); position: relative; overflow: hidden; border-radius: 4px"))},[_c('v-skeleton-loader',{attrs:{"type":"table-thead, divider, table-tbody, table-tbody"}}),_c('v-skeleton-loader',{staticStyle:{"position":"absolute","bottom":"0","left":"0","right":"0","z-index":"1"},attrs:{"type":"divider, table-tfoot"}})],1):_c('v-data-table',{staticStyle:{"overflow":"hidden"},attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"_id","show-select":_vm.deletePermission,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortBy === '_changedAt',"must-sort":"","fixed-header":"","height":("calc(100vh - 80px - 59px - " + (_vm.$vuetify.breakpoint.name === 'xs' ? 0 : 112) + "px)"),"items-per-page":_vm.itemsPerPage,"page":_vm._page,"footer-props":{
      itemsPerPageOptions: [25, 50, 100, -1]
    }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm._page=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var index = ref.index;
    var item = ref.item;
    var isMobile = ref.isMobile;
    var isSelected = ref.isSelected;
    var select = ref.select;
    var headers = ref.headers;
return [_c(isMobile ? 'v-mobile-row' : 'v-row',{key:item._id,tag:"component",staticClass:"row-pointer",class:{ 'v-data-table__selected': isSelected },attrs:{"index":index,"item":item,"headers":headers,"rtl":_vm.$vuetify.rtl},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }!_vm.elementContainsSelection($event.target) &&
            _vm.$emit(
              $event.metaKey || $event.ctrlKey ? 'open-new-tab' : 'open',
              item._id
            )},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.$emit('open-new-tab', item._id)}},scopedSlots:_vm._u([{key:"data-table-select",fn:function(){return [_c('v-checkbox',{staticClass:"v-data-table__checkbox",attrs:{"value":isSelected},on:{"change":select,"click":function($event){$event.stopPropagation();}}})]},proxy:true},{key:"color",fn:function(ref){
            var item = ref.item;
return [(item.color)?_c('v-icon',{attrs:{"color":item.color},domProps:{"textContent":_vm._s(_vm.cubeIconSvg)}}):_vm._e()]}},{key:"image",fn:function(ref){
            var item = ref.item;
return [_c('img',{staticStyle:{"height":"56px","width":"100px","background-color":"white","object-fit":"contain","margin-top":"4px","margin-bottom":"-3px"},attrs:{"src":item.image || _vm.defaultImage},on:{"error":function($event){item.image = _vm.defaultImage}}})]}},{key:"productTabelName",fn:function(ref){
            var item = ref.item;
return [(item.displayName)?[_vm._v(" "+_vm._s(item.displayName)+" ")]:[_c('i',[_vm._v(_vm._s(item.productName))])]]}},{key:"integrationStateIos",fn:function(ref){
            var item = ref.item;
return [(item.integrationStateIos === _vm.IntegrationState.DOING)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.codeIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.IN_DEVELOPMENT)))])]):(item.integrationStateIos === _vm.IntegrationState.DONE)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"},domProps:{"textContent":_vm._s(_vm.checkIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.DONE)))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},domProps:{"textContent":_vm._s(_vm.closeIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.TODO)))])])]}},{key:"integrationStateAndroid",fn:function(ref){
            var item = ref.item;
return [(item.integrationStateAndroid === _vm.IntegrationState.DOING)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.codeIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.IN_DEVELOPMENT)))])]):(item.integrationStateAndroid === _vm.IntegrationState.DONE)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"},domProps:{"textContent":_vm._s(_vm.checkIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.DONE)))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},domProps:{"textContent":_vm._s(_vm.closeIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.TODO)))])])]}},{key:"integrationStateWeb",fn:function(ref){
            var item = ref.item;
return [(item.integrationStateWeb === _vm.IntegrationState.DOING)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.codeIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.IN_DEVELOPMENT)))])]):(item.integrationStateWeb === _vm.IntegrationState.DONE)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"},domProps:{"textContent":_vm._s(_vm.checkIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.DONE)))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},domProps:{"textContent":_vm._s(_vm.closeIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.TODO)))])])]}},{key:"exportWebsite",fn:function(ref){
            var item = ref.item;
return [(item.exportWebsite === _vm.ExportState.STAGING)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.cloudLockIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.STAGING)))])]):(item.exportWebsite === _vm.ExportState.PUBLIC)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"},domProps:{"textContent":_vm._s(_vm.cloudCheckIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.PUBLIC)))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},domProps:{"textContent":_vm._s(_vm.lockIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.PRIVATE)))])])]}},{key:"exportApps",fn:function(ref){
            var item = ref.item;
return [(item.exportApps === _vm.ExportState.STAGING)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.cloudLockIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.INVISIBLE_EXPORT)))])]):(item.exportApps === _vm.ExportState.PUBLIC)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"},domProps:{"textContent":_vm._s(_vm.cloudCheckIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.VISIBLE_EXPORT)))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},domProps:{"textContent":_vm._s(_vm.lockIconSvg)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.NO_EXPORT)))])])]}},{key:"productIcon",fn:function(ref){
            var item = ref.item;
return [_c('img',{staticClass:"lightup-icons",staticStyle:{"height":"56px","margin-bottom":"-7px"},attrs:{"src":item.productIcon}})]}},{key:"icon",fn:function(ref){
            var item = ref.item;
return [_c('img',{staticClass:"lightup-icons",staticStyle:{"height":"56px","margin-bottom":"-7px"},attrs:{"src":item.icon}})]}}],null,true)})]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }