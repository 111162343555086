import type { Document, DocTypes, DocsFilter } from './document';
import { FieldTypes, DetailData } from '../typings/field';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import { persistentDocumentsStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface UiElement extends Document {
  _docType: DocTypes.UiElement;
  label: string;
  explanationDev: string | null;
}

export const defaultListProperties: string = `
  label
`;

export const defaultDetailProperties: string = `
  label
  explanationDev
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.LABEL),
      value: 'label',
      align: 'start',
      sortable: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: UiElement[] = Object.values(
    persistentDocumentsStore.UiElement
  );
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (
      document: UiElement
    ): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      label: document.label
    })
  );
}

export const sortListBy: string | string[] = 'label';

export async function getCategories(
  document: Partial<UiElement>
): Promise<DetailData> {
  return {
    title: document.label,
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.LABEL),
            required: true,
            model: 'label',
            value: document.label
          },
          {
            type: FieldTypes.MULTILINE,
            label: I18n.get(Translations.EXPLANATION_DEV),
            required: false,
            model: 'explanationDev',
            value: document.explanationDev
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.LABEL),
      property: 'label'
    },
    {
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      label: I18n.get(Translations.EXPLANATION_DEV),
      property: 'explanationDev'
    }
  ];
}
