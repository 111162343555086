var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"sticky top"},[_vm._v(" "+_vm._s(_vm.$I18n.get(_vm.$Translations.FILTER_LIST))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addFilter}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.plusIconSvg)}}),_vm._v(" "+_vm._s(_vm.$I18n.get(_vm.$Translations.ADD_FILTER))+" ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","disabled":!_vm.filters.length},on:{"click":_vm.removeAllFilter}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.closeIconSvg)}}),_vm._v(" "+_vm._s(_vm.$I18n.get(_vm.$Translations.DELETE_ALL_FILTER))+" ")],1)],1),_c('v-card-text',[(_vm.filters.length)?_c('v-list',_vm._l((_vm.filters),function(filter,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-row',{attrs:{"align":"end","align-content":"start","justify-content":"start","no-gutters":""}},[_c('v-col',{staticClass:"mr-2",staticStyle:{"min-width":"250px","width":"250px","max-width":"250px"}},[_c('v-select',{attrs:{"items":_vm.getAvailableProperties(filter.property),"placeholder":_vm.$I18n.get(_vm.$Translations.FIELD)},model:{value:(filter.property),callback:function ($$v) {_vm.$set(filter, "property", $$v)},expression:"filter.property"}})],1),_c('v-col',{staticClass:"mr-2",staticStyle:{"min-width":"175px","width":"175px","max-width":"175px"}},[_c('v-select',{attrs:{"items":_vm.getAvailableOperators(filter.property, filter.operator),"placeholder":_vm.$I18n.get(_vm.$Translations.OPERATOR),"disabled":!filter.property},on:{"change":function($event){filter.value = null}},model:{value:(filter.operator),callback:function ($$v) {_vm.$set(filter, "operator", $$v)},expression:"filter.operator"}})],1),_c('v-col',{staticClass:"mr-2",attrs:{"align-self":"stretch"}},[(['exists', 'notExists'].includes(filter.operator))?void 0:(
                  filter.operator &&
                  _vm.getAvailableFilter(filter.property).isBoolean
                )?_c('v-checkbox',{model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):(
                  !_vm.getAvailableFilter(filter.property).options &&
                  [
                    'eq',
                    'ne',
                    'le',
                    'lt',
                    'ge',
                    'gt',
                    'contains',
                    'notContains'
                  ].includes(filter.operator)
                )?_c('v-text-field',{attrs:{"value":filter.value,"placeholder":_vm.$I18n.get(_vm.$Translations.VALUE)},on:{"input":function($event){filter.value = _vm.getAvailableFilter(filter.property).upperCase
                    ? ($event || '').toUpperCase()
                    : $event},"keydown":function($event){_vm.validateKey(
                    _vm.getAvailableFilter(filter.property).validator,
                    $event
                  )},"paste":function($event){_vm.validatePaste(
                    _vm.getAvailableFilter(filter.property).validator,
                    $event
                  )},"drop":function($event){_vm.validateDrop(
                    _vm.getAvailableFilter(filter.property).validator,
                    $event
                  )}}}):(filter.operator === 'between')?[_c('v-text-field',{attrs:{"value":(filter.value || [null, null])[0],"placeholder":_vm.$I18n.get(_vm.$Translations.VALUE)},on:{"input":function($event){filter.value
                      ? filter.value.splice(0, 1, $event)
                      : (filter.value = [$event, null])},"keydown":function($event){_vm.validateKey(
                      _vm.getAvailableFilter(filter.property).validator,
                      $event
                    )},"paste":function($event){_vm.validatePaste(
                      _vm.getAvailableFilter(filter.property).validator,
                      $event
                    )},"drop":function($event){_vm.validateDrop(
                      _vm.getAvailableFilter(filter.property).validator,
                      $event
                    )}}}),_c('v-text-field',{attrs:{"value":(filter.value || [null, null])[1],"placeholder":_vm.$I18n.get(_vm.$Translations.VALUE)},on:{"input":function($event){filter.value
                      ? filter.value.splice(1, 1, $event)
                      : (filter.value = [null, $event])},"keydown":function($event){_vm.validateKey(
                      _vm.getAvailableFilter(filter.property).validator,
                      $event
                    )},"paste":function($event){_vm.validatePaste(
                      _vm.getAvailableFilter(filter.property).validator,
                      $event
                    )},"drop":function($event){_vm.validateDrop(
                      _vm.getAvailableFilter(filter.property).validator,
                      $event
                    )}}})]:(
                  !_vm.getAvailableFilter(filter.property).options &&
                  ['in', 'all', 'any', 'none'].includes(filter.operator)
                )?_c('v-combobox',{attrs:{"placeholder":_vm.$I18n.get(_vm.$Translations.VALUES),"multiple":"","append-icon":"","chips":"","small-chips":"","deletable-chips":"","hide-no-data":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):(
                  _vm.getAvailableFilter(filter.property).options &&
                  ['eq', 'ne', 'in', 'all', 'any', 'none'].includes(
                    filter.operator
                  )
                )?_c('v-autocomplete',{attrs:{"placeholder":_vm.$I18n.get(
                    ['in', 'all', 'any', 'none'].includes(filter.operator)
                      ? _vm.$Translations.VALUES
                      : _vm.$Translations.VALUE
                  ),"multiple":['in', 'all', 'any', 'none'].includes(filter.operator),"item-text":_vm.getAvailableFilter(filter.property).textField,"item-value":_vm.getAvailableFilter(filter.property).valueField,"items":_vm.getAvailableFilter(filter.property).options,"chips":"","small-chips":"","deletable-chips":""},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e()],2)],1)],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeFilter(i)}}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.closeIconSvg)}})],1)],1)],1)}),1):_c('div',{staticClass:"mt-5",staticStyle:{"text-align":"center"},domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.NO_FILTERS))}})],1),_c('v-card-actions',{staticClass:"sticky bottom"},[_c('v-spacer'),_c('v-btn',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"color":"error","text":"","data-right":"AUTOFOCUS"},domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.ABORT))},on:{"click":function($event){$event.stopPropagation();return _vm.reset()}}}),_c('v-btn',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"color":"success","text":"","disabled":!_vm.isValid(),"data-left":"AUTOFOCUS","data-default":""},domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.SAVE))},on:{"click":function($event){$event.stopPropagation();return _vm.applyFilters()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }