import { Document, DocTypes, DocsFilter } from './document';
import type { AttributeType } from './attributeType';
import type { Localization } from './localization';
import { Translations } from '../plugins/i18n';
import { I18n } from '@aws-amplify/core';
import { FieldTypes, DetailData } from '../typings/field';
import { persistentDocumentsStore, userStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface AttributeValue extends Document {
  _docType: DocTypes.AttributeValue;
  attributeType: AttributeType;
  value: string;
  meaning: string;
  display: Localization | null;
  command: Localization | null;
  triggerDescription: Localization | null;
  triggerBrick: Localization | null;
  conditionDescription: Localization | null;
  conditionBrick: Localization | null;
  conditionDescriptionNot: Localization | null;
  conditionBrickNot: Localization | null;
  actionDescription: Localization | null;
  actionBrick: Localization | null;
  isTrigger: boolean | null;
  isCondition: boolean | null;
  isAction: boolean | null;
}

export const defaultListProperties: string = `
  attributeType {
    _id
  }
  value
  display {
    _id
  }
  meaning
`;

export const defaultDetailProperties: string = `
  attributeType {
    _id
  }
  value
  meaning
  display {
    _id
  }
  command {
    _id
  }
  triggerDescription {
    _id
  }
  triggerBrick {
    _id
  }
  conditionDescription {
    _id
  }
  conditionBrick {
    _id
  }
  conditionDescriptionNot {
    _id
  }
  conditionBrickNot {
    _id
  }
  actionDescription {
    _id
  }
  actionBrick {
    _id
  }
  isTrigger
  isCondition
  isAction
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.ATTRIBUTETYPE),
      value: 'attributeType',
      align: 'start',
      sortable: true,
      sort: (item1: string, item2: string): number =>
        parseInt((item1 ?? '').match(/.*\((\d+)\)/)?.[1] ?? '') -
        parseInt((item2 ?? '').match(/.*\((\d+)\)/)?.[1] ?? '')
    },
    {
      text: I18n.get(Translations.VALUE),
      value: 'value',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.DISPLAY),
      value: 'display',
      align: 'start',
      sortable: true
    },
    {
      text: '',
      value: 'displayKey',
      hidden: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: AttributeValue[] = Object.values(
    persistentDocumentsStore.AttributeValue
  );
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (
      document: AttributeValue
    ): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      attributeType: `${
        persistentDocumentsStore.AttributeType[
          document.attributeType?._id ?? ''
        ]?.name || 'unknown'
      }, ${
        persistentDocumentsStore.AttributeType[
          document.attributeType?._id ?? ''
        ]?.number || -1
      }`,
      value: document.value ?? '',
      display:
        persistentDocumentsStore.Localization[document.display?._id ?? '']?.[
          userStore.locale
        ],
      displayKey:
        persistentDocumentsStore.Localization[document.display?._id ?? '']
          ?.stringIdentifier
    })
  );
}

export const sortListBy: string | string[] = ['attributeType', 'value'];

export const defaults: Partial<AttributeValue> = {
  isTrigger: false,
  isCondition: false,
  isAction: false
};

export async function getCategories(
  document: Partial<AttributeValue>
): Promise<DetailData> {
  return {
    title: document.value,
    subtitle:
      persistentDocumentsStore.AttributeType[
        document?.attributeType?._id ||
          ((document?.attributeType as unknown) as string)
      ]?.name || '',
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ATTRIBUTETYPE),
            required: true,
            model: 'attributeType',
            value: document.attributeType,
            docType: DocTypes.AttributeType,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[
                persistentDocumentsStore.AttributeType[item._id]?.displayName
                  ?._id ?? ''
              ]?.[userStore.locale] || 'unknown',
            additionalText: (item: Document): string => {
              const doc: AttributeType =
                persistentDocumentsStore.AttributeType[item._id];
              return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
            },
            valueField: '_id',
            options: Object.values(persistentDocumentsStore.AttributeType).sort(
              (item1: AttributeType, item2: AttributeType): number =>
                item1.number - item2.number
            ),
            multiple: false
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.VALUE),
            required: true,
            model: 'value',
            value: document.value
          },
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.MEANING),
            required: true,
            model: 'meaning',
            value: document.meaning
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.DISPLAY),
            required: false,
            model: 'display',
            value: document.display,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier.toUpperCase().includes('_VALUE_') &&
                !loc.stringIdentifier.toUpperCase().includes('_TRIGGER_') &&
                !loc.stringIdentifier.toUpperCase().includes('_CONDITION_') &&
                !loc.stringIdentifier.toUpperCase().includes('_ACTION_') &&
                !loc.stringIdentifier.toUpperCase().endsWith('_COMMAND')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.COMMAND),
            required: false,
            model: 'command',
            value: document.command,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier.toUpperCase().includes('_VALUE_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_COMMAND')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.TRIGGER_DESCRIPTION),
            required: false,
            model: 'triggerDescription',
            value: document.triggerDescription,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier
                  .toUpperCase()
                  .includes('_TRIGGER_VALUE_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.TRIGGER_BRICK),
            required: false,
            model: 'triggerBrick',
            value: document.triggerBrick,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier
                  .toUpperCase()
                  .includes('_TRIGGER_VALUE_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_BRICK')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.CONDITION_DESCRIPTION),
            required: false,
            model: 'conditionDescription',
            value: document.conditionDescription,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier
                  .toUpperCase()
                  .includes('_CONDITION_VALUE_') &&
                !loc.stringIdentifier
                  .toUpperCase()
                  .includes('_CONDITION_VALUE_NOT_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.CONDITION_BRICK),
            required: false,
            model: 'conditionBrick',
            value: document.conditionBrick,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier
                  .toUpperCase()
                  .includes('_CONDITION_VALUE_') &&
                !loc.stringIdentifier
                  .toUpperCase()
                  .includes('_CONDITION_VALUE_NOT_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_BRICK')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.CONDITION_DESCRIPTION_NOT),
            required: false,
            model: 'conditionDescriptionNot',
            value: document.conditionDescriptionNot,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier
                  .toUpperCase()
                  .includes('_CONDITION_VALUE_NOT_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.CONDITION_BRICK_NOT),
            required: false,
            model: 'conditionBrickNot',
            value: document.conditionBrickNot,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier
                  .toUpperCase()
                  .includes('_CONDITION_VALUE_NOT_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_BRICK')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ACTION_DESCRIPTION),
            required: false,
            model: 'actionDescription',
            value: document.actionDescription,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier.toUpperCase().includes('_ACTION_VALUE_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
            ),
            multiple: false
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            label: I18n.get(Translations.ACTION_BRICK),
            required: false,
            model: 'actionBrick',
            value: document.actionBrick,
            docType: DocTypes.Localization,
            textField: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]?.[
                userStore.locale
              ] || 'unknown',
            valueField: '_id',
            additionalText: (item: Document): string =>
              persistentDocumentsStore.Localization[item._id]
                ?.stringIdentifier || 'unknown',
            options: Object.values(
              persistentDocumentsStore.Localization
            ).filter(
              (loc: Localization): boolean =>
                loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
                loc.stringIdentifier.toUpperCase().includes('_ACTION_VALUE_') &&
                loc.stringIdentifier.toUpperCase().endsWith('_BRICK')
            ),
            multiple: false
          },
          {
            type: FieldTypes.BOOLEAN,
            label: I18n.get(Translations.IS_TRIGGER),
            required: false,
            model: 'isTrigger',
            value: document.isTrigger
          },
          {
            type: FieldTypes.BOOLEAN,
            label: I18n.get(Translations.IS_CONDITION),
            required: false,
            model: 'isCondition',
            value: document.isCondition
          },
          {
            type: FieldTypes.BOOLEAN,
            label: I18n.get(Translations.IS_ACTION),
            required: false,
            model: 'isAction',
            value: document.isAction
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.ATTRIBUTETYPE),
      property: 'attributeType',
      textField: (item: Document): string => {
        const doc: AttributeType =
          persistentDocumentsStore.AttributeType[item._id];
        return `${doc?.name || 'unknown'}, ${doc?.number || -1}`;
      },
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.AttributeType).sort(
        (item1: AttributeType, item2: AttributeType): number =>
          item1.number - item2.number
      )
    },
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.VALUE),
      property: 'value'
    },
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.MEANING),
      property: 'meaning'
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.DISPLAY),
      property: 'display',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier.toUpperCase().includes('_VALUE_') &&
          !loc.stringIdentifier.toUpperCase().includes('_TRIGGER_') &&
          !loc.stringIdentifier.toUpperCase().includes('_CONDITION_') &&
          !loc.stringIdentifier.toUpperCase().includes('_ACTION_') &&
          !loc.stringIdentifier.toUpperCase().endsWith('_COMMAND')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.COMMAND),
      property: 'command',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier.toUpperCase().includes('_VALUE_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_COMMAND')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.TRIGGER_DESCRIPTION),
      property: 'triggerDescription',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier.toUpperCase().includes('_TRIGGER_VALUE_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.TRIGGER_BRICK),
      property: 'triggerBrick',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier.toUpperCase().includes('_TRIGGER_VALUE_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_BRICK')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.CONDITION_DESCRIPTION),
      property: 'conditionDescription',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier.toUpperCase().includes('_CONDITION_VALUE_') &&
          !loc.stringIdentifier
            .toUpperCase()
            .includes('_CONDITION_VALUE_NOT_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.CONDITION_BRICK),
      property: 'conditionBrick',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier.toUpperCase().includes('_CONDITION_VALUE_') &&
          !loc.stringIdentifier
            .toUpperCase()
            .includes('_CONDITION_VALUE_NOT_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_BRICK')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.CONDITION_DESCRIPTION_NOT),
      property: 'conditionDescriptionNot',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier
            .toUpperCase()
            .includes('_CONDITION_VALUE_NOT_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.CONDITION_BRICK_NOT),
      property: 'conditionBrickNot',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier
            .toUpperCase()
            .includes('_CONDITION_VALUE_NOT_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_BRICK')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.ACTION_DESCRIPTION),
      property: 'actionDescription',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier.toUpperCase().includes('_ACTION_VALUE_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_DESCRIPTION')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists', 'in'],
      label: I18n.get(Translations.ACTION_BRICK),
      property: 'actionBrick',
      textField: (item: Document): string =>
        persistentDocumentsStore.Localization[item._id]?.[userStore.locale] ||
        'unknown',
      valueField: '_id',
      options: Object.values(persistentDocumentsStore.Localization).filter(
        (loc: Localization): boolean =>
          loc.stringIdentifier.toUpperCase().startsWith('ATTRIBUTE_') &&
          loc.stringIdentifier.toUpperCase().includes('_ACTION_VALUE_') &&
          loc.stringIdentifier.toUpperCase().endsWith('_BRICK')
      )
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists'],
      label: I18n.get(Translations.IS_TRIGGER),
      property: 'isTrigger',
      isBoolean: true
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists'],
      label: I18n.get(Translations.IS_CONDITION),
      property: 'isCondition',
      isBoolean: true
    },
    {
      operators: ['eq', 'ne', 'exists', 'notExists'],
      label: I18n.get(Translations.IS_ACTION),
      property: 'isAction',
      isBoolean: true
    }
  ];
}
