/* eslint-disable @typescript-eslint/no-explicit-any */

const awsmobile: any = {
  aws_cognito_region: process.env.REGION,
  aws_user_pools_mfa_type: 'OFF',
  aws_user_pools_id: process.env.USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.IDENTITY_POOL_ID,
  aws_appsync_graphqlEndpoint: process.env.GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files: 'enable',
  aws_user_files_s3_bucket: process.env.S3_BUCKET,
  aws_user_files_s3_bucket_region: process.env.REGION
};

export default awsmobile;
