













































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ObjectEditorComponent from './ObjectEditorComponent.vue';
import CodeEditorComponent from './CodeEditorComponent.vue';
import FileEditorComponent from './FileEditorComponent.vue';
import ImageEditorComponent from './ImageEditorComponent.vue';
import VueMarkdown from 'vue-markdown';
import {
  FieldTypes,
  Field,
  FileTypes,
  ValidationRules,
  CodeEditorMode,
  TextField,
  AutocompleteField
} from '../typings/field';
import {
  mdiCheckBold,
  mdiCloseThick,
  mdiPlus,
  mdiContentCopy,
  mdiInformationOutline
} from '@mdi/js';
import { isEmpty } from '../api';
import type { Document } from '../api/document';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import { Bind, Debounce } from 'lodash-decorators';

@Component({
  components: {
    ObjectEditorComponent,
    CodeEditorComponent,
    FileEditorComponent,
    ImageEditorComponent,
    VueMarkdown
  }
})
export default class FieldComponent extends Vue {
  private readonly plusIconSvg: string = mdiPlus;
  private readonly closeIconSvg: string = mdiCloseThick;
  private readonly checkIconSvg: string = mdiCheckBold;
  private readonly copyIconSvg: string = mdiContentCopy;
  private readonly infoIconSvg: string = mdiInformationOutline;
  private readonly FieldTypes: typeof FieldTypes = FieldTypes;
  private readonly FileTypes: typeof FileTypes = FileTypes;
  private readonly CodeEditorMode: typeof CodeEditorMode = CodeEditorMode;

  private searchInput: string | null = null;
  private cursorPos: number | null = null;
  private cursorTarget: HTMLInputElement | null = null;

  private rules: ValidationRules = [];

  private numberValidator(data: string): boolean {
    return !isNaN(parseInt(data));
  }

  @Prop({ type: Boolean, default: false })
  private readonly edit!: boolean;

  @Prop({
    type: Object,
    default: {
      type: FieldTypes.NONE,
      label: 'Unknown',
      hideInViewMode: false,
      hideInEditMode: true
    }
  })
  private readonly field!: Field;

  public hasError: boolean = false;

  @Watch('field.value', { deep: true })
  private updateHasError(): void {
    this.hasError = ([] as Vue[])
      .concat(
        (this.$refs?.field || []) as Vue | Vue[],
        (this.$refs?.dropdown || []) as Vue | Vue[]
      )
      .some((field: Vue & { hasError?: boolean }): boolean => !!field.hasError);
  }

  @Watch('field.value', { deep: true })
  private setCursorPos(): void {
    if (this.cursorTarget)
      this.$nextTick((): void =>
        this.cursorTarget?.setSelectionRange(
          this.cursorPos || 0,
          this.cursorPos || 0
        )
      );
  }

  private validateKey(
    validator: (data: string) => boolean,
    event: KeyboardEvent
  ): void {
    if (!validator) {
      return;
    }
    const isForbiddenChar: boolean =
      event.key.length === 1 && !validator(event.key);
    const isAllowedModifier: boolean = event.ctrlKey || event.metaKey;
    if (isForbiddenChar && !isAllowedModifier) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validatePaste(
    validator: (data: string) => boolean,
    event: ClipboardEvent
  ): void {
    if (!validator) {
      return;
    }
    if (
      !event.clipboardData ||
      !validator(event.clipboardData.getData('text/plain'))
    ) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validateDrop(
    validator: (data: string) => boolean,
    event: DragEvent
  ): void {
    if (!validator) {
      return;
    }
    if (
      !event.dataTransfer ||
      !validator(event.dataTransfer.getData('text/plain'))
    ) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private handleInput(e: Event, field: TextField): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    let $event: string | null = target.value;
    this.cursorPos = target.selectionStart;
    this.cursorTarget = target;
    if ($event) {
      $event =
        field.prefix &&
        $event.toUpperCase().includes(field.prefix.toUpperCase())
          ? $event.slice(field.prefix.length)
          : $event;
      $event = field.prefix ? field.prefix + $event : $event;
      $event = field.upperCase ? $event.toUpperCase() : $event;
    }
    this.$emit('input', $event);
  }

  private getValidationRules(field: Field): ValidationRules {
    const rules: ValidationRules = field.rules || [];
    if (field.required) {
      rules.push(
        (v: unknown): true | string =>
          !isEmpty(v) || I18n.get(Translations.REQUIRED)
      );
    }
    if (Array.isArray(field.unique)) {
      rules.push(
        (v: unknown): true | string =>
          !field.unique?.includes(v) || I18n.get(Translations.UNIQUE)
      );
    }
    return rules;
  }

  private copyToClipBoardSnackbar: {
    show: boolean;
    theme: string;
    text: string;
  } = {
    show: false,
    theme: 'primary',
    text: ''
  };

  private copyToClipBoard(text: string): void {
    if (text) {
      text = text.split(',')[0];
      navigator.clipboard
        .writeText(text)
        .then((): void => {
          this.copyToClipBoardSnackbar.theme = 'primary';
          this.copyToClipBoardSnackbar.text = I18n.get(
            Translations.COPIED_TO_CLIPBOARD
          );
          this.copyToClipBoardSnackbar.show = true;
        })
        .catch((): void => {
          this.copyToClipBoardSnackbar.theme = 'error';
          this.copyToClipBoardSnackbar.text = I18n.get(
            Translations.COPY_TO_CLIPBOARD_FAILED
          );
          this.copyToClipBoardSnackbar.show = true;
        });
    }
  }

  private mounted(): void {
    this.rules = this.getValidationRules(this.field);
  }

  private autocompleteFilter(
    item: unknown,
    queryText: string,
    itemText: string
  ): boolean {
    return (
      (itemText ?? '').toLowerCase().includes(queryText.toLowerCase()) ||
      JSON.stringify(item ?? '')
        .toLowerCase()
        .includes(queryText.toLowerCase())
    );
  }

  private getSortedItems(): Array<Document> {
    const field: AutocompleteField = this.field as AutocompleteField;
    const items: Array<Document & { number: number }> = ((field.options ||
      []) as Array<Document & { number: number }>)
      .concat(this.field.value as Document & { number: number })
      .filter(Boolean);
    if (
      !this.searchInput ||
      field.type !== FieldTypes.AUTOCOMPLETE ||
      typeof items[0]?.number !== 'number'
    )
      return items as Document[];
    const searchNumber: number = parseInt(this.searchInput, 10);
    items.sort(
      (
        a: Document & { number: number },
        b: Document & { number: number }
      ): number =>
        Math.abs(a.number - searchNumber) > Math.abs(b.number - searchNumber)
          ? 1
          : -1
    );
    return items;
  }

  @Debounce(50)
  @Bind()
  private updateSearchInput(e: string): void {
    this.searchInput = e;
  }

  private parseInput(
    delimiters: string[] | undefined,
    transform: ((data: string) => string) | undefined,
    multiple: boolean,
    data: null | string | string[]
  ): void {
    const value: string[] = ([] as string[]).concat(data || []);
    if (!value.length) {
      this.$emit('input', null);
      return;
    }
    if (!delimiters || !delimiters.length) {
      this.$emit('input', multiple ? value : value[0]);
      return;
    }
    const regExp: RegExp = new RegExp(`[${delimiters.join()}]`);
    const cleanedValue: string[] = [
      ...new Set(
        value
          .reduce(
            (all: string[], curr: string): string[] => [
              ...all,
              ...curr.split(regExp)
            ],
            []
          )
          .map((data: string): string => (transform ? transform(data) : data))
      )
    ].filter(Boolean);
    if (cleanedValue.length) {
      this.$emit('input', multiple ? cleanedValue : cleanedValue[0]);
    } else {
      this.$emit('input', null);
    }
  }

  public closeMenu(): void {
    if ((this.$refs?.dropdown as { isMenuActive?: boolean })?.isMenuActive) {
      (this.$refs.dropdown as { isMenuActive?: boolean }).isMenuActive = false;
    }
  }
}
