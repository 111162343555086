












































































































































import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator';
import {
  mdiOpenInNew,
  mdiTrashCan,
  mdiFileDocument,
  mdiFileTable
} from '@mdi/js';
import mime from 'mime-types';
import { FileTypes, ValidationRule, ValidationRules } from '../typings/field';
import type { DocsFile } from '../api/document';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import _ from 'lodash';

@Component
export default class FileEditorComponent extends Vue {
  private readonly openIcon: string = mdiOpenInNew;
  private readonly trashIcon: string = mdiTrashCan;
  private readonly fileIconSvg: string = mdiFileDocument;
  private readonly tableIconSvg: string = mdiFileTable;
  private readonly FileTypes: typeof FileTypes = FileTypes;

  private errors: string[] = [];

  @Prop({ type: [String, Boolean], default: null })
  private hint!: string | null;

  public get hasError(): boolean {
    return !!this.errors.length;
  }

  @Inject({ default: null }) readonly form!: {
    register: (input: Vue) => void;
    unregister: (input: Vue) => void;
  } | null;

  @Prop({ type: [Object, Array], default: null })
  public readonly value!: DocsFile | DocsFile[] | null;

  @Prop({
    type: Array,
    default: (): ValidationRules => []
  })
  private readonly rules!: ValidationRules;

  @Prop({ type: String, default: '' })
  public readonly label!: string;

  @Prop({ type: [String, Array], default: (): string[] => [] })
  public readonly link!: string | string[];

  @Prop({ type: String, default: 'center' })
  public readonly justify!: string;

  @Prop({ type: Number, default: 128 })
  public readonly iconSize!: number;

  @Prop({ type: String, default: FileTypes.DOCUMENT })
  public readonly fileType!: FileTypes;

  @Prop({ type: String })
  public readonly mimeType: string | undefined;

  @Prop({ type: Boolean, default: true })
  public readonly multiple!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;

  @Watch('disabled')
  private onDisabledChanged(): void {
    this.errors = [];
  }

  private get files(): DocsFile[] {
    return ([] as DocsFile[]).concat(this.value || []);
  }

  private get links(): string[] {
    return Array.isArray(this.link)
      ? this.link
      : new Array(this.files.length).fill(this.link);
  }

  private created(): void {
    this.form && this.form.register(this);
  }

  private beforeDestroy(): void {
    this.form && this.form.unregister(this);
  }

  private getFileName(file: DocsFile): string {
    return `${file.name}${file.extension ? `.${file.extension}` : ''}`;
  }

  private get dropZoneText(): string {
    let dropZoneText: string = '';
    switch (this.fileType) {
      case FileTypes.DOCUMENT:
        dropZoneText = I18n.get(
          this.multiple
            ? Translations.FILE_UPLOAD_DROPZONE_PDF_MULTI
            : Translations.FILE_UPLOAD_DROPZONE_PDF_SINGLE
        );
        break;
      case FileTypes.TABLE:
        dropZoneText = I18n.get(
          this.multiple
            ? Translations.FILE_UPLOAD_DROPZONE_XLSX_MULTI
            : Translations.FILE_UPLOAD_DROPZONE_XLSX_SINGLE
        );
        break;
    }
    if (dropZoneText) {
      return `${dropZoneText}\n${I18n.get(Translations.FILE_UPLOAD_DROPZONE)}`;
    }
    return I18n.get(Translations.FILE_UPLOAD_DROPZONE);
  }

  private get mimeTypeFilter(): string {
    if (this.mimeType) {
      return this.mimeType;
    }
    switch (this.fileType) {
      case FileTypes.DOCUMENT:
        return 'application/pdf';
      case FileTypes.TABLE:
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return '*/*';
    }
  }

  private async addFile(changeEvent: Event): Promise<void> {
    const allowedMimeType: string = this.mimeTypeFilter.replaceAll('*', '');
    const files: File[] = Array.from(
      (changeEvent?.target as HTMLInputElement)?.files || []
    ).filter(
      (file: File): boolean =>
        file &&
        (allowedMimeType === '/' || file.type.startsWith(allowedMimeType))
    );
    if (files.length < 1) {
      (this.$refs.fileInput as HTMLInputElement).value = '';
      return;
    }
    const version: number = new Date().getTime();
    if (!this.multiple) {
      const url: string = URL.createObjectURL(files[0]);
      const value: DocsFile = {
        name: files[0].name.split('.').slice(0, -1).join('_'),
        extension: mime.extension(files[0].type) || undefined,
        size: files[0].size,
        url,
        version
      };
      this.errors = this.rules.reduce(
        (errors: string[], rule: ValidationRule): string[] => {
          const error: boolean | string = rule(value);
          if (typeof error === 'string') {
            errors.push(error);
            value.error = true;
          }
          return errors;
        },
        []
      );
      this.$emit('input', value);
    } else {
      let values: DocsFile[] = [
        ...this.files,
        ...(await Promise.all(
          Array.from(files).map(
            async (file: File): Promise<DocsFile> => {
              const url: string = URL.createObjectURL(file);
              return {
                name: file.name.split('.').slice(0, -1).join('_'),
                extension: mime.extension(file.type) || undefined,
                size: file.size,
                url,
                version
              };
            }
          )
        ))
      ];
      const errors: string[] = [];
      values = values.map(
        (value: DocsFile): DocsFile => {
          this.rules.forEach((rule: ValidationRule): void => {
            const error: boolean | string = rule(value);
            if (typeof error === 'string') {
              errors.push(error);
              value.error = true;
            }
          });
          return value;
        }
      );
      this.errors = _.uniq(errors);
      this.$emit('input', values);
    }
    (this.$refs.fileInput as HTMLInputElement).value = '';
  }

  private removeFile(removeFile: DocsFile): void {
    let remainingFiles: DocsFile[] = this.files.filter(
      (remainingFile: DocsFile): boolean =>
        remainingFile.name !== removeFile.name
    );
    if (removeFile.url && removeFile.url.startsWith('blob:')) {
      URL.revokeObjectURL(removeFile.url);
    }
    if (remainingFiles.length < 1) {
      this.errors = this.rules.reduce(
        (errors: string[], rule: ValidationRule): string[] => {
          const error: boolean | string = rule(null);
          if (typeof error === 'string') {
            errors.push(error);
          }
          return errors;
        },
        []
      );
      this.$emit('input', null);
    } else {
      const errors: string[] = [];
      remainingFiles = remainingFiles.map(
        (value: DocsFile): DocsFile => {
          this.rules.forEach((rule: ValidationRule): void => {
            const error: boolean | string = rule(value);
            if (typeof error === 'string') {
              errors.push(error);
              value.error = true;
            }
          });
          return value;
        }
      );
      this.errors = _.uniq(errors);
      this.$emit('input', remainingFiles);
    }
  }

  private openFile(file: DocsFile, index: number): void {
    window.open(this.links[index] || file.url, '_blank');
  }
}
