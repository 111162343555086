







































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { userStore } from '../plugins/store';
import { Translations } from '../plugins/i18n';
import { I18n } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import type { ValidationRules } from '../typings/field';

let signupDomains: string[] = ['codeatelier.com'];
if (process.env.SIGNUP_DOMAINS) {
  if (Array.isArray(process.env.SIGNUP_DOMAINS)) {
    signupDomains = process.env.SIGNUP_DOMAINS;
  } else if (typeof process.env.SIGNUP_DOMAINS === 'string') {
    signupDomains = process.env.SIGNUP_DOMAINS.split(',');
  }
}

enum AuthState {
  Login = 'Login',
  Register = 'Register',
  ConfirmUser = 'ConfirmUser',
  Reset = 'Reset',
  ConfirmReset = 'ConfirmReset'
}

const ALERT_TIMEOUT: number = 10000;

@Component
export default class AuthView extends Vue {
  private readonly AuthState: typeof AuthState = AuthState;
  private readonly emailPattern: string =
    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
  private readonly nameRules: ValidationRules = [
    (value: string): boolean | string =>
      !!value || I18n.get(Translations.REQUIRED),
    (value: string): boolean | string =>
      value.length <= 32 || I18n.get(Translations.TOO_LONG)
  ];
  private readonly emailRules: ValidationRules = [
    (value: string): boolean | string =>
      !!value || I18n.get(Translations.REQUIRED),
    (value: string): boolean | string =>
      value.length <= 32 || I18n.get(Translations.TOO_LONG),
    (value: string): boolean | string =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ||
      I18n.get(Translations.INVALID_MAIL_ADDRESS),
    (value: string): boolean | string =>
      signupDomains.some((domain: string): boolean => value.endsWith(domain)) ||
      I18n.get(Translations.DOMAIN_NOT_ALLOWED)
  ];
  private readonly passwordRules: ValidationRules = [
    (value: string): boolean | string =>
      !!value || I18n.get(Translations.REQUIRED),
    (value: string): boolean | string =>
      value.length >= 8 || I18n.get(Translations.TOO_SHORT),
    (value: string): boolean | string =>
      value.length <= 99 || I18n.get(Translations.TOO_LONG),
    (value: string): boolean | string =>
      /[a-z]/.test(value) || I18n.get(Translations.REQUIRE_LOWERCASE),
    (value: string): boolean | string =>
      /[A-Z]/.test(value) || I18n.get(Translations.REQUIRE_UPPERCASE),
    (value: string): boolean | string =>
      /\d/.test(value) || I18n.get(Translations.REQUIRE_NUMBERS),
    (value: string): boolean | string =>
      /[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/.test(value) ||
      I18n.get(Translations.REQUIRE_SYMBOLS)
  ];
  private readonly codeRules: ValidationRules = [
    (value: string): boolean | string =>
      !!value || I18n.get(Translations.REQUIRED),
    (value: string): boolean | string =>
      /^\d+$/.test(value) || I18n.get(Translations.ONLY_NUMBERS),
    (value: string): boolean | string =>
      value.length >= 6 || I18n.get(Translations.TOO_SHORT),
    (value: string): boolean | string =>
      value.length <= 6 || I18n.get(Translations.TOO_LONG)
  ];
  private currentAuthState: AuthState = AuthState.Login;
  private valid: boolean = false;
  private loading: boolean = false;
  private success: string = '';
  private error: string = '';
  private name: string = '';
  private email: string = '';
  private password: string = '';
  private code: string = '';

  @Watch('currentAuthState')
  onAuthStateChange(val: AuthState, oldVal: AuthState): void {
    if (val === oldVal) {
      return;
    }
    this.code = '';
    if (val === AuthState.ConfirmReset) {
      this.password = '';
    }
  }

  private validateKeyCode(event: KeyboardEvent): void {
    const isForbiddenChar: boolean =
      event.key != undefined &&
      event.key.length === 1 &&
      isNaN(parseInt(event.key));
    const isAllowedModifier: boolean = event.ctrlKey || event.metaKey;
    if (isForbiddenChar && !isAllowedModifier) {
      event.preventDefault();
    }
  }

  private validatePasteCode(event: ClipboardEvent): void {
    event.preventDefault();
    if (!event.clipboardData) {
      return;
    }
    const data: string = event.clipboardData.getData('text/plain');
    if (/^\D*\d+\D*$/.test(data)) {
      this.code = data.replace(/[^\d]/g, '');
    }
  }

  private validateDropCode(event: DragEvent): void {
    event.preventDefault();
    if (!event.dataTransfer) {
      return;
    }
    const data: string = event.dataTransfer.getData('text/plain');
    if (/^\D*\d+\D*$/.test(data)) {
      this.code = data.replace(/[^\d]/g, '');
    }
  }

  private showError(message: string): void {
    this.error = message;
    setTimeout((): void => void (this.error = ''), ALERT_TIMEOUT);
  }

  private showSuccess(message: string): void {
    this.success = message;
    setTimeout((): void => void (this.success = ''), ALERT_TIMEOUT);
  }

  private login(): void {
    if (!this.valid) {
      return;
    }
    this.loading = true;
    Auth.signIn({
      username: this.email,
      password: this.password
    }).catch((error: { code: string; message: string; name: string }): void => {
      this.loading = false;
      if (error.code === 'UserNotConfirmedException') {
        this.currentAuthState = AuthState.ConfirmUser;
        this.resend();
      } else if (error.code === 'PasswordResetRequiredException') {
        this.currentAuthState = AuthState.ConfirmReset;
        this.reset();
      } else {
        this.showError(error.message);
      }
    });
  }

  private register(): void {
    if (!this.valid) {
      return;
    }
    this.loading = true;
    Auth.signUp({
      username: this.email,
      password: this.password,
      attributes: {
        name: this.name,
        locale: userStore.locale
      }
    })
      .then((): void => {
        this.currentAuthState = AuthState.ConfirmUser;
        this.showSuccess(I18n.get(Translations.CODE_SENT));
      })
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private confirmUser(): void {
    if (!this.valid) {
      return;
    }
    this.loading = true;
    Auth.confirmSignUp(this.email, this.code)
      .then((): void => {
        this.currentAuthState = AuthState.Login;
        this.login();
      })
      .catch((error: Error): void => {
        this.loading = false;
        this.showError(error.message);
      });
  }

  private resend(): void {
    if (!this.email) {
      return;
    }
    Auth.resendSignUp(this.email)
      .then((): void => this.showSuccess(I18n.get(Translations.CODE_SENT)))
      .catch((error: Error): void => this.showError(error.message));
  }

  private reset(): void {
    if (!this.email) {
      return;
    }
    this.loading = true;
    Auth.forgotPassword(this.email)
      .then((): void => {
        this.currentAuthState = AuthState.ConfirmReset;
        this.showSuccess(I18n.get(Translations.CODE_SENT));
      })
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private confirmReset(): void {
    if (!this.valid) {
      return;
    }
    this.loading = true;
    Auth.forgotPasswordSubmit(this.email, this.code, this.password)
      .then((): void => {
        this.currentAuthState = AuthState.Login;
        this.login();
      })
      .catch((error: Error): void => {
        this.loading = false;
        this.showError(error.message);
      });
  }
}
