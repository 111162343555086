import { Document, DocTypes, DocsFile, DocsFilter } from './document';
import { FieldTypes, DetailData, ImageTypes } from '../typings/field';
import { I18n } from '@aws-amplify/core';
import { Translations } from '../plugins/i18n';
import { persistentDocumentsStore } from '../plugins/store';
import type { MyDataTableHeader } from '../typings';
import { getImageDetails, ListFilter, Rows } from '.';
import _ from 'lodash';

export interface ProductIcon extends Document {
  _docType: DocTypes.ProductIcon;
  name: string;
  icon: DocsFile;
}

export const defaultListProperties: string = `
  name
  icon {
    name
    extension
    version
  }
`;

export const defaultDetailProperties: string = `
  name
  icon {
    name
    extension
    version
  }
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.NAME),
      value: 'name',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.PRODUCT_ICON),
      value: 'icon',
      align: 'center',
      sortable: false
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: ProductIcon[] = Object.values(
    persistentDocumentsStore.ProductIcon
  );
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (
      document: ProductIcon
    ): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      name: document.name,
      icon: document.icon
        ? `https://${
            process.env.S3_BUCKET
          }/${document._docType.toLocaleLowerCase()}/${document._id}/${
            document.icon.name
          }${
            document.icon.extension ? `.${document.icon.extension}` : ''
          }?version=${document.icon.version}`
        : null
    })
  );
}

export const sortListBy: string | string[] = 'name';

export async function getCategories(
  document: Partial<ProductIcon>
): Promise<DetailData> {
  const icon: DocsFile | null =
    document.icon && document._id
      ? !(document.icon?.url ?? '').startsWith('blob:') && document._id
        ? await getImageDetails(
            document._id,
            DocTypes.ProductIcon,
            document.icon
          )
        : document.icon
      : null;
  return {
    title: document.name,
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.NAME),
            required: true,
            model: 'name',
            value: document.name
          },
          {
            type: FieldTypes.IMAGE,
            label: I18n.get(Translations.PRODUCT_ICON),
            required: true,
            model: 'icon',
            value: icon,
            imageType: ImageTypes.ICON,
            multiple: false
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.NAME),
      property: 'name'
    },
    {
      operators: ['exists', 'notExists'],
      label: I18n.get(Translations.PRODUCT_ICON),
      property: 'icon'
    }
  ];
}
