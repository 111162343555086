var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",attrs:{"id":"scroll-container"}},[_c('v-row',{style:({ height: (_vm.containerHeight + "px") }),attrs:{"dense":"","justify":"center","align-content":"start"}},[_c('div',{style:({ width: '100%', height: (_vm.scrollOffset + "px") })}),_vm._l((_vm.renderedCards),function(card){return _c('v-card',{key:card.id,ref:"card",refInFor:true,staticClass:"card",attrs:{"width":_vm.cardWidth,"height":_vm.cardHeight}},[_c('div',{staticClass:"pointer",style:({
          'background-image':
            'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), url(' +
            card.image +
            '), url(' +
            _vm.defaultImage +
            ')',
          height: '200px',
          width: _vm.cardWidth + 'px'
        }),on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.$emit(
            $event.metaKey || $event.ctrlKey ? 'open-new-tab' : 'open',
            card.id
          )},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.$emit('open-new-tab', card.id)}}}),_c('TooltipOnTruncateComponent',{attrs:{"tooltipText":card.title || _vm.defaultTitle}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" "+_vm._s(card.title || _vm.defaultTitle)+" ")])],1)],1)})],2),_c('ResizeObserver',{directives:[{name:"scroll",rawName:"v-scroll:#scroll-container",value:(_vm.handleScroll),expression:"handleScroll",arg:"#scroll-container"}],on:{"notify":_vm.handleResize}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }