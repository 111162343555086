import { Logger } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { GraphQLResult, API, graphqlOperation } from '@aws-amplify/api';
import { Storage } from '@aws-amplify/storage';
import {
  volatileDocumentsStore,
  persistentDocumentsStore
} from '../plugins/store';
import _, { noop } from 'lodash';
import type Observable from 'zen-observable-ts';
import {
  defaultListProperties as defaultAttributeTypeListProperties,
  defaultDetailProperties as defaultAttributeTypeDetailProperties
} from './attributeType';
import {
  defaultListProperties as defaultAttributeValueListProperties,
  defaultDetailProperties as defaultAttributeValueDetailProperties
} from './attributeValue';
import {
  defaultListProperties as defaultConditionOperatorListProperties,
  defaultDetailProperties as defaultConditionOperatorDetailProperties
} from './conditionOperator';
import {
  defaultListProperties as defaultCubeListProperties,
  defaultDetailProperties as defaultCubeDetailProperties
} from './cube';
import {
  defaultListProperties as defaultHomeegramClassListProperties,
  defaultDetailProperties as defaultHomeegramClassDetailProperties
} from './homeegramClass';
import {
  defaultListProperties as defaultLocalizationListProperties,
  defaultDetailProperties as defaultLocalizationDetailProperties
} from './localization';
import {
  defaultListProperties as defaultPlaceholderListProperties,
  defaultDetailProperties as defaultPlaceholderDetailProperties
} from './placeholder';
import {
  defaultListProperties as defaultProductListProperties,
  defaultDetailProperties as defaultProductDetailProperties,
  Product
} from './product';
import {
  defaultListProperties as defaultProductIconListProperties,
  defaultDetailProperties as defaultProductIconDetailProperties,
  ProductIcon
} from './productIcon';
import {
  defaultListProperties as defaultProductTypeListProperties,
  defaultDetailProperties as defaultProductTypeDetailProperties
} from './productType';
import {
  defaultListProperties as defaultProfileListProperties,
  defaultDetailProperties as defaultProfileDetailProperties
} from './profile';
import {
  defaultListProperties as defaultProtocolListProperties,
  defaultDetailProperties as defaultProtocolDetailProperties
} from './protocol';
import {
  defaultListProperties as defaultServiceListProperties,
  defaultDetailProperties as defaultServiceDetailProperties
} from './service';
import {
  defaultListProperties as defaultTriggerOperatorListProperties,
  defaultDetailProperties as defaultTriggerOperatorDetailProperties
} from './triggerOperator';
import {
  defaultListProperties as defaultUiElementListProperties,
  defaultDetailProperties as defaultUiElementDetailProperties
} from './uiElement';
import {
  defaultListProperties as defaultUseCaseListProperties,
  defaultDetailProperties as defaultUseCaseDetailProperties
} from './useCase';
import {
  Document,
  DocTypes,
  defaultProperties,
  DocumentFilter,
  DocsFile,
  minimalProperties
} from './document';
import { SearchType } from '../plugins/store/persistentDocuments';

export const LOCK_TIMEOUT: number = 600000;

export const CREATE_GROUP: string = 'Create';
export const UPDATE_GROUP: string = 'Update';
export const DELETE_GROUP: string = 'Delete';

interface APIListResult<T extends Document = Document> {
  result: { items: T[]; nextToken?: string };
}

export type Rows = Array<Record<string, string | number | boolean | null>>;
export type ListFilter = Record<string, number> | false;

const defaultDetailProperties: Record<DocTypes, string> = {
  [DocTypes.AttributeType]: defaultAttributeTypeDetailProperties,
  [DocTypes.AttributeValue]: defaultAttributeValueDetailProperties,
  [DocTypes.ConditionOperator]: defaultConditionOperatorDetailProperties,
  [DocTypes.Cube]: defaultCubeDetailProperties,
  [DocTypes.HomeegramClass]: defaultHomeegramClassDetailProperties,
  [DocTypes.Localization]: defaultLocalizationDetailProperties,
  [DocTypes.Placeholder]: defaultPlaceholderDetailProperties,
  [DocTypes.Product]: defaultProductDetailProperties,
  [DocTypes.ProductIcon]: defaultProductIconDetailProperties,
  [DocTypes.ProductType]: defaultProductTypeDetailProperties,
  [DocTypes.Profile]: defaultProfileDetailProperties,
  [DocTypes.Protocol]: defaultProtocolDetailProperties,
  [DocTypes.Service]: defaultServiceDetailProperties,
  [DocTypes.TriggerOperator]: defaultTriggerOperatorDetailProperties,
  [DocTypes.UiElement]: defaultUiElementDetailProperties,
  [DocTypes.UseCase]: defaultUseCaseDetailProperties
};

const defaultListProperties: Record<DocTypes, string> = {
  [DocTypes.AttributeType]: defaultAttributeTypeListProperties,
  [DocTypes.AttributeValue]: defaultAttributeValueListProperties,
  [DocTypes.ConditionOperator]: defaultConditionOperatorListProperties,
  [DocTypes.Cube]: defaultCubeListProperties,
  [DocTypes.HomeegramClass]: defaultHomeegramClassListProperties,
  [DocTypes.Localization]: defaultLocalizationListProperties,
  [DocTypes.Placeholder]: defaultPlaceholderListProperties,
  [DocTypes.Product]: defaultProductListProperties,
  [DocTypes.ProductIcon]: defaultProductIconListProperties,
  [DocTypes.ProductType]: defaultProductTypeListProperties,
  [DocTypes.Profile]: defaultProfileListProperties,
  [DocTypes.Protocol]: defaultProtocolListProperties,
  [DocTypes.Service]: defaultServiceListProperties,
  [DocTypes.TriggerOperator]: defaultTriggerOperatorListProperties,
  [DocTypes.UiElement]: defaultUiElementListProperties,
  [DocTypes.UseCase]: defaultUseCaseListProperties
};

export function isEmpty(value: unknown): boolean {
  switch (typeof value) {
    case 'number':
      return Number.isNaN(value) || !Number.isFinite(value);
    case 'bigint':
    case 'boolean':
      return false;
    case 'symbol':
      return isEmpty(value.valueOf());
    default:
      return _.isEmpty(value);
  }
}

export async function updateListState(
  type: DocTypes,
  properties: string,
  force: boolean = false
): Promise<void> {
  const lastFetch: number = new Date().getTime(),
    newerThan: number = force
      ? 0
      : persistentDocumentsStore.lastFetch[type] || 0;
  let nextToken: string | undefined;
  do {
    const data: APIListResult = await queryDocumentList(
      type,
      properties,
      newerThan,
      undefined,
      nextToken
    );
    await persistentDocumentsStore.updateList({
      type,
      items: data.result.items
    });
    nextToken = data.result.nextToken;
  } while (nextToken);
  await persistentDocumentsStore.updateLastFetch({
    type,
    lastFetch
  });
}

export async function queryDocumentDetail(
  type: DocTypes,
  id: string,
  properties: string
): Promise<Document> {
  const {
    errors,
    data
  }: GraphQLResult<{ item: Document }> = (await API.graphql(
    graphqlOperation(
      `query q($id: ID!) {
        item: get${type}(id: $id) {
          ${defaultProperties}
          ${properties}
        }
      }`,
      {
        id
      }
    )
  )) as GraphQLResult<{ item: Document }>;
  if (errors) {
    throw errors;
  }
  if (!data?.item) {
    throw [{ message: 'Empty response' }];
  }
  return data.item;
}

export async function updateDetailState(
  type: DocTypes,
  id: string
): Promise<void> {
  try {
    volatileDocumentsStore.setLoading(true);
    volatileDocumentsStore.setDocument(
      await queryDocumentDetail(type, id, defaultDetailProperties[type])
    );
    volatileDocumentsStore.setLoading(false);
  } catch (e) {
    volatileDocumentsStore.setLoading(false);
    throw e;
  }
}

export async function queryDocumentList<T extends Document = Document>(
  type: DocTypes,
  properties: string,
  newerThan: number,
  filter: DocumentFilter | undefined = undefined,
  nextToken: string | undefined
): Promise<APIListResult<T>> {
  const { errors, data }: GraphQLResult<APIListResult<T>> = (await API.graphql(
    graphqlOperation(
      `query q(${
        filter ? `$filter: Filter${type}Input, ` : ''
      }$nextToken: String, $newerThan: Long) {
        result: get${type}List(${
        filter ? 'filter: $filter, ' : ''
      }nextToken: $nextToken, newerThan: $newerThan) {
          items {
            ${defaultProperties}
            ${properties}
          }
          nextToken
        }
      }`,
      {
        filter,
        nextToken,
        newerThan
      }
    )
  )) as GraphQLResult<APIListResult<T>>;
  if (errors) {
    throw errors;
  }
  if (!data?.result) {
    throw [{ message: 'Empty response' }];
  }
  return data;
}

export async function queryWholeDocumentList<T extends Document = Document>(
  type: DocTypes,
  properties: string | undefined,
  filter: DocumentFilter | undefined = undefined
): Promise<T[]> {
  if (properties === undefined) {
    properties = defaultListProperties[type];
  }
  const result: T[] = [];
  let nextToken: string | undefined;
  do {
    const data: APIListResult<T> = await queryDocumentList<T>(
      type,
      properties,
      0,
      filter,
      nextToken
    );
    result.push(...data.result.items);
    nextToken = data.result.nextToken;
  } while (nextToken);
  return result;
}

export async function searchDocumentList(
  docType: DocTypes,
  searchString: string,
  searchType: SearchType = SearchType.FUZZY
): Promise<Array<{ _id: string; _score: number }>> {
  const weightings: string[] = {
    [DocTypes.AttributeType]: ['name^2', 'displayName^2'],
    [DocTypes.AttributeValue]: ['attributeType^2', 'value^2', 'display^2'],
    [DocTypes.ConditionOperator]: ['name^2'],
    [DocTypes.Cube]: ['name^2', 'displayName^2'],
    [DocTypes.HomeegramClass]: ['name^2', 'description^2'],
    [DocTypes.Localization]: ['stringIdentifier^2', 'de^2', 'en^2'],
    [DocTypes.Placeholder]: ['stringIdentifier^2'],
    [DocTypes.Product]: [
      'manufacturer^2',
      'productName^2',
      'zigbeeDeviceId^2',
      'zigbeeProfileId^2',
      'enoceanEep^2',
      'displayName^2',
      'asin^2',
      'eanCodes^2'
    ],
    [DocTypes.ProductIcon]: ['name^2'],
    [DocTypes.ProductType]: ['displayName^2'],
    [DocTypes.Profile]: ['name^2'],
    [DocTypes.Protocol]: ['name^2', 'displayName^2'],
    [DocTypes.Service]: ['name^2', 'bit^2'],
    [DocTypes.TriggerOperator]: ['name^2'],
    [DocTypes.UiElement]: ['label^2'],
    [DocTypes.UseCase]: ['stringIdentifier^2']
  }[docType];
  const {
    errors,
    data
  }: GraphQLResult<{
    result: Array<{ _id: string; _score: number }>;
  }> = (await API.graphql(
    graphqlOperation(
      `query q($docType: DocType!, $searchString: String!, $searchType: SearchType, $weightings: [String!]) {
        result: search(docType: $docType, searchString: $searchString, searchType: $searchType, weightings: $weightings) {
          _id
          _score
        }
      }`,
      {
        docType,
        searchString,
        searchType,
        weightings
      }
    )
  )) as GraphQLResult<{ result: Array<{ _id: string; _score: number }> }>;
  if (errors) {
    throw errors;
  }
  if (!data?.result) {
    throw [{ message: 'Empty response' }];
  }
  return data.result;
}

export function serializeValue(value: unknown): unknown {
  if (Array.isArray(value)) {
    return value
      .map(serializeValue)
      .filter((elem: unknown): boolean => !isEmpty(elem));
  }
  if (typeof value === 'object' && (value as Document)?._id) {
    return (value as Document)._id;
  }
  return value;
}

export async function createDocument(
  type: DocTypes,
  input: Partial<Document>
): Promise<string> {
  input = Object.entries(input).reduce(
    (
      input: Partial<Document>,
      [key, value]: [string, unknown]
    ): Partial<Document> => ({
      ...input,
      ...(isEmpty(value) ? {} : { [key]: value })
    }),
    {}
  );
  const attachments: Record<string, string> = {};
  switch (type) {
    case DocTypes.Product: {
      const product: Partial<Product> = input as Partial<Product>;
      if (product.image) {
        if (product.image.url && product.image.url.startsWith('blob:')) {
          attachments['image.tiff'] = product.image.url;
        }
        product.image = {
          name: 'image',
          version: product.image.version
        };
      }
      if (product.manuals) {
        const manuals: DocsFile[] = [];
        for (const manual of product.manuals) {
          if (manual.url && manual.url.startsWith('blob:')) {
            attachments[`${manual.name}.${manual.extension}`] = manual.url;
          }
          manuals.push({
            name: manual.name,
            extension: manual.extension,
            version: manual.version
          });
        }
        product.manuals = manuals;
      }
      break;
    }
    case DocTypes.ProductIcon: {
      const productIcon: Partial<ProductIcon> = input as Partial<ProductIcon>;
      if (productIcon.icon) {
        if (productIcon.icon.url && productIcon.icon.url.startsWith('blob:')) {
          attachments['icon.svg'] = productIcon.icon.url;
        }
        productIcon.icon = {
          name: 'icon',
          extension: 'svg',
          version: productIcon.icon.version
        };
      }
      break;
    }
  }
  const {
    errors,
    data
  }: GraphQLResult<{ item: Document }> = await (API.graphql(
    graphqlOperation(
      `mutation m($input: ${type}Input!) {
        item: create${type}(input: $input) {
          ${minimalProperties}
        }
      }`,
      {
        input
      }
    )
  ) as Promise<GraphQLResult<{ item: Document }>>);
  if (errors) {
    throw errors;
  }
  if (!data?.item) {
    throw [{ message: 'Empty response' }];
  }
  for (const [key, value] of Object.entries(attachments || {})) {
    await uploadAttachment(
      `${type.toLocaleLowerCase()}/${data.item._id}/${key}`,
      value
    );
    URL.revokeObjectURL(value);
  }
  return data.item._id;
}

export async function updateDocument(
  id: string,
  type: DocTypes,
  version: number,
  input: Partial<Document>
): Promise<void> {
  input = Object.entries(input).reduce(
    (
      input: Partial<Document>,
      [key, value]: [string, unknown]
    ): Partial<Document> => ({
      ...input,
      [key]: isEmpty(value) ? null : value
    }),
    {}
  );
  switch (type) {
    case DocTypes.Product: {
      const product: Partial<Product> = input as Partial<Product>;
      if (product.image) {
        if (product.image.url && product.image.url.startsWith('blob:')) {
          await uploadAttachment(
            `${type.toLocaleLowerCase()}/${id}/image.tiff`,
            product.image.url
          );
          URL.revokeObjectURL(product.image.url);
        }
        product.image = {
          name: 'image',
          version: product.image.version
        };
      }
      if (product.manuals) {
        const manuals: DocsFile[] = [];
        for (const manual of product.manuals) {
          if (manual.url && manual.url.startsWith('blob:')) {
            await uploadAttachment(
              `${type.toLocaleLowerCase()}/${id}/${manual.name}.${
                manual.extension
              }`,
              manual.url
            );
            URL.revokeObjectURL(manual.url);
          }
          manuals.push({
            name: manual.name,
            extension: manual.extension,
            version: manual.version
          });
        }
        product.manuals = manuals;
      }
      break;
    }
    case DocTypes.ProductIcon: {
      const productIcon: Partial<ProductIcon> = input as Partial<ProductIcon>;
      if (productIcon.icon) {
        if (productIcon.icon.url && productIcon.icon.url.startsWith('blob:')) {
          await uploadAttachment(
            `${type.toLocaleLowerCase()}/${id}/icon.svg`,
            productIcon.icon.url
          );
          URL.revokeObjectURL(productIcon.icon.url);
        }
        productIcon.icon = {
          name: 'icon',
          extension: 'svg',
          version: productIcon.icon.version
        };
      }
      break;
    }
  }
  return (API.graphql(
    graphqlOperation(
      `mutation m($id: ID!, $version: Long!, $input: ${type}Input!) {
        item: update${type}(id: $id, version: $version, input: $input) {
          ${minimalProperties}
        }
      }`,
      {
        id,
        version,
        input
      }
    )
  ) as Promise<GraphQLResult<{ item: Document }>>).then(
    ({ errors, data }: GraphQLResult<{ item: Document }>): void => {
      if (errors) {
        throw errors;
      }
      if (!data?.item) {
        throw [{ message: 'Empty response' }];
      }
    }
  );
}

export async function uploadAttachment(
  path: string,
  url: string
): Promise<void> {
  const blob: Blob = await fetch(url).then(
    (response: Response): Promise<Blob> => response.blob()
  );
  return Storage.put(path, blob, {
    level: 'public',
    contentType: blob.type
  }).then(noop);
}

export async function getImageDetails(
  id: string,
  docType: DocTypes,
  file: DocsFile,
  extension?: string
): Promise<DocsFile> {
  const previewExtension: string | undefined = extension || file.extension;
  const previewUrl: string = `https://${
    process.env.S3_BUCKET
  }/${docType.toLocaleLowerCase()}/${id}/${file.name}${
    previewExtension ? `.${previewExtension}` : ''
  }?version=${file.version}`;
  try {
    const response: Response = await fetch(previewUrl, { method: 'HEAD' });
    const widthHeader: string | null = response.headers.get('X-OriginalWidth');
    const heightHeader: string | null = response.headers.get(
      'X-OriginalHeight'
    );
    const sizeHeader: string | null = response.headers.get('X-OriginalSize');
    const extensionHeader: string | null = response.headers.get(
      'X-OriginalExtension'
    );
    return {
      ...file,
      url: previewUrl,
      width: widthHeader ? parseInt(widthHeader) : undefined,
      height: heightHeader ? parseInt(heightHeader) : undefined,
      size: sizeHeader ? parseInt(sizeHeader) : undefined,
      extension: extensionHeader || undefined,
      s3Url: `https://s3.console.aws.amazon.com/s3/object/${
        process.env.S3_BUCKET
      }?region=eu-central-1&prefix=public/${docType.toLocaleLowerCase()}/${id}/${
        file.name
      }.tiff`
    };
  } catch (e) {
    return { ...(file || ({} as DocsFile)), url: previewUrl };
  }
}

export async function batchDeleteDocument(
  ids: string[],
  docType: DocTypes
): Promise<void> {
  return (API.graphql(
    graphqlOperation(
      `mutation m($ids: [ID!]!, $docType: String!) {
          item: batchDeleteDocument(ids: $ids, docType: $docType) {
            ${minimalProperties}
          }
        }`,
      {
        ids,
        docType
      }
    )
  ) as Promise<GraphQLResult>).then(({ errors }: GraphQLResult): void => {
    if (errors) {
      throw errors;
    }
  });
}

export function deleteDocument(id: string): Promise<void> {
  return (API.graphql(
    graphqlOperation(
      `mutation m($id: ID!) {
        item: deleteDocument(id: $id) {
          ${minimalProperties}
        }
      }`,
      {
        id
      }
    )
  ) as Promise<GraphQLResult<{ item: Document }>>).then(
    ({ errors, data }: GraphQLResult<{ item: Document }>): void => {
      if (errors) {
        throw errors;
      }
      if (!data?.item) {
        throw [{ message: 'Empty response' }];
      }
      if (data.item._deleted !== true) {
        throw [{ message: 'Not deleted' }];
      }
    }
  );
}

export function lockDocument(
  id: string,
  version: number,
  previousLock: number
): Promise<void> {
  return (API.graphql(
    graphqlOperation(
      `mutation q($id: ID!, $version: Long!, $previousLock: Long!) {
        item: lockDocument(id: $id, version: $version, previousLock: $previousLock) {
          ${minimalProperties}
        }
      }`,
      {
        id,
        version,
        previousLock
      }
    )
  ) as Promise<GraphQLResult<{ item: Document }>>).then(
    ({ data }: GraphQLResult<{ item: Document }>): void => {
      if (!data?.item) {
        throw [{ message: 'Empty response' }];
      }
      volatileDocumentsStore.updateDocument(data.item);
    }
  );
}

export function unlockDocument(
  id: string,
  version: number,
  previousLock: number
): Promise<void> {
  return (API.graphql(
    graphqlOperation(
      `mutation q($id: ID!, $version: Long!, $previousLock: Long!) {
        item: unlockDocument(id: $id, version: $version, previousLock: $previousLock) {
          ${minimalProperties}
        }
      }`,
      {
        id,
        version,
        previousLock
      }
    )
  ) as Promise<GraphQLResult<{ item: Document }>>).then(
    ({ data }: GraphQLResult<{ item: Document }>): void => {
      if (!data?.item) {
        throw [{ message: 'Empty response' }];
      }
      volatileDocumentsStore.updateDocument(data.item);
    }
  );
}

export async function setupSubscriptions(): Promise<void> {
  const logger: Logger = new Logger('console');
  await Auth.currentAuthenticatedUser();
  (API.graphql(
    graphqlOperation(
      `subscription s {
        result: onChangeDocument {
          _id
          _docType
        }
      }`
    )
  ) as Observable<{
    value: { data: { result: { _id: string; _docType: DocTypes } } };
  }>).subscribe({
    next: (val: {
      value: {
        errors: unknown;
        data: { result: { _id: string; _docType: DocTypes } };
      };
    }): void => {
      if (val.value.errors) {
        logger.error(val.value.errors);
        return;
      }
      if (!val?.value?.data?.result) {
        logger.error(new Error('no data'));
        return;
      }
      if (!val.value.data.result._docType) {
        logger.error(new Error('missing properties'));
        return;
      }
      if (!val.value.data.result._id) {
        volatileDocumentsStore.clearStore();
      } else {
        updateDetailState(
          val.value.data.result._docType,
          val.value.data.result._id
        );
      }
      persistentDocumentsStore.setLoading(true);
      updateListState(
        val.value.data.result._docType,
        defaultListProperties[val.value.data.result._docType]
      ).finally((): void => {
        persistentDocumentsStore.setLoading(false);
      });
    },
    error: (err: Error): void => logger.error(err)
  });
}

export async function fetchDocumentLists(
  activeType: DocTypes,
  force: boolean = false
): Promise<void> {
  try {
    persistentDocumentsStore.setLoading(true);
    await Auth.currentAuthenticatedUser();
    await updateListState(activeType, defaultListProperties[activeType], force);
    const chunks: string[][] = Object.keys(defaultListProperties)
      .filter((type: string): boolean => type !== activeType)
      .reduce((all: string[][], one: string, i: number): string[][] => {
        const ch: number = Math.floor(i / 25);
        all[ch] = ([] as string[]).concat(all[ch] || ([] as string[]), one);
        return all;
      }, [] as string[][]);
    for (const chunk of chunks) {
      await Promise.all(
        chunk.map(
          (type: string): Promise<void> =>
            updateListState(
              type as DocTypes,
              defaultListProperties[type as DocTypes],
              force
            )
        )
      );
    }
    localStorage.setItem('initialized', 'TRUE');
  } finally {
    persistentDocumentsStore.setLoading(false);
  }
}
