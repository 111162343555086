import type _Vue from 'vue';
import { Logger } from '@aws-amplify/core';

export default function VueLoggerPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$logger = new Logger('console');
}

declare module 'vue/types/vue' {
  interface Vue {
    $logger: Logger;
  }
}
