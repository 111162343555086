import './registerComponentHooks';
import './registerServiceWorker';

import Vue, { CreateElement } from 'vue';
import App from './App.vue';
import router from './plugins/router';
import store from './plugins/store';
import vuetify from './plugins/vuetify';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import AsyncComputedPlugin from 'vue-async-computed';
import awsconfig from './aws-exports';
import VueTimersPlugin from 'vue-plugin-timers';
import VueSpatialNavigationPlugin from 'vue-spatialnavigation';
import VueMousetrapPlugin from 'vue-mousetrap';
import VueI18nPlugin from './plugins/i18n';
import VueLoggerPlugin from './plugins/logger';
import type { VNode } from 'vue/types/umd';

window.addEventListener('storage', (event: StorageEvent): void => {
  if (event.key === 'initialized') {
    window.location.reload();
  }
});

// send ID token instead of access token to get extended identity claims
// see https://github.com/aws-amplify/amplify-js/issues/3326#issuecomment-577010244
// see https://github.com/aws-amplify/amplify-js/issues/4751
// see https://github.com/aws-amplify/amplify-cli/issues/3062
awsconfig.API = {
  graphql_headers: async (): Promise<{
    Authorization: string;
  }> => {
    const session: CognitoUserSession = await Auth.currentSession();
    return {
      Authorization: session.getIdToken().getJwtToken()
    };
  }
};
Amplify.configure(awsconfig);

Vue.use(VueLoggerPlugin);

Vue.use(VueI18nPlugin);

Vue.use(AsyncComputedPlugin);

Vue.use(VueTimersPlugin);

Vue.use(VueSpatialNavigationPlugin);

Vue.use(VueMousetrapPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h: CreateElement): VNode => h(App)
}).$mount('#app');
