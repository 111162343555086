import {
  Document,
  DocTypes,
  LocalizedDocument,
  supportedLocalizations,
  DocsFilter
} from './document';
import { supportedLanguages, Translations } from '../plugins/i18n';
import { I18n } from '@aws-amplify/core';
import { FieldTypes, TextArea, DetailData } from '../typings/field';
import type { MyDataTableHeader } from '../typings';
import { persistentDocumentsStore } from '../plugins/store';
import { Placeholder, supportedBrands } from './placeholder';
import type { ListFilter, Rows } from '.';
import _ from 'lodash';

export interface Localization
  extends Document,
    LocalizedDocument<string | null> {
  _docType: DocTypes.Localization;
  stringIdentifier: string;
  note?: string | null;
}

export const defaultListProperties: string = `
  stringIdentifier
  ${supportedLanguages.join('\n')}
`;

export const defaultDetailProperties: string = `
  stringIdentifier
  ${supportedLocalizations.join('\n')}
  note
`;

export function getColumns(): MyDataTableHeader[] {
  return [
    {
      text: I18n.get(Translations.STRING_IDENTIFIER),
      value: 'stringIdentifier',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.GERMAN),
      value: 'de',
      align: 'start',
      sortable: true
    },
    {
      text: I18n.get(Translations.ENGLISH),
      value: 'en',
      align: 'start',
      sortable: true
    }
  ];
}

export function getRows(listFilter?: ListFilter): Rows {
  let documents: Localization[] = Object.values(
    persistentDocumentsStore.Localization
  );
  if (listFilter) {
    const idList: string[] = Object.keys(listFilter);
    documents = _.orderBy(
      documents.filter((document: Document): boolean =>
        idList.includes(document._id)
      ),
      (document: Document): number => listFilter[document._id],
      'desc'
    );
  }
  return documents.map(
    (
      document: Localization
    ): Record<string, string | number | boolean | null> => ({
      _id: document._id,
      stringIdentifier: document.stringIdentifier,
      de: document.de,
      en: document.en
    })
  );
}

export const sortListBy: string | string[] = 'stringIdentifier';

export async function getCategories(
  document: Partial<Localization>
): Promise<DetailData> {
  const unwantedWords: LocalizedDocument<RegExp[]> = Object.values(
    persistentDocumentsStore.Placeholder
  ).reduce(
    (
      unwantedWords: LocalizedDocument<RegExp[]>,
      placeholder: Placeholder
    ): LocalizedDocument<RegExp[]> => {
      for (const brand of supportedBrands) {
        for (const locale of supportedLocalizations) {
          if (placeholder[brand][locale]) {
            unwantedWords[locale].push(
              new RegExp(`\\b${placeholder[brand][locale]}\\b`, 'i')
            );
          }
        }
      }
      return unwantedWords;
    },
    supportedLocalizations.reduce(
      (
        unwantedWords: LocalizedDocument<RegExp[]>,
        key: string
      ): LocalizedDocument<RegExp[]> => ({
        ...unwantedWords,
        [key]: []
      }),
      {} as LocalizedDocument<RegExp[]>
    )
  );
  return {
    title: document.stringIdentifier,
    categories: [
      {
        name: I18n.get(Translations.GENERAL),
        fields: [
          {
            type: FieldTypes.TEXT,
            label: I18n.get(Translations.STRING_IDENTIFIER),
            required: true,
            unique: Object.entries(persistentDocumentsStore.Localization)
              .filter(
                ([key]: [string, Localization]): boolean => key !== document._id
              )
              .map(
                ([, item]: [string, Localization]): string =>
                  item.stringIdentifier
              ),
            model: 'stringIdentifier',
            value: document.stringIdentifier,
            validator: (data: string): boolean => /^[\w-]+$/.test(data),
            upperCase: true
          },
          ...supportedLocalizations.map(
            (locale: keyof LocalizedDocument): TextArea => ({
              type: FieldTypes.MULTILINE,
              label: I18n.get(Translations[locale]),
              required: false,
              model: locale,
              value: document[locale],
              hint: (value: string): string | null =>
                unwantedWords[locale].some((unwantedWord: RegExp): boolean =>
                  unwantedWord.test(value)
                )
                  ? I18n.get(Translations.REPLACE_PLACEHOLDERS)
                  : null
            })
          ),
          {
            type: FieldTypes.MULTILINE,
            label: I18n.get(Translations.NOTE),
            required: false,
            model: 'note',
            value: document.note
          }
        ]
      }
    ]
  };
}

export function getFilters(): DocsFilter[] {
  return [
    {
      operators: [
        'eq',
        'ne',
        'exists',
        'notExists',
        'contains',
        'notContains',
        'in'
      ],
      label: I18n.get(Translations.STRING_IDENTIFIER),
      property: 'stringIdentifier',
      validator: (data: string): boolean => /^[\w-]+$/.test(data),
      upperCase: true
    },
    ...supportedLocalizations.map(
      (locale: keyof LocalizedDocument): DocsFilter => ({
        operators: ['contains', 'notContains', 'exists', 'notExists'],
        label: I18n.get(Translations[locale]),
        property: locale
      })
    ),
    {
      operators: ['contains', 'notContains', 'exists', 'notExists'],
      label: I18n.get(Translations.NOTE),
      property: 'note'
    }
  ];
}
