var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"id":"error-alert","type":"error","transition":"slide-y-transition","dismissible":"","tile":"","value":_vm.error !== false},on:{"input":function($event){_vm.error = false}}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('v-overlay',{staticStyle:{"left":"-56px"},attrs:{"value":_vm.showWaiting,"absolute":"","z-index":10}},[_c('p',{staticClass:"text-center",domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.PREPARE_DOWNLOAD))}}),(_vm.urlFound)?_c('v-img',{attrs:{"src":_vm.gifUrl}}):_vm._e(),(!_vm.urlFound)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}}):_vm._e()],1),_c('v-toolbar',{staticStyle:{"z-index":"3"},attrs:{"elevated":"","height":"64px","extension-height":"16px","dense":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(
          _vm.deletePermission &&
          (!_vm.showCardGallery || _vm.docType !== _vm.DocTypes.Product)
        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({style:({ right: _vm.createPermission ? '88px' : '36px' }),attrs:{"color":"error","small":"","absolute":"","bottom":"","right":"","fab":"","disabled":_vm.selected.length <= 0},on:{"click":function($event){_vm.showConfirmDelete = true}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.deleteIconSvg)}})],1)]}}],null,false,3762018379)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.DELETE))}})]):_vm._e(),(_vm.createPermission)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"right":"36px"},attrs:{"color":"success","small":"","absolute":"","bottom":"","right":"","fab":"","to":{
              path: ("/" + ((_vm.docType || '').toLowerCase()) + "/new")
            }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.plusIconSvg)}})],1)]}}],null,false,756276159)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.ADD))}})]):_vm._e()]},proxy:true}])},[_c('v-row',{staticClass:"mt-4 flex-nowrap",attrs:{"no-gutters":"","justify":"center"}},[(_vm.docType === _vm.DocTypes.Product)?_c('v-switch',{staticClass:"ml-sm-10",staticStyle:{"align-items":"center","margin-right":"auto","width":"110px"},attrs:{"label":_vm.$I18n.get(_vm.$Translations.GALLERY),"hide-details":"","dense":""},model:{value:(_vm.showCardGallery),callback:function ($$v) {_vm.showCardGallery=$$v},expression:"showCardGallery"}}):_vm._e(),_c('v-text-field',{ref:"searchField",staticClass:"mt-2",class:_vm.docType === _vm.DocTypes.Product ? 'switchOffset' : '',staticStyle:{"max-width":"500px"},attrs:{"value":_vm.search,"placeholder":_vm.$I18n.get(_vm.$Translations.SEARCH),"hide-details":"","clearable":"","autofocus":"","single-line":""},on:{"input":function($event){return _vm.setSearchText($event)},"click:clear":function($event){return _vm.setSearchText()}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"focus-proxy",domProps:{"textContent":_vm._s(
                  _vm.searchType === _vm.SearchType.FUZZY
                    ? _vm.approxEqualIconSvg
                    : _vm.searchType === _vm.SearchType.STRICT
                    ? _vm.equalIconSvg
                    : _vm.jsonIconSvg
                )},on:{"click":_vm.setNextSearchType}},on))]}}])},[_vm._v(" "+_vm._s(_vm.$I18n.get( _vm.searchType === _vm.SearchType.FUZZY ? _vm.$Translations.FUZZY_SEARCH : _vm.searchType === _vm.SearchType.STRICT ? _vm.$Translations.STRICT_SEARCH : _vm.$Translations.SYNTAX_SEARCH ))+" ")])]},proxy:true}])}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.showFilterDialog = true}}},on),[_c('v-icon',{attrs:{"color":_vm.isFilter ? 'primary' : undefined},domProps:{"textContent":_vm._s(_vm.filterIconSvg)}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.FILTER))}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({style:(_vm.docType === _vm.DocTypes.Product ? 'margin-right: auto;' : ''),attrs:{"disabled":!_vm.isFilter,"icon":""},on:{"click":function($event){return _vm.setFilter({})}}},on),[_c('v-icon',{attrs:{"color":"error"},domProps:{"textContent":_vm._s(_vm.deleteFilterIconSvg)}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.DELETE_FILTER))}})])],1)],1),(_vm.showCardGallery && _vm.docType === _vm.DocTypes.Product)?_c('CardListComponent',{staticStyle:{"margin-top":"80px"},attrs:{"cards":_vm.cards,"sort":!_vm.isSearch},on:{"open":_vm.openDetail,"open-new-tab":_vm.openDetailNewTab}}):_c('TableListComponent',{key:_vm.docType,staticClass:"ma-sm-14 ma-xs-0 elevation-2",staticStyle:{"overflow":"hidden"},attrs:{"headers":_vm.headers,"items":_vm.rows,"baseUrl":("/" + (_vm.docType.toLowerCase()) + "/"),"selected":_vm.selected,"sortBy":_vm.sortBy,"loading":_vm.loading},on:{"update:selected":function($event){_vm.selected=$event},"open":_vm.openDetail,"open-new-tab":_vm.openDetailNewTab}}),(_vm.hasDownload)?_c('v-speed-dial',{attrs:{"absolute":"","bottom":"","right":"","direction":"top","open-on-hover":"","transition":"slide-y-reverse-transition"},on:{"input":function($event){$event && _vm.fetchWaitingGif()}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},on),[(_vm.fab)?_c('v-icon',{domProps:{"textContent":_vm._s(_vm.closeIconSvg)}}):_c('v-icon',{domProps:{"textContent":_vm._s(_vm.downloadIconSvg)}})],1)]}}],null,false,1953210470)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.DOWNLOAD))}})])]},proxy:true}],null,false,2036134502),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.docType === _vm.DocTypes.Product)?[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"grey"},on:{"click":function($event){return _vm.triggerProductListDownload(_vm.ProductListDownloadTarget.JSON)}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.jsonIconSvg)}})],1)]}}],null,false,14928735)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.JSON)))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#1d6f42"},on:{"click":function($event){return _vm.triggerProductListDownload(_vm.ProductListDownloadTarget.EXCEL)}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.excelIconSvg)}})],1)]}}],null,false,802209494)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.EXCEL)))])])]:_vm._e(),(_vm.docType === _vm.DocTypes.Localization)?[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#1d6f42"},on:{"click":function($event){return _vm.triggerLocalizationListDownload(
                _vm.LocalizationListDownloadTarget.Excel
              )}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.excelIconSvg)}})],1)]}}],null,false,3116445974)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.EXCEL)))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.triggerLocalizationListDownload(
                _vm.LocalizationListDownloadTarget.Excel_Missing
              )}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.excelIconSvg)}})],1)]}}],null,false,3064751806)},[_c('span',[_vm._v(_vm._s(((_vm.$I18n.get(_vm.$Translations.LOCALIZATION)) + " " + (_vm.$I18n.get( _vm.$Translations.MISSING_LOWER )))))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#FFD600"},on:{"click":function($event){return _vm.triggerLocalizationListDownload(
                _vm.LocalizationListDownloadTarget.Notification
              )}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.mailIconSvg)}})],1)]}}],null,false,3900306019)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.NOTIFICATION_SERVER)))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.triggerLocalizationListDownload(
                _vm.LocalizationListDownloadTarget.Web
              )}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.webIconSvg)}})],1)]}}],null,false,490225829)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.WEB)))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#a4c639"},on:{"click":function($event){return _vm.triggerLocalizationListDownload(
                _vm.LocalizationListDownloadTarget.Android
              )}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.androidIconSvg)}})],1)]}}],null,false,3743308127)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.ANDROID)))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"#5e84a7"},on:{"click":function($event){return _vm.triggerLocalizationListDownload(
                _vm.LocalizationListDownloadTarget.iOS
              )}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.appleIconSvg)}})],1)]}}],null,false,2862790850)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.IOS)))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"purple"},on:{"click":function($event){return _vm.triggerLocalizationListDownload(
                _vm.LocalizationListDownloadTarget.All
              )}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.filesIconSvg)}})],1)]}}],null,false,3453521996)},[_c('span',[_vm._v(_vm._s(_vm.$I18n.get(_vm.$Translations.ALL)))])])]:_vm._e()],2):_vm._e(),_c('v-dialog',{attrs:{"width":"70%","max-width":"1000px","persistent":""},on:{"click:outside":function($event){return _vm.$refs.filterComponent.reset()}},model:{value:(_vm.showFilterDialog),callback:function ($$v) {_vm.showFilterDialog=$$v},expression:"showFilterDialog"}},[_c('FilterComponent',{ref:"filterComponent",attrs:{"value":_vm.filter,"availableFilters":_vm.availableFilters},on:{"input":function($event){return _vm.setFilter($event)}}})],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showConfirmDelete),callback:function ($$v) {_vm.showConfirmDelete=$$v},expression:"showConfirmDelete"}},[_c('v-card',[_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.DELETE_DOCUMENTS))}}),_c('v-card-text',{domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.CONFIRM_DELETE_DOCUMENTS))}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"color":"error","text":"","data-right":"AUTOFOCUS"},domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.ABORT))},on:{"click":function($event){$event.stopPropagation();_vm.showConfirmDelete = false}}}),_c('v-btn',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"color":"success","text":"","data-left":"AUTOFOCUS","data-default":""},domProps:{"textContent":_vm._s(_vm.$I18n.get(_vm.$Translations.DELETE))},on:{"click":function($event){$event.stopPropagation();return _vm.deleteDocuments()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }